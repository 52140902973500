import format from 'date-fns/format';
import { createSelector } from 'reselect';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';

import { FORM_PERIODS } from '@tsp/utils/time/periods';
import { createInWindowsIntersectionDefiner } from '@tsp/utils/time/inRange';
import { createFormatter } from '@tsp/utils/format/createFormatter';
import { createValueProtector } from '@tsp/utils/common/protectValue';
import { formatSimple as formatDateSimple } from '@tsp/utils/format/date';
import { calculateStatus } from '@tsp/utils/asyncEntity/utils/calculateStatus';

import { selectDetailsData } from '../details';
import { WorkTypeFilterValue } from '../filters';
import { selectDefaultContractor } from '../env';
import { selectContractTypes, selectSettingsStatus } from '../settings';
import { createMoneyFormatter, Currency, currencyToString } from '../money';

import {
  Contract,
  ApiParty,
  ConditionTableView,
  ContractsStoreSegment
} from './interface';

const EMPTY_DATA_PLACEHOLDER = 'N/A';
const protectValue = createValueProtector(EMPTY_DATA_PLACEHOLDER);

export const selectContractsSegment = (state: ContractsStoreSegment) =>
  state.contracts;

export const selectContracts = (state: ContractsStoreSegment) => {
  return selectContractsSegment(state).data;
};

export const selectContractsStatus = (state: ContractsStoreSegment) => {
  return state.contracts.status;
};

export const selectContract =
  (contractId: string) => (state: ContractsStoreSegment) => {
    return selectContracts(state)?.[contractId];
  };

export const selectUserHasContracts = createSelector(
  selectContracts,
  contracts => {
    return !!Object.keys(contracts || {}).length;
  }
);

export const selectContractCurrency = (contractId: string) =>
  createSelector(selectContract(contractId), contract => contract?.currency);

export const selectFormattedContractBalance = (contractId: string) =>
  createSelector(selectContract(contractId), contract => {
    if (!contract) {
      return undefined;
    }

    const { balance, currency } = contract;
    return createMoneyFormatter(currency)(balance || 0);
  });

export const selectContractCardView = (contractId: string) =>
  createSelector(
    selectContract(contractId),
    selectContractTypes,
    (contract, contractTypes) => {
      if (!contract) {
        return undefined;
      }

      const title = contract.type
        ? contractTypes?.[contract.type]?.name || contract.type
        : 'Default contract';

      const { currency, id, active, contractor } = contract;
      const periodFrom = format(contract.periodFrom, 'dd.LL.uuuu');
      const balance = createMoneyFormatter(currency)(contract.balance || 0);

      return {
        id,
        title,
        active,
        balance,
        periodFrom,
        contractorName: contractor.name
      };
    }
  );

export const selectContractConditions =
  (contractId: string) => (state: ContractsStoreSegment) => {
    const contract = selectContract(contractId)(state);

    return contract ? contract.conditions : undefined;
  };

export const selectContractCondition = (
  contractId: string,
  conditionId: string
) =>
  createSelector(selectContractConditions(contractId), conditions => {
    return conditions?.[conditionId];
  });

export const selectContractsList = createSelector(selectContracts, contracts =>
  Object.values<Contract>(contracts || {}).sort(
    (first, second) => Number(second.active) - Number(first.active)
  )
);

export const selectPositiveContractsList = createSelector(
  selectContractsList,
  contracts => contracts.filter(contract => !!contract.balance)
);

export const selectConditionsList = (contractId: string) =>
  createSelector(selectContractConditions(contractId), conditions =>
    Object.values(conditions || {})
  );

// TODO: Не будет работать, если у контракта указан тип: null
export const selectContractType = (contractId: string) =>
  createSelector(
    selectContract(contractId),
    selectContractTypes,
    (contract, contractTypes) => {
      if (!contract) {
        return undefined;
      }

      return contractTypes[contract.type];
    }
  );

export const selectConditionsTableView = (
  contractId: string,
  showInactive?: boolean
) =>
  createSelector(
    selectConditionsList(contractId),
    selectContractType(contractId),
    selectContractCurrency(contractId),
    (conditions, contractType, currency) => {
      const formatDate = createFormatter(formatDateSimple, {
        placeholder: '-'
      });
      const formatMoney = createFormatter(createMoneyFormatter(currency));

      return conditions
        .filter(condition => (showInactive ? true : condition.active))
        .map<ConditionTableView>(condition => {
          const supervisors = condition.supervisors
            .map(supervisor => supervisor?.name)
            .join(', ');

          const workType = contractType.work_types[condition.workType];
          const workSubtype =
            workType.work_sub_types[condition.workSubtype]?.name || 'N/A';

          return {
            supervisors,
            workSubtype,
            id: condition.id,
            workType: workType.name,
            active: condition.active,
            periodTo: formatDate(condition.periodTo),
            createdAt: formatDate(condition.createdAt),
            unitPrice: formatMoney(condition.unitPrice),
            periodFrom: formatDate(condition.periodFrom),
            fixedPrice: formatMoney(condition.fixedPrice)
          };
        });
    }
  );

export const selectTotalBalance = createSelector(
  selectContractsList,
  contracts => {
    const total = contracts.reduce(
      (result, contract) => result + contract.balance,
      0
    );

    // TODO: Ask owner, how to calculate and format total balance for contracts with different currencies
    return createMoneyFormatter(Currency.EUR)(total);
  }
);

export const selectContractsByType = (contractType: string | null) =>
  createSelector(selectContractsList, contracts =>
    (contracts || []).filter(contract => contract.type === contractType)
  );

export const selectContractsByTypeIncludingUniversal = (contractType: string) =>
  createSelector(selectContractsList, contracts =>
    (contracts || []).filter(
      contract => contract.type === contractType || !contract.type
    )
  );

interface ContractFormValues {
  id: string;
  title: string;
}

export const selectFormContractFieldValuesUniversalContracts = (
  contractType: string
) =>
  createSelector(
    selectContractsByType(contractType),
    selectContractsByType(null),
    (typeContracts, nullContracts) => {
      const contracts = typeContracts.concat(nullContracts);
      const hasIntersection = createInWindowsIntersectionDefiner(
        startOfMonth(FORM_PERIODS[0]).getTime(),
        endOfMonth(FORM_PERIODS[FORM_PERIODS.length - 1]).getTime()
      );

      return contracts.reduce<ContractFormValues[]>((result, contract) => {
        if (!hasIntersection(contract.periodFrom, contract.periodTo)) {
          return result;
        }

        const dateTemplate = 'dd.LL.yyyy';
        const periodFromView = format(contract.periodFrom, dateTemplate);
        const periodToView = contract.periodTo
          ? format(contract.periodTo, dateTemplate)
          : 'now';

        result.push({
          id: contract.id,
          title: `${contract.title} - ${periodFromView} - ${periodToView}`
        });

        return result;
      }, []);
    }
  );

export const selectFormContractFieldValues = (contractType: string) =>
  createSelector(selectContractsByType(contractType), contracts => {
    const hasIntersection = createInWindowsIntersectionDefiner(
      startOfMonth(FORM_PERIODS[0]).getTime(),
      endOfMonth(FORM_PERIODS[FORM_PERIODS.length - 1]).getTime()
    );

    return contracts.reduce<ContractFormValues[]>((result, contract) => {
      if (!hasIntersection(contract.periodFrom, contract.periodTo)) {
        return result;
      }

      const dateTemplate = 'dd.LL.yyyy';
      const periodFromView = format(contract.periodFrom, dateTemplate);
      const periodToView = contract.periodTo
        ? format(contract.periodTo, dateTemplate)
        : 'now';

      result.push({
        id: contract.id,
        title: `${contract.title} - ${periodFromView} - ${periodToView}`
      });

      return result;
    }, []);
  });

export const createWorkTypesFilterSelector = <S extends {}>(
  contractsSelector: (state: S) => Contract[]
) =>
  createSelector(
    contractsSelector,
    selectContractTypes,
    (contracts, contractTypes) => {
      const result = contracts.reduce<Record<string, WorkTypeFilterValue>>(
        (result, contract) => {
          const contractType = contractTypes[contract.type];

          if (!contractType) {
            return result;
          }

          return Object.values(contract.conditions).reduce(
            (result, condition) => {
              const workType = contractType.work_types[condition.workType];

              if (workType) {
                result[condition.workType] = {
                  workTypeName: workType.name,
                  value: String(workType.type),
                  contractName: contractType.name
                };
              }

              return result;
            },
            result
          );
        },
        {}
      );

      return Object.values(result);
    }
  );

export const selectContractDetailsData = (contractId: string) =>
  createSelector(
    selectContract(contractId),
    selectContractTypes,
    (contract, contractTypes) => {
      const currency = contract?.currency
        ? currencyToString(contract.currency)
        : protectValue(contract?.currency);

      const supplier = protectValue(contract?.supplierName);
      const contractor = protectValue(contract?.contractor?.name);
      const type = protectValue(contractTypes?.[contract?.type]?.name);

      return [
        {
          title: 'Contract',
          values: {
            Supplier: { value: supplier, testId: 'value-contract-supplier' },
            Contractor: { value: contractor },
            Type: { value: type, testId: 'value-contract-type' },
            Currency: { value: currency, testId: 'value-contract-currency' }
          }
        }
      ];
    }
  );

export const selectContractDetailsCommonData = (contractId: string) =>
  createSelector(
    selectDetailsData,
    selectContractCurrency(contractId),
    (details, currency) => {
      const formatMoney = currency ? createMoneyFormatter(currency) : null;

      const lastIncome = formatMoney
        ? formatMoney(details?.previousIncome || 0)
        : EMPTY_DATA_PLACEHOLDER;
      const totalEarnings = formatMoney
        ? formatMoney(details?.totalEarnings || 0)
        : EMPTY_DATA_PLACEHOLDER;

      const paid = protectValue(details?.workItems?.paid || 0);
      const signed = protectValue(details?.workItems?.signed || 0);
      const forSignature = protectValue(details?.workItems?.waiting || 0);

      return [
        {
          title: 'Details',
          values: {
            'Total Earnings': {
              value: totalEarnings,
              testId: 'value-total-earnings',
              hint: 'Total contract revenue for all time'
            },
            'Last Income': {
              value: lastIncome,
              testId: 'value-last-income',
              hint: 'Latest transfer to your account under this contract'
            }
          }
        },
        {
          title: 'Work Items',
          hint: 'All tasks that you’ve done',
          values: {
            'Waiting for signature': {
              value: forSignature,
              testId: 'value-wi-waiting-for-signature',
              hint: 'Works not yet approved'
            },
            Signed: {
              value: signed,
              testId: 'value-wi-signed',
              hint: 'Works approved for payment'
            },
            Paid: {
              value: paid,
              testId: 'value-wi-paid',
              hint: 'Number of already paid works'
            }
          }
        }
      ];
    }
  );

export const selectDependenciesStatus = createSelector(
  selectSettingsStatus,
  calculateStatus
);

export const selectDomainStatus = createSelector(
  selectDependenciesStatus,
  selectContractsStatus,
  calculateStatus
);

type ApiPartyWithMeta = ApiParty & {
  activeContract: boolean;
};

export const selectUserContractors = createSelector(
  selectContracts,
  contracts => {
    const map = Object.values(contracts || {}).reduce<
      Record<string, ApiPartyWithMeta>
    >((result, contract) => {
      const element: ApiPartyWithMeta = result[contract.contractor.uuid] || {
        activeContract: contract.active,
        ...contract.contractor
      };

      result[contract.contractor.uuid] = {
        ...element,
        activeContract: element.activeContract || contract.active
      };

      return result;
    }, {});

    return Object.values(map);
  }
);

export const selectUserActiveContractors = createSelector(
  selectUserContractors,
  contractors => contractors.filter(contractor => contractor.activeContract)
);

const mapContractors = (
  contractors: ApiPartyWithMeta[],
  defaultContractor: string
) =>
  contractors
    .map(contractor => ({
      uuid: contractor.uuid,
      title: contractor.name
    }))
    .sort(first => (first.uuid === defaultContractor ? -1 : 0));

export const selectUserActiveContractorsList = createSelector(
  selectUserActiveContractors,
  selectDefaultContractor,
  mapContractors
);

export const selectUserContractorsList = createSelector(
  selectUserContractors,
  selectDefaultContractor,
  mapContractors
);
