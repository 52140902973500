import { AnyAction } from 'typescript-fsa';

import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { ContractorAccountsState } from './interface';
import { loadContractorAccountsAsync, domainAction } from './actions';

const initialState: ContractorAccountsState = {
  status: EntityStatus.initial
};

const reducer = (
  state = initialState,
  action: AnyAction
): ContractorAccountsState => {
  if (domainAction.reset.match(action)) {
    return { ...initialState };
  }

  if (loadContractorAccountsAsync.done.match(action)) {
    return {
      ...state,
      data: action.payload.result
    };
  }

  return state;
};

export const contractorAccountsReducer = withStatusReducer(
  loadContractorAccountsAsync,
  reducer
);
