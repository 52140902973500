import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { linkUnlimint } from './actions';
import { UnlimintState } from './interface';

const initialState: UnlimintState = {
  status: EntityStatus.initial
};

export const reducer = (state = initialState) => state;

export const unlimintReducer = withStatusReducer(linkUnlimint, reducer);
