import { DepartmentsStoreSegment } from './interface';

export const selectDepartmentsSegment = (state: DepartmentsStoreSegment) =>
  state.departments;

export const selectDepartmentsAsyncEntityStatus = (
  state: DepartmentsStoreSegment
) => {
  const segment = selectDepartmentsSegment(state);
  return segment.status;
};

export const selectDepartmentsAsyncEntityData = (
  state: DepartmentsStoreSegment
) => {
  const segment = selectDepartmentsSegment(state);
  return segment.data;
};
