import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { AnyAction } from 'typescript-fsa';

import { ContractDetailsState } from './interface';
import { loadingAction, domainAction } from './actions';

const initialState: ContractDetailsState = {
  status: EntityStatus.initial
};

const reducer = (
  state = initialState,
  action: AnyAction
): ContractDetailsState => {
  if (domainAction.reset.match(action)) {
    return { ...initialState };
  }

  if (loadingAction.done.match(action)) {
    return { ...state, data: action.payload.result };
  }

  return state;
};

export const detailsReducer = withStatusReducer(loadingAction, reducer);
