import { push } from 'connected-react-router';
import { map, mergeMap } from 'rxjs/operators';

import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { parseServerError } from '@tsp/api/utils/parseServerError';

import { pushNotification } from '../notifications';

import { linkRevolut } from './actions';
import { RevolutEpicDependencies } from './interface';
import { translateLinkFormValuesToRequest } from './translators';

const linkRevolutEpic: Epic<unknown, RevolutEpicDependencies> = (
  actions$,
  _,
  deps
) =>
  actions$.pipe(
    ofAction(linkRevolut.started),
    mergeMap(action => {
      const request = translateLinkFormValuesToRequest(action.payload.data);

      return deps.api.linkRevolut(request).pipe(
        map(result => {
          return linkRevolut.done({ params: action.payload, result });
        }),
        catchAsyncError(error =>
          linkRevolut.failed({
            error: parseServerError(error),
            params: action.payload
          })
        )
      );
    })
  );

const linkSuccessEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkRevolut.done),
    map(() => push('/accounts'))
  );

const errorNotificationsEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkRevolut.failed),
    mergeMap(action => {
      return typeof action.payload.error === 'string'
        ? [
            pushNotification({
              type: 'error',
              message: action.payload.error
            })
          ]
        : [];
    })
  );

const successResultEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkRevolut.done),
    mergeMap(action => {
      action.payload.params.callback();
      return [];
    })
  );

const errorResultEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkRevolut.failed),
    mergeMap(action => {
      const errors: Object =
        typeof action.payload.error === 'string' ? {} : action.payload.error;

      action.payload.params.callback(errors);

      return [];
    })
  );

export const revolutEpic = combineEpics(
  linkSuccessEpic,
  errorResultEpic,
  linkRevolutEpic,
  successResultEpic,
  errorNotificationsEpic
);
