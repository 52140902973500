import { AccountProvider } from '../settings';

export const getAccountNameByType = (type: AccountProvider) => {
  switch (type) {
    case AccountProvider.Paysera:
      return 'Paysera';
    case AccountProvider.Payoneer:
      return 'Payoneer';
    case AccountProvider.Ecommpay:
    case AccountProvider.EcommpayCard:
      return 'Ecommpay';
    case AccountProvider.Epayments:
      return 'Epayments';
    case AccountProvider.Unlimint:
      return 'Unlimint';
    case AccountProvider.Bank131:
    case AccountProvider.Bank131Card:
      return 'Bank131';
    case AccountProvider.IdBank:
      return 'IDBank';
    case AccountProvider.Monetix:
    case AccountProvider.MonetixCard:
      return 'Monetix';
    case AccountProvider.CitiBankSEPA:
      return 'CitiBank.SEPA';
  }
};
