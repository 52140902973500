import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';
import { createDomainAction } from '@tsp/core/features/dependencies';

import { createFilterPresetsActions } from '../filters';

import {
  ToolsFormValues,
  LineItemListRequest,
  LineItemListResponse,
  ToolsFormAdditionalValues
} from './interface';

const createAction = actionCreatorFactory('LINE_ITEMS');

export const loadLineItemsAsync = createAsyncAction<
  LineItemListRequest,
  LineItemListResponse
>(createAction);

export const reloadLineItems = createAction<ToolsFormValues>('RELOAD');

export const presetsActions =
  createFilterPresetsActions<ToolsFormAdditionalValues>(createAction);

export const domainAction = createDomainAction(createAction);
