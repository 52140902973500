import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EntityStatus } from '@tsp/utils/asyncEntity';

import { DomainAction } from './interface';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface Props {
  domainAction: DomainAction;
  selectStatus: (state: any) => EntityStatus;
}

export const useDependenciesLoading = ({
  domainAction,
  selectStatus
}: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const status = useSelector(selectStatus);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (status === EntityStatus.fetched) {
        setLoading(false);
      }
    }, 100);

    return () => clearInterval(timer);
  }, [status]);

  useEffect(
    () => () => {
      dispatch(domainAction.reset());
    },
    [dispatch, domainAction]
  );

  const onStart = useCallback(() => {
    dispatch(domainAction.init());
  }, [dispatch, domainAction]);

  return { loading, onStart };
};
