import { actionCreatorFactory } from 'typescript-fsa';
import { createAsyncAction } from '@tsp/utils/asyncEntity';

import { GetLinkAccountUrlRequest, GetLinkAccountUrlResponse } from '../payout';

import {
  LinkBank131Error,
  LinkBank131Request,
  LinkBank131Response
} from './interface';

const createAction = actionCreatorFactory('BANK131');

export const linkBank131 = createAction.async<
  LinkBank131Request,
  LinkBank131Response,
  LinkBank131Error
>('LINK');

export const loadBank131ApiTokenAsync = createAsyncAction<
  GetLinkAccountUrlRequest,
  GetLinkAccountUrlResponse
>(createAction);
