import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { LinkIdBankRequest, LinkIdBankResponse } from './interface';

const linkIdBank = defineHandler<LinkIdBankRequest, LinkIdBankResponse>({
  path: '/linkIdBankAccount',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const idBankApi = {
  linkIdBank
};
