import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { AnyAction } from 'typescript-fsa';

import { Bank131State } from './interface';
import { linkBank131, loadBank131ApiTokenAsync } from './actions';

const initialState: Bank131State = {
  status: EntityStatus.initial,
  data: {
    apiToken: null
  }
};

export const reducer = (
  state = initialState,
  action: AnyAction
): Bank131State => {
  if (loadBank131ApiTokenAsync.done.match(action)) {
    return {
      ...state,
      data: {
        apiToken: action.payload.result.link
      }
    };
  }

  return state;
};

export const bank131Reducer = withStatusReducer(linkBank131, reducer);
