import { interval } from 'rxjs';
import { Action } from 'typescript-fsa/src';
import { filter, map, mergeMap, first, pairwise } from 'rxjs/operators';

import { createLoadingEpic } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';

import { pushNotification } from '../notifications';
import { selectEnvValues, EnvSegment } from '../env';

import {
  fetchUser,
  logoutAction,
  loadingAction,
  fetchUserPositions
} from './actions';
import {
  UserStoreSegment,
  UserEpicDependencies,
  GetUserStatusRequest,
  GetUserStatusResponse,
  GetUserPositionsStatusResponse
} from './interface';
import { translateUserPositions } from './translators';

export type State = UserStoreSegment & EnvSegment;

//TODO: Refresh token every 5 minutes
const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 5;

const loadingEpic = createLoadingEpic({
  action: loadingAction,
  selectFetcher: (deps: UserEpicDependencies) => deps.api.getUserStatus
});

const loadingPositionsEpic = createLoadingEpic({
  action: fetchUserPositions,
  selectFetcher: (deps: UserEpicDependencies) => deps.api.getUserPositions
});

export const loadingUserStartEpic: Epic<State, {}> = (actions$, state$) =>
  actions$.pipe(
    ofAction(fetchUser.started),
    map(action => {
      const { APP_ORIGIN } = selectEnvValues(state$.value);
      const appUrl = APP_ORIGIN;
      return loadingAction.started({ appUrl, ...action.payload });
    })
  );

export const loadingPositionsStartEpic: Epic<State, {}> = actions$ =>
  actions$.pipe(
    ofAction(loadingAction.done),
    map(({ payload }) =>
      payload.result.authenticated
        ? fetchUserPositions.started({})
        : fetchUserPositions.done({ result: [], params: {} })
    )
  );

const userLoadingDoneEpic: Epic<State, {}> = actions$ =>
  actions$.pipe(
    ofAction(loadingAction.done, fetchUserPositions.done),
    pairwise(),
    map(actions => {
      const fetchUserAction = actions.find(action =>
        loadingAction.done.match(action)
      ) as Action<{
        params: GetUserStatusRequest;
        result: GetUserStatusResponse;
      }>;

      const fetchPositionsResult = actions.find(action =>
        fetchUserPositions.done.match(action)
      ) as Action<{ result: GetUserPositionsStatusResponse }>;

      return fetchUser.done({
        params: fetchUserAction.payload.params,
        result: {
          ...fetchUserAction.payload.result,
          positions: translateUserPositions(fetchPositionsResult.payload.result)
        }
      });
    })
  );

export const updateTokenEpic: Epic<State, UserEpicDependencies> = (
  actions$,
  _state$,
  deps
) =>
  actions$.pipe(
    ofAction(loadingAction.done),
    filter(action => action.payload.result.authenticated),
    map(action => action.payload.result.token),
    mergeMap(token => {
      deps.api.setBearerToken(token);
      return [];
    })
  );

export const initSentryEpic: Epic<State, UserEpicDependencies> = (
  actions$,
  state$,
  deps
) =>
  actions$.pipe(
    ofAction(loadingAction.done),
    filter(action => action.payload.result.authenticated),
    first(),
    mergeMap(action => {
      const { API_SERVER: apiServer } = selectEnvValues(state$.value);

      const {
        payload: {
          result: { token, user }
        }
      } = action;

      deps.initSentry({
        token,
        apiServer,
        user: {
          id: user.uuid,
          email: user.email,
          username: user.screenName
        }
      });

      return [];
    })
  );

const logoutEpic: Epic<State, UserEpicDependencies> = (
  actions$,
  state$,
  deps
) =>
  actions$.pipe(
    ofAction(logoutAction),
    mergeMap(() => {
      deps.api.setBearerToken('');
      window.location.href = state$.value.user.data.logoutUrl;

      return [];
    })
  );

const autoRefreshTokenEpic: Epic<State, UserEpicDependencies> = (
  actions$,
  state$,
  deps
) =>
  actions$.pipe(
    ofAction(loadingAction.done),
    filter(action => action.payload.result.authenticated),
    mergeMap(() =>
      interval(REFRESH_TOKEN_INTERVAL).pipe(
        mergeMap(() => {
          const { APP_ORIGIN } = selectEnvValues(state$.value);
          const appUrl = APP_ORIGIN;
          return deps.api.refreshToken({ appUrl }).pipe(mergeMap(() => []));
        })
      )
    )
  );

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadingAction.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

export const userEpic = combineEpics(
  logoutEpic,
  loadingEpic,
  initSentryEpic,
  updateTokenEpic,
  userLoadingDoneEpic,
  autoRefreshTokenEpic,
  loadingPositionsEpic,
  loadingUserStartEpic,
  errorNotificationEpic,
  loadingPositionsStartEpic
);
