import { createSelector } from 'reselect';

import { AccountProvider } from '../settings';

import { getAccountNameByType } from './helpers';
import { UserAccountsStoreSegment } from './interface';

export const selectUserAccountsSegment = (state: UserAccountsStoreSegment) => {
  return state.userAccounts;
};

export const selectUserAccountsStatus = (state: UserAccountsStoreSegment) => {
  return state.userAccounts.status;
};

export const selectUserAccountsData = (state: UserAccountsStoreSegment) => {
  const segment = selectUserAccountsSegment(state);
  return segment.data;
};

export const selectUserAccounts = createSelector(
  selectUserAccountsData,
  data => data?.userAccounts
);

export const selectUserAccount = (userAccountId: string) =>
  createSelector(selectUserAccountsSegment, segment => {
    return (segment.data?.userAccounts || []).find(
      account => account.uuid === userAccountId
    );
  });

export const selectFirstPrimaryMethod = createSelector(
  selectUserAccountsSegment,
  segment => {
    const defaultAccounts = Object.values(segment.data?.defaultAccounts || {});

    return defaultAccounts.reduce<string | undefined>((result, pair) => {
      const accounts = Object.values(pair);
      return result ? result : accounts[0];
    }, undefined);
  }
);

export const selectPrimaryMethods = (contractor: string) =>
  createSelector(
    selectUserAccountsSegment,
    segment => segment.data.defaultAccounts[contractor] || {}
  );

export const selectPrimaryMethodsList = (contractor: string) =>
  createSelector(selectPrimaryMethods(contractor), primaryMethods =>
    Object.values(primaryMethods)
  );

export const selectPrimaryAccountFullName = createSelector(
  selectFirstPrimaryMethod,
  selectUserAccountsSegment,
  (primaryAccount, segment) => {
    const account = (segment.data?.userAccounts || []).find(
      account => account.uuid === primaryAccount
    );

    const isCard =
      account.systemType === AccountProvider.Ecommpay ||
      account.systemType === AccountProvider.EcommpayCard ||
      account.systemType === AccountProvider.Bank131 ||
      account.systemType === AccountProvider.Bank131Card ||
      account.systemType === AccountProvider.Monetix ||
      account.systemType === AccountProvider.MonetixCard;

    const number =
      (isCard ? account.details?.number : account.externalId) || '';

    return account
      ? `${getAccountNameByType(account.systemType)} (${number})`
      : '';
  }
);

export const selectHasPrimaryMethod = createSelector(
  selectUserAccountsSegment,
  segment => Object.values(segment.data?.defaultAccounts || {}).length > 0
);

export const selectHasAccount = (provider: AccountProvider) =>
  createSelector(
    selectUserAccounts,
    accounts =>
      !!(accounts || []).find(account => account.systemType === provider)
  );

// export const selectNextPayoutTsFormatted = createSelector(
//   selectUserAccountsSegment,
//   segment => {
//     return segment.data ? format(segment.data.nextPayoutTs, 'MMMM, d') : '';
//   }
// );
