import { createWithDefaultSorting } from '@tsp/utils/translators/createWithSorting';

import { rangeToDateRequest } from '../filters/utils/periodToDateRange';
import { reduceAdditionalFilters } from '../filters/utils/reduceAdditionalFilters';

import { ToolsFormValues } from './interface';

export const translateFilterToRequest = createWithDefaultSorting(
  (values: ToolsFormValues) => {
    const { search, pagination, period, ...additional } = values;

    const { dateFrom, dateTo } = rangeToDateRequest(period);

    const mainValues = {
      search,
      dateTo,
      dateFrom,
      limit: pagination.limit,
      page: pagination.page + 1,

      'filter[0][value]': 0,
      'filter[0][type]': 'gt',
      'filter[0][field]': 'totalAmountStorable'
    };

    const additionalValues = reduceAdditionalFilters(
      additional,
      (key, value) => {
        if (key === 'sort') {
          return {};
        }

        if (key === 'workType') {
          return {
            'filter[1][value]': value,
            'filter[1][type]': 'eq',
            'filter[0][type]': 'innerjoin',
            'filter[0][alias]': '_workItem',
            'filter[0][field]': 'workItem',
            'filter[1][alias]': '_workItem',
            'filter[1][field]': 'workType'
          };
        }

        return { [key]: value };
      }
    );

    return { ...mainValues, ...additionalValues };
  }
);
