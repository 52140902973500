import format from 'date-fns/format';
import { createSelector } from 'reselect';

import { calculateStatus } from '@tsp/utils/asyncEntity/utils/calculateStatus';

import {
  selectWorkTypes,
  selectSettingsStatus,
  selectProjectsPortfolio
} from '../settings';
import {
  selectContracts,
  selectContractsByType,
  selectContractsStatus,
  selectContractConditions,
  createWorkTypesFilterSelector,
  selectFormContractFieldValues,
  selectContractsByTypeIncludingUniversal
} from '../contracts';
import { selectDefaultCurrency } from '../env';
import { createMoneyFormatter } from '../money';
import { hasOnlyCreatedDate } from '../history/utils/hasOnlyCreatedDate';
import { historyToHistoryData } from '../history/utils/historyToHistoryData';
import { mapDocumentAttachmentToFileInputAttachment } from '../workItems/utils/mapDocumentAttachmentToFileInputAttachment';

import {
  FormData,
  CustomWork,
  CustomWorkTableView,
  CustomWorkDetailsView,
  CustomWorksStoreSegment,
  CUSTOM_WORKS_CONTRACT_TYPE
} from './interface';
import { getUniqConditions, createCWOptionNameGetter } from './utils';

export const selectCustomWorksSegment = (state: CustomWorksStoreSegment) =>
  state.customWorks;

export const selectCustomWorksData = (state: CustomWorksStoreSegment) => {
  const segment = selectCustomWorksSegment(state);
  return segment.data;
};

export const selectTotalSumData = (state: CustomWorksStoreSegment) => {
  const segment = selectCustomWorksSegment(state);
  return segment.totalSum;
};

export const selectCustomWorksStatus = (state: CustomWorksStoreSegment) => {
  const segment = selectCustomWorksSegment(state);
  return segment.status;
};

export const selectCustomWork = (workId?: string) =>
  createSelector(selectCustomWorksData, data =>
    (data || []).find(work => work.uuid === workId)
  );

export const selectCustomWorksMap = createSelector(
  selectCustomWorksData,
  data => {
    return (data || []).reduce<Record<string, CustomWork>>(
      (result, customWork) => {
        result[customWork.uuid] = customWork;
        return result;
      },
      {}
    );
  }
);

export const selectCustomWorkHistoryData = (workId: string) =>
  createSelector(selectCustomWorksMap, works => {
    const work = works?.[workId];

    const history = work?.history;
    const supervisor = work?.supervisor;

    return historyToHistoryData(history, supervisor);
  });

export const selectCustomWorksTableData = createSelector(
  selectCustomWorksData,
  selectProjectsPortfolio,
  selectContracts,
  (works, portfolios, contracts) => {
    return works.map<CustomWorkTableView>(work => {
      const period = format(work.completedAt, 'MMMM, uuuu');

      const { currency } = contracts[work.contract];
      const formatMoney = createMoneyFormatter(currency);

      const property =
        portfolios[work.locale]?.brands[work.brand]?.properties[work.property]
          ?.label || '';

      const attachment = work.attachment.map(
        mapDocumentAttachmentToFileInputAttachment(work.uuid)
      );

      return {
        period,
        property,
        attachment,
        uuid: work.uuid,
        teamwoxId: work.teamwoxId,
        description: work.description,
        amount: formatMoney(work.amount),
        createdAt: work.history.createdAt,
        asanaId: `custom_work/${work.uuid}`,
        communicationLink: work.communicationLink,
        highlight: hasOnlyCreatedDate(work.history),
        history: historyToHistoryData(work.history, work.supervisor)
      };
    });
  }
);

export const selectHasCustomWorkContractsForForm = createSelector(
  selectFormContractFieldValues(CUSTOM_WORKS_CONTRACT_TYPE),
  contracts => contracts.length > 0
);

export const selectHasUniversalContractsForForm = createSelector(
  selectFormContractFieldValues(null),
  contracts => contracts.length > 0
);

export const selectHasCustomWorkContracts = createSelector(
  selectContractsByType(CUSTOM_WORKS_CONTRACT_TYPE),
  contracts => contracts.length > 0
);

export const selectHasUniversalContracts = createSelector(
  selectContractsByType(null),
  contracts => contracts.length > 0
);

export const selectHasCustomWorkCompatibleContractsForForm = createSelector(
  selectHasCustomWorkContractsForForm,
  selectHasUniversalContractsForForm,
  (hasCWContracts, hasUniversalContracts) =>
    hasCWContracts || hasUniversalContracts
);

export const selectHasCustomWorkCompatibleContracts = createSelector(
  selectHasCustomWorkContracts,
  selectHasUniversalContracts,
  (hasCWContracts, hasUniversalContracts) =>
    hasCWContracts || hasUniversalContracts
);

export const selectOptionFieldSelector = createSelector(
  selectWorkTypes,
  workTypes => createCWOptionNameGetter(workTypes)
);

export const selectPropertyReadonly = (property: string) =>
  createSelector(selectProjectsPortfolio, portfolios => {
    const result: string[] = [];

    Object.values(portfolios).find(portfolio => {
      return Object.values(portfolio.brands).find(brand => {
        return Object.entries(brand.properties).find(([uuid, { label }]) => {
          if (property === uuid) {
            result.push(portfolio.name, brand.label, label);
            return true;
          }
          return false;
        });
      });
    });

    return result;
  });

export const selectConditionProperty = (
  contractId: string,
  conditionId: string
) =>
  createSelector(selectContractConditions(contractId), conditions => {
    return conditions?.[conditionId]?.property;
  });

export const selectFormState = (state: CustomWorksStoreSegment) =>
  state.customWorks.form;

export const selectCustomWorksDetails = (workId: string) =>
  createSelector(
    selectCustomWorksMap,
    selectProjectsPortfolio,
    selectContracts,
    selectProjectsPortfolio,
    (works, portfolios, contracts, locales) => {
      const work = works[workId];

      if (!work) {
        return undefined;
      }

      const brand = portfolios[work.locale].brands[work.brand];
      const platform = brand.properties[work.property].label;

      const { currency, title: contractName } = contracts[work.contract];
      const formatMoney = createMoneyFormatter(currency);

      const period = format(work.completedAt, 'MMMM, uuuu');

      const attachment = work.attachment.map(
        mapDocumentAttachmentToFileInputAttachment(work.uuid)
      );

      const view: CustomWorkDetailsView = {
        period,
        platform,
        attachment,
        brand: brand.label,
        contract: contractName,
        supervisor: work.supervisor,
        amount: formatMoney(work.amount),
        description: work.description || '',
        locale: locales[work.locale]?.name || 'N/A'
      };

      return view;
    }
  );

export const selectCustomWorkFormData = (workId: string) =>
  createSelector(
    selectCustomWorksMap,
    selectContracts,
    selectOptionFieldSelector,
    (works, contracts, getCWOptionName) => {
      const customWork = works[workId];

      if (!customWork) {
        return undefined;
      }

      const uniqConditions = getUniqConditions(
        contracts[customWork.contract].conditions || {}
      );

      const condition = Object.values(uniqConditions).find(condition => {
        return (
          condition.workType === customWork.workType &&
          condition.workSubtype === customWork.workSubType &&
          (condition.property === customWork.property ||
            condition.property === null)
        );
      });

      const attachment = customWork.attachment.map(
        mapDocumentAttachmentToFileInputAttachment(customWork.uuid)
      );

      const conditionValue = {
        uuid: condition.id,
        title: getCWOptionName(condition),
        data: {
          workType: condition.workType,
          propertyUuid: condition.property,
          workSubType: condition.workSubtype
        }
      };

      const formData: FormData = {
        attachment,
        brand: customWork.brand,
        locale: customWork.locale,
        condition: conditionValue,
        contract: customWork.contract,
        property: customWork.property,
        completedAt: customWork.completedAt,
        description: customWork.description,
        totalAmountStorable: String(customWork.amount / 100)
      };

      return formData;
    }
  );

export const selectWorkTypesForFilter = createWorkTypesFilterSelector(
  selectContractsByTypeIncludingUniversal(CUSTOM_WORKS_CONTRACT_TYPE)
);

export const selectCustomWorksFilterPresets = createSelector(
  selectCustomWorksSegment,
  segment => segment.presets
);

export const selectCustomWorksFilterPresetInfo = createSelector(
  selectCustomWorksFilterPresets,
  presets =>
    Object.entries(presets)
      .map(([id, preset]) => ({
        id: Number(id),
        title: preset.title
      }))
      .sort((first, second) => second.id - first.id)
);

export const selectDefaultCurrencyTotalSum = createSelector(
  selectTotalSumData,
  selectDefaultCurrency,
  (totals, defaultCurrency) => {
    const value = totals[defaultCurrency] || 0;
    const formatMoney = createMoneyFormatter(defaultCurrency, true);

    return formatMoney(value);
  }
);

export const selectDependenciesStatus = createSelector(
  selectSettingsStatus,
  selectContractsStatus,
  calculateStatus
);

export const selectDomainStatus = createSelector(
  selectDependenciesStatus,
  selectCustomWorksStatus,
  calculateStatus
);
