import React from 'react';

import { openAsana } from '../../features/external';
import { linkAccount } from '../../features/payout/actions';
import { createExternalDispatchPage } from '../../features/external/components/ExternalDispatch';

export const ExternalDispatchPage = createExternalDispatchPage({
  asana: {
    action: openAsana.started
  },
  account: {
    action: linkAccount.started
  }
});
