export const EnvSchema = {
  GA_ID: '',
  SSO_URL: '',
  API_SERVER: '',
  APP_ORIGIN: '',
  TEAMWOX_URL: '',
  FIN_DEP_LINK: '',
  DEFAULT_CURRENCY: '',
  DEFAULT_CONTRACTOR: '',
  BANK131_WIDGET_HOST: '',
  BANK131_PROXY_HOST: ''
};

export const ENV_ENTRY_POINT = '/env';

export type RuntimeEnvironments = typeof EnvSchema;
