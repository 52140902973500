import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';

import { GetDepartmentsRequest, GetDepartmentsResponse } from './interface';

const createAction = actionCreatorFactory('DEPARTMENTS');

export const loadDepartmentsAction = createAsyncAction<
  GetDepartmentsRequest,
  GetDepartmentsResponse
>(createAction);

export const loadDepartmentsOnceAction = createAction.async('LOAD_ONCE');
