import { map, mergeMap } from 'rxjs/operators';

import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { parseServerError } from '@tsp/api/utils/parseServerError';

import { EnvSegment, selectEnvValues } from '../env';
import { pushNotification } from '../notifications';

import { linkRestaff } from './actions';
import { RestaffEpicDependencies } from './interface';
import { translateFormDataToRequest } from './translators';

type State = EnvSegment;
type RestaffEpic = Epic<State, RestaffEpicDependencies>;

const linkRestaffEpic: RestaffEpic = (actions$, state$, deps) =>
  actions$.pipe(
    ofAction(linkRestaff.started),
    mergeMap(action => {
      const env = selectEnvValues(state$.value);
      const request = translateFormDataToRequest(action.payload.data, env);

      return deps.api.linkRestaff(request).pipe(
        map(result => {
          return linkRestaff.done({ params: action.payload, result });
        }),
        catchAsyncError(error =>
          linkRestaff.failed({
            error: parseServerError(error),
            params: action.payload
          })
        )
      );
    })
  );

const errorNotificationsEpic: RestaffEpic = actions$ =>
  actions$.pipe(
    ofAction(linkRestaff.failed),
    mergeMap(action => {
      return typeof action.payload.error === 'string'
        ? [
            pushNotification({
              type: 'error',
              message: action.payload.error
            })
          ]
        : [];
    })
  );

const successCallbackEpic: RestaffEpic = actions$ =>
  actions$.pipe(
    ofAction(linkRestaff.done),
    mergeMap(action => {
      action.payload.params.callback();
      return [];
    })
  );

const errorCallbackEpic: RestaffEpic = actions$ =>
  actions$.pipe(
    ofAction(linkRestaff.failed),
    mergeMap(action => {
      const errors: Object =
        typeof action.payload.error === 'string' ? {} : action.payload.error;

      action.payload.params.callback(errors);

      return [];
    })
  );

export const restaffEpic = combineEpics(
  errorCallbackEpic,
  linkRestaffEpic,
  successCallbackEpic,
  errorNotificationsEpic
);
