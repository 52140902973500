import { map } from 'rxjs/operators';

import { createLoadingEpic } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';

import { pushNotification } from '../notifications';

import { loadingAction } from './actions';
import { DetailsEpicDependencies } from './interface';

const loadingEpic = createLoadingEpic({
  action: loadingAction,
  selectFetcher: (deps: DetailsEpicDependencies) => deps.api.getDetails
});

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadingAction.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

export const detailsEpic = combineEpics(loadingEpic, errorNotificationEpic);
