import { debounceTime, map, mergeMap } from 'rxjs/operators';

import { createLoadingEpic } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { createPageInitEpic } from '@tsp/core/features/dependencies';

import {
  loadingAction as loadingContracts,
  domainAction as contractsDomainAction
} from '../contracts';
import { pushNotification } from '../notifications';
import { loadSettingsOnce } from '../settings/actions';
import { domainAction as detailsDomainAction } from '../details';

import {
  domainAction,
  presetsActions,
  reloadLineItems,
  loadLineItemsAsync
} from './actions';
import {
  selectDependenciesStatus,
  selectWorkItemsFilterPresets
} from './selectors';
import { toolsFormInitialValues } from './constants';
import { translateFilterToRequest } from './translators';
import { LineItemsEpicDependencies, LineItemsStoreSegment } from './interface';

const updateFilterEpic: Epic = $actions =>
  $actions.pipe(
    ofAction(reloadLineItems),
    debounceTime(500),
    map(action => {
      const request = translateFilterToRequest(action.payload);
      return loadLineItemsAsync.started(request);
    })
  );

const loadingStartEpic = createLoadingEpic({
  action: loadLineItemsAsync,
  selectFetcher: (deps: LineItemsEpicDependencies) => deps.api.getLineItems
});

const updateFilterPresetsEpic: Epic<
  LineItemsStoreSegment,
  LineItemsEpicDependencies
> = (actions$, state$, deps) =>
  actions$.pipe(
    ofAction(presetsActions.delete, presetsActions.push),
    map(action => {
      const presets = selectWorkItemsFilterPresets(state$.value);
      deps.setRecovery({ lineItems: { presets } });

      const messageAction = presetsActions.push.match(action)
        ? 'saved'
        : 'deleted';

      const title = action.payload.preset.title;

      return pushNotification({
        type: 'success',
        message: `Filter preset "${title}" ${messageAction}`
      });
    })
  );

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadLineItemsAsync.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

const resetDependenciesEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.reset),
    mergeMap(() => [contractsDomainAction.reset(), detailsDomainAction.reset()])
  );

const dependenciesEpic = createPageInitEpic({
  domainAction,
  selectStatus: selectDependenciesStatus,
  dependencies: [loadSettingsOnce, loadingContracts]
});

const loadMainDataEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.success),
    map(() => reloadLineItems(toolsFormInitialValues))
  );

export const workItemsEpic = combineEpics(
  loadingStartEpic,
  updateFilterEpic,
  loadMainDataEpic,
  dependenciesEpic,
  resetDependenciesEpic,
  errorNotificationEpic,
  updateFilterPresetsEpic
);
