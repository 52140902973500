import { actionCreatorFactory } from 'typescript-fsa';

import {
  FormData,
  LinkKonsoleProError,
  LinkKonsoleProResponse
} from './interface';

type Callback = (errors?: Object) => void;

const createAction = actionCreatorFactory('KONSOLE_PRO');

export const resetKonsolePro = createAction('RESET');

export const linkKonsolePro = createAction.async<
  { data: FormData; callback: Callback },
  LinkKonsoleProResponse,
  LinkKonsoleProError
>('LINK');
