import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Box } from '@tsp/tsp-frontend-components/mui';

import { GetUserStatusResponse, useStatus } from '../../features/user';

const classes = {
  root: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: '#F0F0F0'
  } as const
};

export const AuthPage = () => {
  const dispatch = useDispatch();

  const onComplete = useCallback(
    (data: GetUserStatusResponse) => {
      if (data.authenticated) {
        dispatch(push('/'));
      } else {
        window.location.href = data.loginUrl;
      }
    },
    [dispatch]
  );

  useStatus({ onComplete });

  return <Box sx={classes.root} />;
};
