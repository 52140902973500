import format from 'date-fns/format';
import { createSelector } from 'reselect';

import { toUTC } from '@tsp/utils/time/convert';
import { createInRangeDefiner } from '@tsp/utils/time/inRange';
import { createFormatter } from '@tsp/utils/format/createFormatter';
import { calculateStatus } from '@tsp/utils/asyncEntity/utils/calculateStatus';

import {
  selectWorkTypes,
  SettingsWorkType,
  selectContractTypes,
  selectSettingsStatus
} from '../settings';
import {
  selectContracts,
  selectContractsByType,
  selectContractsStatus,
  selectContractConditions,
  createWorkTypesFilterSelector,
  selectFormContractFieldValues
} from '../contracts';
import { selectUserPositions } from '../user';
import { selectDefaultCurrency } from '../env';
import { createMoneyFormatter } from '../money';
import { hasOnlyCreatedDate } from '../history/utils/hasOnlyCreatedDate';
import { historyToHistoryData } from '../history/utils/historyToHistoryData';
import { mapDocumentAttachmentToFileInputAttachment } from '../workItems/utils/mapDocumentAttachmentToFileInputAttachment';
import {
  selectDepartmentsAsyncEntityData,
  selectDepartmentsAsyncEntityStatus
} from '../departments';

import { selectDepartmentByUuid } from './helpers';
import {
  FormData,
  CustomPaymentTableView,
  CustomPaymentDetailsView,
  CustomPaymentsStoreSegment,
  CUSTOM_PAYMENT_CONTRACT_TYPE
} from './interface';

export const selectCustomPaymentsSegment = (
  state: CustomPaymentsStoreSegment
) => state.customPayments;

export const selectCustomPaymentsData = (state: CustomPaymentsStoreSegment) => {
  const segment = selectCustomPaymentsSegment(state);
  return segment.data;
};

export const selectTotalSumData = (state: CustomPaymentsStoreSegment) => {
  const segment = selectCustomPaymentsSegment(state);
  return segment.totalSum;
};

export const selectCustomPaymentsStatus = (
  state: CustomPaymentsStoreSegment
) => {
  const segment = selectCustomPaymentsSegment(state);
  return segment.status;
};

export const selectCustomPayment = (uuid: string) =>
  createSelector(selectCustomPaymentsSegment, segment => {
    return segment.data.find(payment => payment.uuid === uuid);
  });

export const selectCustomPaymentsHistory = (uuid: string) =>
  createSelector(selectCustomPayment(uuid), payment => {
    const history = payment?.history;
    const supervisor = payment?.supervisor;

    return historyToHistoryData(history, supervisor);
  });

export const selectCustomPaymentsTableData = createSelector(
  selectCustomPaymentsData,
  selectContracts,
  selectWorkTypes,
  (payments, contracts, workTypes: Record<string, SettingsWorkType>) => {
    return payments.map<CustomPaymentTableView>(payment => {
      const { currency } = contracts[payment.contract];

      const workType = workTypes[payment.workType]?.name || 'N/A';

      const period = format(payment.completedAt, 'MMMM, uuuu');

      const formatMoney = createFormatter(createMoneyFormatter(currency));

      const attachment = payment.attachment.map(
        mapDocumentAttachmentToFileInputAttachment(payment.uuid)
      );

      return {
        period,
        workType,
        attachment,
        uuid: payment.uuid,
        description: payment.description,
        amount: formatMoney(payment.amount),
        createdAt: payment.history.createdAt,
        asanaId: `custom_payment/${payment.uuid}`,
        communicationLink: payment.communicationLink,
        highlight: hasOnlyCreatedDate(payment.history),
        history: historyToHistoryData(payment.history, payment.supervisor)
      };
    });
  }
);

export const selectCustomPaymentsDetails = (uuid: string) =>
  createSelector(
    selectCustomPayment(uuid),
    selectContracts,
    selectDepartmentsAsyncEntityData,
    selectWorkTypes,
    (
      payment,
      contracts,
      departments,
      workTypes: Record<string, SettingsWorkType>
    ) => {
      if (!payment) {
        return undefined;
      }

      const contract = contracts[payment.contract];

      if (!contract) {
        return undefined;
      }

      const period = format(new Date(payment.completedAt), 'MMMM uuuu');

      const { title: contractName, currency } = contract;

      const formatMoney = createFormatter(createMoneyFormatter(currency));

      const workType = workTypes[payment.workType]?.name || 'N/A';

      const attachment = payment.attachment.map(
        mapDocumentAttachmentToFileInputAttachment(payment.uuid)
      );

      const department = selectDepartmentByUuid(
        departments,
        payment.departmentUuid
      );
      const departmentName = department?.name || '-';

      const view: CustomPaymentDetailsView = {
        period,
        workType,
        attachment,
        contract: contractName,
        description: payment.description,
        amount: formatMoney(payment.amount),
        departmentName
      };

      return view;
    }
  );

export const selectHasCustomPaymentContractsForForm = createSelector(
  selectFormContractFieldValues(CUSTOM_PAYMENT_CONTRACT_TYPE),
  contracts => contracts.length > 0
);

export const selectHasUniversalContractsForForm = createSelector(
  selectFormContractFieldValues(null),
  contracts => contracts.length > 0
);

export const selectHasCustomPaymentCompatibleContractsForForm = createSelector(
  selectHasCustomPaymentContractsForForm,
  selectHasUniversalContractsForForm,
  (hasCPContracts, hasUniversalContracts) =>
    hasCPContracts || hasUniversalContracts
);

export const selectCustomPaymentsWorkTypes = (contractId: string) =>
  createSelector(
    selectContractConditions(contractId),
    selectContractTypes,
    (conditions, contractTypes) => {
      const { work_types } = contractTypes[CUSTOM_PAYMENT_CONTRACT_TYPE];

      const valuesMap = Object.values(conditions || {}).reduce<
        Record<string, string>
      >((result, condition) => {
        const { workType } = condition;
        const name = work_types[workType]?.name;

        return name ? { ...result, [workType]: name } : result;
      }, {});

      return Object.entries(valuesMap).map(([value, name]) => ({
        name,
        value
      }));
    }
  );

export const selectFormStatus = (state: CustomPaymentsStoreSegment) => {
  return state.customPayments.form;
};

export const selectWorkTypesForFilter = createWorkTypesFilterSelector(
  selectContractsByType(CUSTOM_PAYMENT_CONTRACT_TYPE)
);

export const selectCustomPaymentsFilterPresets = createSelector(
  selectCustomPaymentsSegment,
  segment => segment.presets
);

export const selectCustomPaymentsFilterPresetInfo = createSelector(
  selectCustomPaymentsFilterPresets,
  presets =>
    Object.entries(presets)
      .map(([id, preset]) => ({
        id: Number(id),
        title: preset.title
      }))
      .sort((first, second) => second.id - first.id)
);

export const selectCustomPaymentFormData = (uuid?: string) =>
  createSelector(selectCustomPayment(uuid), customPayment => {
    if (!customPayment) {
      return undefined;
    }

    const attachment = customPayment.attachment.map(
      mapDocumentAttachmentToFileInputAttachment(customPayment.uuid)
    );

    const formData: FormData = {
      attachment,
      contract: customPayment.contract,
      workType: customPayment.workType,
      description: customPayment.description,
      completedAt: customPayment.completedAt,
      departmentUuid: customPayment.departmentUuid,
      totalAmountStorable: String(customPayment.amount / 100)
    };

    return formData;
  });

export const selectDefaultCurrencyTotalSum = createSelector(
  selectTotalSumData,
  selectDefaultCurrency,
  (totals, defaultCurrency) => {
    const value = totals[defaultCurrency] || 0;
    const formatMoney = createMoneyFormatter(defaultCurrency, true);

    return formatMoney(value);
  }
);

export const selectDependenciesStatus = createSelector(
  selectSettingsStatus,
  selectContractsStatus,
  selectDepartmentsAsyncEntityStatus,
  calculateStatus
);

export const selectDomainStatus = createSelector(
  selectDependenciesStatus,
  selectCustomPaymentsStatus,
  calculateStatus
);

export const selectUserFormDepartments = (
  date: number | null,
  initialDepartmentUuid: string | null
) =>
  createSelector(
    selectUserPositions,
    selectDepartmentsAsyncEntityData,
    (userPositions, departments) => {
      const departmentsNamesMap = userPositions
        .filter(userPosition => {
          if (date === null) {
            return false;
          }

          const dateUtc = toUTC(date);
          const periodFromUtc = toUTC(userPosition.periodFrom);
          const periodToUtc = userPosition.periodTo
            ? toUTC(userPosition.periodTo)
            : null;

          return createInRangeDefiner(dateUtc)(periodFromUtc, periodToUtc);
        })
        .reduce<Record<string, string[]>>((result, record) => {
          const department = selectDepartmentByUuid(
            departments,
            record.departmentUuid
          );

          const isArchived = department?.isArchived;
          const isChosen = department.uuid === initialDepartmentUuid;

          if (!isArchived || isChosen) {
            result[department.uuid] = result[department.uuid] || [];
            result[department.uuid].push(record.positionName);
          }
          return result;
        }, {});

      return Object.entries(departmentsNamesMap).map(([uuid, positions]) => {
        const department = selectDepartmentByUuid(departments, uuid);
        const positionsStr = positions.join(', ');
        const title = `${department.name} (${positionsStr})`;

        return { uuid: department.uuid, title };
      });
    }
  );
