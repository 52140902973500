import { push } from 'connected-react-router';
import { debounceTime, map, mergeMap } from 'rxjs/operators';

import { createLoadingEpic } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { parseServerError } from '@tsp/api/utils/parseServerError';
import { createPageInitEpic } from '@tsp/core/features/dependencies';

import {
  loadingAction as loadingContracts,
  domainAction as contractsDomainAction
} from '../contracts';
import { pushNotification } from '../notifications';
import { loadSettingsOnce } from '../settings/actions';
import { loadDepartmentsOnceAction } from '../departments';

import {
  domainAction,
  presetsActions,
  updateCustomPayment,
  createCustomPayment,
  fetchCustomPayments,
  deleteCustomPayment,
  reloadCustomPayments,
  loadCustomPaymentsAsync
} from './actions';
import {
  CustomPaymentsStoreSegment,
  CustomPaymentsEpicDependencies
} from './interface';
import {
  selectDependenciesStatus,
  selectCustomPaymentsFilterPresets,
  selectCustomPayment
} from './selectors';
import { toolsFormInitialValues } from './constants';
import { mapFormDataToRequest, translateFiltersToRequest } from './translators';

const updateFilterEpic: Epic = $actions =>
  $actions.pipe(
    ofAction(reloadCustomPayments),
    debounceTime(500),
    map(action => {
      const request = translateFiltersToRequest(action.payload);
      return loadCustomPaymentsAsync.started(request);
    })
  );

const loadingStartEpic = createLoadingEpic({
  action: loadCustomPaymentsAsync,
  selectFetcher: (deps: CustomPaymentsEpicDependencies) =>
    deps.api.getCustomPayments
});

const createPaymentEpic: Epic<unknown, CustomPaymentsEpicDependencies> = (
  actions$,
  _,
  deps
) =>
  actions$.pipe(
    ofAction(createCustomPayment.started),
    mergeMap(action => {
      const request = mapFormDataToRequest(action.payload.data);
      return deps.api.createCustomPayment(request).pipe(
        map(response =>
          createCustomPayment.done({
            result: response,
            params: action.payload
          })
        ),
        catchAsyncError(error =>
          createCustomPayment.failed({
            error: parseServerError(error),
            params: action.payload
          })
        )
      );
    })
  );

const updatePaymentEpic: Epic<
  CustomPaymentsStoreSegment,
  CustomPaymentsEpicDependencies
> = (actions$, state$, deps) =>
  actions$.pipe(
    ofAction(updateCustomPayment.started),
    mergeMap(action => {
      const customPayment = selectCustomPayment(action.payload.uuid)(
        state$.value
      );
      const request = mapFormDataToRequest(action.payload.data, customPayment);

      return deps.api
        .updateCustomPayment(request, ajaxReq => ({
          ...ajaxReq,
          url: `${ajaxReq.url}/${action.payload.uuid}`
        }))
        .pipe(
          map(result =>
            updateCustomPayment.done({ result, params: action.payload })
          ),
          catchAsyncError(error =>
            updateCustomPayment.failed({
              error: parseServerError(error),
              params: action.payload
            })
          )
        );
    })
  );

const deletePaymentEpic: Epic<unknown, CustomPaymentsEpicDependencies> = (
  actions$,
  _,
  deps
) =>
  actions$.pipe(
    ofAction(deleteCustomPayment.started),
    mergeMap(action => {
      return deps.api
        .deleteCustomPayment({}, ajaxReq => ({
          ...ajaxReq,
          url: `${ajaxReq.url}/${action.payload.uuid}`
        }))
        .pipe(
          mergeMap(() => {
            return [
              deleteCustomPayment.done({
                params: action.payload,
                result: {}
              })
            ];
          }),
          catchAsyncError(error =>
            deleteCustomPayment.failed({
              error: parseServerError(error),
              params: action.payload
            })
          )
        );
    })
  );

const hideFormEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(
      createCustomPayment.done,
      updateCustomPayment.done,
      deleteCustomPayment.done
    ),
    mergeMap(action => {
      if (deleteCustomPayment.done.match(action)) {
        return [push('/custom-payments')];
      }

      if (
        createCustomPayment.done.match(action) ||
        updateCustomPayment.done.match(action)
      ) {
        const { next } = action.payload.params.data;
        return next ? [] : [push('/custom-payments')];
      }

      return [];
    })
  );

const reloadEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(
      createCustomPayment.done,
      updateCustomPayment.done,
      deleteCustomPayment.done
    ),
    map(() => fetchCustomPayments())
  );

const updateFilterPresetsEpic: Epic<
  CustomPaymentsStoreSegment,
  CustomPaymentsEpicDependencies
> = (actions$, state$, deps) =>
  actions$.pipe(
    ofAction(presetsActions.delete, presetsActions.push),
    map(action => {
      const presets = selectCustomPaymentsFilterPresets(state$.value);
      deps.setRecovery({ customPayments: { presets } });

      const messageAction = presetsActions.push.match(action)
        ? 'saved'
        : 'deleted';

      const title = action.payload.preset.title;

      return pushNotification({
        type: 'success',
        message: `Filter preset "${title}" ${messageAction}`
      });
    })
  );

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(
      deleteCustomPayment.failed,
      createCustomPayment.failed,
      updateCustomPayment.failed,
      loadCustomPaymentsAsync.failed
    ),
    mergeMap(action => {
      return typeof action.payload.error === 'string'
        ? [
            pushNotification({
              type: 'error',
              message: action.payload.error
            })
          ]
        : [];
    })
  );

const successNotificationsEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(
      createCustomPayment.done,
      updateCustomPayment.done,
      deleteCustomPayment.done
    ),
    map(action => {
      if (createCustomPayment.done.match(action)) {
        return pushNotification({
          type: 'success',
          message: 'Custom payment added successfully'
        });
      }

      if (deleteCustomPayment.done.match(action)) {
        return pushNotification({
          type: 'success',
          message: 'Custom payment deleted successfully'
        });
      }

      return pushNotification({
        type: 'success',
        message: 'Custom payment updated successfully'
      });
    })
  );

const successResultEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(
      createCustomPayment.done,
      updateCustomPayment.done,
      deleteCustomPayment.done
    ),
    mergeMap(action => {
      action.payload.params.callback();
      return [];
    })
  );

const errorResultEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(createCustomPayment.failed, updateCustomPayment.failed),
    mergeMap(action => {
      const errors: Object =
        typeof action.payload.error === 'string' ? {} : action.payload.error;

      action.payload.params.callback(errors);

      return [];
    })
  );

const resetDependenciesEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.reset),
    mergeMap(() => [contractsDomainAction.reset()])
  );

const dependenciesEpic = createPageInitEpic({
  domainAction,
  selectStatus: selectDependenciesStatus,
  dependencies: [loadSettingsOnce, loadingContracts, loadDepartmentsOnceAction]
});

const loadMainDataEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.success),
    map(() => reloadCustomPayments(toolsFormInitialValues))
  );

export const customPaymentsEpic = combineEpics(
  reloadEpic,
  hideFormEpic,
  errorResultEpic,
  updateFilterEpic,
  loadingStartEpic,
  dependenciesEpic,
  loadMainDataEpic,
  successResultEpic,
  deletePaymentEpic,
  createPaymentEpic,
  updatePaymentEpic,
  resetDependenciesEpic,
  errorNotificationEpic,
  updateFilterPresetsEpic,
  successNotificationsEpic
);
