import { FormApi } from 'final-form';
import { ActionCreatorFactory } from 'typescript-fsa';

import { FilterPreset, FilterPresetMeta } from './interface';

export const createFilterPresetsActions = <D extends {}>(
  createAction: ActionCreatorFactory
) => ({
  select: createAction<{ id: number; form: FormApi }>('SELECT_PRESET'),
  push: createAction<{ id: number; preset: FilterPreset<D> }>('PUSH_PRESET'),
  delete: createAction<{ id: number; preset: FilterPresetMeta }>(
    'DELETE_PRESET'
  )
});
