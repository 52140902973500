import { INITIAL_RANGE } from '../filters';
import { CellMeta } from '../components/Table';
import { calculateFiltersCount } from '../filters/utils/calculateFiltersCount';

import { ToolsFormAdditionalKeys, ToolsFormValues } from './interface';

export const tableConfig: Record<string, CellMeta> = {
  status: { width: '7%', sort: false },
  created: { width: '8%', sort: false },
  completed: { width: '8%' },
  description: { width: '29%' },
  unitPrice: { width: '13%', align: 'right', sort: false },
  fixedPrice: { width: '8%', align: 'right', sort: false },
  qty: { width: '6%', align: 'right', sort: false },
  amount: { width: '11%', align: 'right', sort: false },
  control: { width: '10%', align: 'right' }
};

export const toolsFormInitialValues: ToolsFormValues = {
  pagination: {
    page: 0,
    limit: 50
  },
  search: '',
  period: INITIAL_RANGE
};

export const ADDITIONAL_FIELDS: ToolsFormAdditionalKeys[] = [
  'status',
  'workType',
  'brandUuid',
  'localeCode',
  'propertyUuid'
];

export const calculateCount = calculateFiltersCount(ADDITIONAL_FIELDS);
