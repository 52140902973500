import addMinutes from 'date-fns/addMinutes';

export const millisecondsToUnixTime = (milliseconds: number) =>
  Math.round(milliseconds / 1000);

export const unixTimeToMilliseconds = (unixTime: number) => unixTime * 1000;

export const toUTC = <R extends Date | number>(date: R): R => {
  const utcOffset = new Date().getTimezoneOffset();
  const dateUtc = addMinutes(date, utcOffset);

  return (typeof date === 'number' ? dateUtc.getTime() : dateUtc) as R;
};
