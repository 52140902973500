import { TotalSumStorable, TotalSumStorableApi } from './interface';

export const translateTotalSum = (
  total: TotalSumStorableApi
): TotalSumStorable => {
  return Object.entries(total).reduce<TotalSumStorable>(
    (result, [currency, value]) => {
      return { ...result, [currency]: Number(value) };
    },
    {}
  );
};
