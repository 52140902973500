import { MenuItemExtended } from './interface';

export const staticMenu: MenuItemExtended[] = [
  {
    to: '/',
    exact: true,
    label: 'Overview'
    // testId: 'navbar-overview'
  },
  {
    to: '/work-items',
    label: 'Work Items'
    // testId: 'navbar-work-items'
  },
  {
    to: '/custom-works',
    label: 'Custom Works'
    // testId: 'navbar-custom-work'
  },
  {
    to: '/custom-payments',
    label: 'Custom Payments'
    // testId: 'navbar-custom-payment'
  },
  {
    to: '/invoices',
    label: 'Invoices'
    // testId: 'navbar-invoices'
  },
  // { path: '/contracts', name: 'Contracts', testId: 'navbar-contracts' },
  {
    to: '/accounts',
    label: 'Payout Methods'
    // testId: 'navbar-payout-methods'
  }
];
