import { actionCreatorFactory } from 'typescript-fsa';

import { FormData, LinkRestaffError, LinkRestaffResponse } from './interface';

type Callback = (errors?: Object) => void;

const createAction = actionCreatorFactory('RESTAFF');

export const resetRestaff = createAction('RESET');

export const linkRestaff = createAction.async<
  { data: FormData; callback: Callback },
  LinkRestaffResponse,
  LinkRestaffError
>('LINK');
