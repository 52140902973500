import { FormData, LinkCitiBankSEPARequest } from './interface';

export const translateLinkFormValuesToRequest = (
  values: FormData
): LinkCitiBankSEPARequest => {
  const request = { ...values };
  request.swiftCode = values.swiftCode.toUpperCase();

  return request;
};
