import { createSelector } from 'reselect';

import { calculateStatus } from '@tsp/utils/asyncEntity/utils/calculateStatus';

import {
  AccountProvider,
  selectSystemTypes,
  selectSettingsStatus
} from '../settings';
import {
  selectUserAccount,
  selectPrimaryMethodsList,
  selectUserAccountsStatus,
  selectUserAccountsSegment
} from '../userAccounts';
import {
  selectContractorAccounts,
  selectContractorAccountsStatus
} from '../contractorAccounts';
import { Currency, currencyToString } from '../money';
import { selectContractsStatus } from '../contracts';

import { getCardStatus } from './utils/getCardStatus';
import { spliceCardNumber } from './utils/spliceCardNumber';
import { AccountData, CardStatus, ExternalAccountView } from './interface';

export const selectContractorExternalAccountsSystemTypes = (
  contractor: string
) =>
  createSelector(
    selectContractorAccounts(contractor),
    selectSystemTypes,
    (contractorAccounts, systemTypes) =>
      contractorAccounts.reduce<AccountProvider[]>(
        (result, contractorAccount) => {
          if (contractorAccount.systemType === AccountProvider.Internal) {
            return result;
          }

          const match = systemTypes.find(
            systemType => systemType.id === contractorAccount.systemType
          );

          if (!match) {
            return result;
          }

          const transferIds = Object.keys(
            match.allowed_transfer_types || {}
          ).map(key => Number(key) as unknown as AccountProvider);

          return result.concat(transferIds);
        },
        []
      )
  );

export const selectExternalAccountsList = (contractor: string) =>
  createSelector(
    selectUserAccountsSegment,
    selectPrimaryMethodsList(contractor),
    selectContractorExternalAccountsSystemTypes(contractor),
    selectSystemTypes,
    (segment, primaryMethods, contractorSystemTypes, systemTypes) => {
      return (segment.data?.userAccounts || [])
        .filter(
          account => contractorSystemTypes.indexOf(account.systemType) > -1
        )
        .map((account): ExternalAccountView => {
          const { uuid } = account;
          const status = getCardStatus(account, systemTypes);

          const isPrimary = !!primaryMethods.find(
            methodId => methodId === uuid
          );
          const showMenu = !isPrimary && status === CardStatus.Verified;

          return { showMenu, uuid, status };
        })
        .sort((first, second) =>
          first.status === CardStatus.Manual
            ? 1
            : second.status === CardStatus.Manual
            ? -1
            : 0
        );
    }
  );

export const selectIntegratedAccounts = (contractor: string) =>
  createSelector(
    selectSystemTypes,
    selectContractorAccounts(contractor),
    (systemTypes, contractorAccounts) => {
      return (systemTypes || []).reduce<Record<string, AccountData[]>>(
        (result, systemType) => {
          if (!systemType.is_integrated) {
            return result;
          }

          const accountsList = contractorAccounts.filter(
            contractorAccount => contractorAccount.systemType === systemType.id
          );

          return accountsList.reduce((listResult, contractorAccount) => {
            const { currencyId } = contractorAccount;
            const item = {
              systemType,
              contractorAccount
            };

            result[currencyId] = result[currencyId]
              ? [...result[currencyId], item]
              : [item];

            return listResult;
          }, result);
        },
        {}
      );
    }
  );

export const selectAccountsList = (contractor: string) =>
  createSelector(selectIntegratedAccounts(contractor), accounts =>
    Object.values(accounts).reduce<AccountData[]>((result, data) => {
      return [...result, ...data];
    }, [])
  );

export const selectContractorAccount = (
  contractor: string,
  provider: AccountProvider,
  currencyId: Currency
) =>
  createSelector(selectIntegratedAccounts(contractor), accounts => {
    const accountsForCurrency = accounts[currencyId] || [];

    const data = accountsForCurrency.find(
      account => account.systemType.id === provider
    );

    return data ? data.contractorAccount.uuid : undefined;
  });

export const selectAccountView = (accountId: string) =>
  createSelector(
    selectUserAccount(accountId),
    selectSystemTypes,
    (account, systemTypes) => {
      if (!account) {
        return undefined;
      }
      const status = getCardStatus(account, systemTypes);

      const numberLengthMap: Record<CardStatus, number> = {
        [CardStatus.Manual]: 9,
        [CardStatus.Verified]: 16,
        [CardStatus.NotVerified]: 16,
        [CardStatus.NotSupported]: 12
      };

      const numberLength = numberLengthMap[status];

      const isCard =
        account.systemType === AccountProvider.Ecommpay ||
        account.systemType === AccountProvider.EcommpayCard ||
        account.systemType === AccountProvider.Bank131 ||
        account.systemType === AccountProvider.Bank131Card ||
        account.systemType === AccountProvider.Monetix ||
        account.systemType === AccountProvider.MonetixCard;

      let numberSlot = account.externalId || '';
      if (isCard) {
        numberSlot = account.details?.number || '';
      }

      const cardNumber = isCard
        ? numberSlot
        : spliceCardNumber(numberSlot, numberLength).toUpperCase();

      return {
        status,
        cardNumber,
        expireDate: '',
        type: account.systemType,
        currency: currencyToString(account.currency.id)
      };
    }
  );

export const selectDependenciesStatus = createSelector(
  selectSettingsStatus,
  selectContractsStatus,
  selectUserAccountsStatus,
  selectContractorAccountsStatus,
  calculateStatus
);
