import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import {
  GetAsanaResponse,
  GetAsanaLinkRequest,
  GetAttachmentLinkRequest,
  GetAttachmentLinkResponse
} from './interface';

export const getCommunicationLink = defineHandler<
  GetAsanaLinkRequest,
  GetAsanaResponse
>({
  path: '/getCommunicationLink',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const getAttachmentLink = defineHandler<
  GetAttachmentLinkRequest,
  GetAttachmentLinkResponse
>({
  path: '/getAttachmentLink',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const externalApi = {
  getAttachmentLink,
  getCommunicationLink
};
