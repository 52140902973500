import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActionCreator, AnyAction } from 'typescript-fsa';

export const ofActionsList =
  (actionCreators: ActionCreator<unknown>[]) =>
  (actions$: Observable<AnyAction>) =>
    actions$.pipe(
      filter(action =>
        actionCreators.some(actionCreator => actionCreator.match(action))
      )
    );
