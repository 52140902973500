import { isUndefined } from '../common/isUndefined';

export const createInRangeDefiner = (current: Date | number) => {
  const currentTs = typeof current === 'number' ? current : current.getTime();

  return (from: number, to: number | null) => {
    const preparedTo = to || Infinity;
    return currentTs >= from && currentTs <= preparedTo;
  };
};

export const createInWindowsIntersectionDefiner = (
  firstStart: number,
  firstEnd: number
) => {
  return (secondStart: number, secondEnd?: number | undefined | null) => {
    secondEnd = isUndefined(secondEnd) ? Infinity : secondEnd;
    return !(secondEnd < firstStart || secondStart > firstEnd);
  };
};
