import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';

import {
  UserStateData,
  GetUserStatusRequest,
  GetUserStatusResponse,
  GetUserPositionsStatusRequest,
  GetUserPositionsStatusResponse
} from './interface';

const createAction = actionCreatorFactory('USER');

export const fetchUser = createAction.async<
  GetUserStatusRequest,
  UserStateData
>('fetch_user_full');

export const fetchUserPositions = createAsyncAction<
  GetUserPositionsStatusRequest,
  GetUserPositionsStatusResponse
>(createAction, 'FETCH_POSITIONS');

export const loadingAction = createAsyncAction<
  GetUserStatusRequest,
  GetUserStatusResponse
>(createAction);

export const logoutAction = createAction('logout');
