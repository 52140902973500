export const CW_REQUEST_INFO_LINK =
  'https://thesoul.atlassian.net/wiki/spaces/UD/pages/7403679526/How+to+create+a+payment+request+Custom+Works';

export const CP_REQUEST_INFO_LINK =
  'https://thesoul.atlassian.net/wiki/spaces/UD/pages/7413530987/How+to+create+a+payment+request+Custom+Payments';

export const DOCUMENTATION_LINK =
  'https://thesoul.atlassian.net/wiki/spaces/UD/pages/11186116679/DocFlow+payments';

export const LINK_CARD_DOCUMENTATION =
  'https://thesoul.atlassian.net/wiki/spaces/UD/pages/7417931893/How+to+link+your+bank+card+to+DocFlow';

export const LINK_WALLET_DOCUMENTATION =
  'https://thesoul.atlassian.net/wiki/spaces/UD/pages/7403679855/How+to+link+your+wallet+to+DocFlow';

export const LINK_PAYMENT_SYSTEM_COMPARISON =
  'https://thesoul.atlassian.net/wiki/spaces/UD/pages/7417924281/Payment+systems+comparison+matrix';

export const CONTACT_LINE_MANAGER_LINK =
  'https://hq.thesoul.io/servicedesk/1780/500';
