import { excludeUndefined } from '@tsp/utils/common/excludeUndefined';

export type Handler<K, V> = (key: K, value: V) => {};

export const reduceAdditionalFilters = <
  Filters extends {},
  Key extends keyof Filters,
  Value extends Filters[Key]
>(
  filters: Filters,
  handler: Handler<Key, Value>
) => {
  return Object.entries(excludeUndefined(filters)).reduce(
    (result, [key, value]) => {
      return { ...result, ...handler(key as Key, value as Value) };
    },
    {}
  );
};
