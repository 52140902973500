import { Currency } from './interface';

export const currencyToString = (currency: Currency) => {
  switch (currency) {
    case Currency.USD:
      return 'USD';
    case Currency.RUB:
      return 'RUB';
    case Currency.GBP:
      return 'GBP';
    case Currency.HUF:
      return 'HUF';
    case Currency.AMD:
      return 'AMD';
    default:
      return 'EUR';
  }
};

export const currencyToSymbol = (currency: Currency) => {
  switch (currency) {
    case Currency.USD:
      return '$';
    case Currency.RUB:
      return '₽';
    case Currency.GBP:
      return '£';
    case Currency.HUF:
      return 'Ft';
    case Currency.AMD:
      return '֏';
    default:
      return '€';
  }
};

export const stringToCurrency = (value: string): Currency => {
  switch (value) {
    case 'USD':
      return Currency.USD;
    case 'RUB':
      return Currency.RUB;
    case 'GBP':
      return Currency.GBP;
    case 'HUF':
      return Currency.HUF;
    case 'AMD':
      return Currency.AMD;
    default:
      return Currency.EUR;
  }
};

export const splitNumber = (value: number) => {
  const dataStr = String(value).split('.');
  const fractionalStr = dataStr[1] === undefined ? '0' : `0.${dataStr[1]}`;

  return [Number(dataStr[0]), Number(fractionalStr)] as const;
};

export const formatWhole = (value: number) => {
  let buff = '';
  const digArray = String(Math.abs(value)).split('');

  const result = digArray
    .reverse()
    .reduce((result, value, index) => {
      const isLast = index === digArray.length - 1;
      const separate = (index + 1) % 3 === 0;

      buff = value + buff;

      if (separate || isLast) {
        result.unshift(buff);
        buff = '';
      }

      return result;
    }, [])
    .join(' ');

  return value < 0 ? `-${result}` : result;
};

const formatFractional = (value: number, precision: number) => {
  const fractionalStr = String(value).split('.')[1] || '0';
  return `${fractionalStr}0`.slice(0, precision);
};

export const createMoneyFormatter =
  (currency: Currency, space = false) =>
  (value: number) => {
    const precision = 2;
    const symbolSpace = space ? ' ' : '';
    const symbol = currencyToSymbol(currency);
    const [whole, fractional] = splitNumber(value / 100);

    return (
      symbol +
      symbolSpace +
      [formatWhole(whole), formatFractional(fractional, precision)].join(',')
    );
  };
