import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

import {
  millisecondsToUnixTime,
  unixTimeToMilliseconds
} from '@tsp/utils/time/convert';
import { createWithDefaultSorting } from '@tsp/utils/translators/createWithSorting';

import { stringToCurrency } from '../money';
import { translateTotalSum } from '../money/translators';
import { reduceAdditionalFilters } from '../filters/utils/reduceAdditionalFilters';

import {
  Invoice,
  ApiInvoice,
  ToolsFormValues,
  ApiInvoiceResponse,
  GetInvoicesRequest,
  GetInvoicesResponse
} from './interface';

export const translateFilterToRequest = createWithDefaultSorting(
  (values: ToolsFormValues) => {
    const { pagination, range, ...additional } = values;
    const { limit, page } = pagination;

    const periodTo = endOfDay(range[1]).getTime();
    const periodFrom = startOfDay(range[0]).getTime();

    const additionalValues = reduceAdditionalFilters(
      additional,
      (key, value) => {
        return { [key]: value };
      }
    );

    return {
      limit,
      offset: page * limit,
      periodTo: millisecondsToUnixTime(periodTo),
      periodFrom: millisecondsToUnixTime(periodFrom),
      ...additionalValues
    };
  }
);

const mapApiInvoiceToInvoice = (invoice: ApiInvoice): Invoice => ({
  uuid: invoice.uuid,
  description: invoice.description,
  document: invoice.documentNumber,
  approvedAt: invoice.approvalDate,
  amount: Number(invoice.amount) * 100,
  currency: stringToCurrency(invoice.currency),
  period: unixTimeToMilliseconds(invoice.periodTs)
});

export const translateResponseToInvoices = (
  response: ApiInvoiceResponse,
  request: GetInvoicesRequest
): GetInvoicesResponse => {
  return {
    offset: request.offset,
    total: response.totalCount,
    items: response.invoices.map(mapApiInvoiceToInvoice),
    totalSum: translateTotalSum(response.totalSumStorable)
  };
};
