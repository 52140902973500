import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionCreator } from 'typescript-fsa';
import { useDispatch, useSelector } from 'react-redux';

import { selectQueryParams } from '@tsp/core/features/connect-router';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface PageParams {
  entity: string;
}

type EntitiesRegister = Record<
  string,
  {
    action: ActionCreator<any>;
    mapQueryToProps?: (props: any) => any;
  }
>;

const defaultQueryToProps = (query: any) => query;

export const createExternalDispatchPage =
  (register: EntitiesRegister): FC =>
  () => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('Please wait...');

    const params = useParams<PageParams>();
    const query = useSelector(selectQueryParams);

    useEffect(() => {
      const config = register[params.entity];

      if (!config) {
        setMessage(`Unregistered entity: ${params.entity}`);
        return;
      }

      const mapQueryToProps = config.mapQueryToProps || defaultQueryToProps;

      dispatch(config.action(mapQueryToProps(query)));
    }, [dispatch, params.entity, query]);

    return <div>{message}</div>;
  };
