import { FormData, LinkUnlimintRequest } from './interface';

export const translateLinkFormValuesToRequest = (
  values: FormData
): LinkUnlimintRequest => {
  const request = { ...values };
  request.swiftCode = values.swiftCode.toUpperCase();

  return request;
};
