import { createSelector } from 'reselect';

import { AppNotificationStoreSegment } from './interface';

export const selectNotifications = (state: AppNotificationStoreSegment) =>
  state.notifications;

export const selectSortedNotifications = createSelector(
  selectNotifications,
  notifications =>
    notifications.sort((first, second) => second.timestamp - first.timestamp)
);
