import { InputType, InputValue } from '../../components/FileInput/interface';

import { DocumentAttachment } from '../interface';

export const mapDocumentAttachmentToFileInputAttachment =
  (entityUuid: string) =>
  (attachment: DocumentAttachment): InputValue =>
    attachment.type === 'cdn'
      ? {
          type: InputType.FileDescriptor,
          fileName: attachment.fileName,
          entityUuid
        }
      : {
          type: InputType.Link,
          link: attachment.link
        };
