import { AnyAction } from 'redux';
import {
  EpicMiddleware,
  createEpicMiddleware as _createEpicMiddleware
} from 'redux-observable';
import { catchError } from 'rxjs/operators';

import { Epic } from './interface';
import { combineEpics } from './combineEpics';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type ExtendsEpicMiddleware<S, D> = {
  middleware: EpicMiddleware<AnyAction, AnyAction, S, D>;
  run: () => void;
};

export interface EpicConfig<S, D> {
  rootEpic: Epic<S, D>;
  dependencies: D;
}

export function createEpicMiddleware<S, D>({
  rootEpic,
  dependencies
}: EpicConfig<S, D>): ExtendsEpicMiddleware<S, D> {
  const middleware = _createEpicMiddleware<AnyAction, AnyAction, S, D>({
    dependencies
  });

  const safeEpic: Epic = (actions$, store$, deps) =>
    combineEpics(rootEpic)(actions$, store$, deps).pipe(
      catchError((error, source) => {
        console.error('Uncaught stream error:', error);
        return source;
      })
    );

  return {
    middleware,
    run: () => middleware.run(safeEpic)
  };
}
