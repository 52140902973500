import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import {
  GetContractorAccountsRequest,
  GetContractorAccountsResponse,
  ApiGetContractorAccountsResponse
} from './interface';

const translateResponseToContractorAccounts = (
  response: ApiGetContractorAccountsResponse
): GetContractorAccountsResponse => response._embedded.account;

const getContractorAccounts = defineHandler<
  GetContractorAccountsRequest,
  GetContractorAccountsResponse
>({
  path: '/get-contractors-accounts',
  method: HttpMethod.GET,
  params: ParamsContainer.Query,
  translator: translateResponseToContractorAccounts
});

export const contractorAccountsApi = {
  getContractorAccounts
};
