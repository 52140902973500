import format from 'date-fns/format';
import { createSelector } from 'reselect';

import { calculateStatus } from '@tsp/utils/asyncEntity/utils/calculateStatus';

import { selectDefaultCurrency } from '../env';
import { createMoneyFormatter } from '../money';
import { selectSettingsStatus } from '../settings';
import { selectUserAccountsStatus } from '../userAccounts';
import { selectContractorAccountsStatus } from '../contractorAccounts';

import { InvoicesSegment } from './interface';

export const selectInvoicesSegment = (state: InvoicesSegment) => state.invoices;

export const selectInvoices = (state: InvoicesSegment) => {
  const segment = selectInvoicesSegment(state);
  return segment.data;
};

export const selectInvoicesStatus = (state: InvoicesSegment) => {
  const segment = selectInvoicesSegment(state);
  return segment.status;
};

export const selectTotalSumData = (state: InvoicesSegment) => {
  const segment = selectInvoicesSegment(state);
  return segment.totalSum;
};

export const selectInvoicesTableData = createSelector(
  selectInvoices,
  invoices =>
    (invoices || []).map(invoice => {
      const period = format(invoice.period, 'MMMM, uuuu');

      return {
        period,
        uuid: invoice.uuid,
        document: invoice.document,
        approvedAt: invoice.approvedAt,
        description: invoice.description || '',
        amount: createMoneyFormatter(invoice.currency)(invoice.amount)
      };
    })
);

export const selectDefaultCurrencyTotalSum = createSelector(
  selectTotalSumData,
  selectDefaultCurrency,
  (totals, defaultCurrency) => {
    const value = totals[defaultCurrency] || 0;
    const formatMoney = createMoneyFormatter(defaultCurrency, true);

    return formatMoney(value);
  }
);

export const selectDependenciesStatus = createSelector(
  selectSettingsStatus,
  selectUserAccountsStatus,
  selectContractorAccountsStatus,
  calculateStatus
);

export const selectDomainStatus = createSelector(
  selectDependenciesStatus,
  selectInvoicesStatus,
  calculateStatus
);
