import React, { FC, useCallback, useMemo, useState } from 'react';
import { Theme } from '@mui/system';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@tsp/tsp-frontend-components/mui';
import { Header } from '@tsp/tsp-frontend-components/tsp';

import { sxMerge } from '@tsp/utils/styles/sxMerge';

import { logoutAction } from '../../../user';
import { selectProjectName } from '../../../config';
import { Notifications } from '../../../notifications/components';

import { PageLoader } from '../PageLoader';
import { selectUserData } from '../../selectors';
import { useMainMenu } from '../../hooks/useMainMenu';

interface Props {
  loading?: boolean;
}

const classes = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  } as const,
  content: (theme: Theme) =>
    ({
      opacity: 0,
      display: 'flex',
      transition: '0.2s',
      alignItems: 'center',
      flexDirection: 'column',
      padding: `${theme.spacing(4)} 0`
    } as const),
  fade: {
    opacity: 1
  },
  loaderPosition: {
    width: '100%',
    position: 'relative'
  } as const,
  loader: {
    top: 0,
    left: 0,
    width: '100%',
    position: 'absolute'
  } as const
};

export const Layout: FC<Props> = ({ children, loading }) => {
  const dispatch = useDispatch();
  const [viewChildren, setViewChildren] = useState(false);

  const menuProps = useMainMenu();

  const userData = useSelector(selectUserData);
  const projectName = useSelector(selectProjectName);

  const contentStyles = sxMerge(
    classes.content,
    viewChildren ? classes.fade : undefined
  );

  const showContent = useCallback(() => setViewChildren(true), []);
  const logOutHandler = useCallback(() => dispatch(logoutAction()), [dispatch]);

  const userProps = useMemo(() => {
    return userData
      ? {
          ...userData,
          onLogoutClick: logOutHandler
        }
      : undefined;
  }, [logOutHandler, userData]);

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <Box sx={classes.wrapper}>
        <Header
          menuProps={menuProps}
          userProps={userProps}
          projectName={projectName}
        />
        <Box sx={classes.loaderPosition}>
          <Box sx={classes.loader}>
            <PageLoader show={loading} onHide={showContent} />
          </Box>
        </Box>
        {loading ? null : <Box sx={contentStyles}>{children}</Box>}
        <Notifications />
      </Box>
    </LocalizationProvider>
  );
};
