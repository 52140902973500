import { unixTimeToMilliseconds } from '@tsp/utils/time/convert';
import { HttpMethod, ParamsContainer, defineHandler } from '@tsp/api';

import { translateTotalSum } from '../money/translators';

import {
  LineItem,
  LineItemListRequest,
  LineItemListResponse,
  UserLineItemView,
  UserLineItemViewListResponse
} from './interface';
import { selectHistoryFromView } from './utils/selectHistory';

const mapUserLineItemViewToLineItem = (
  lineItem: UserLineItemView
): LineItem => ({
  uuid: lineItem.uuid,
  embeds: lineItem.embeds,
  unitName: lineItem.unitName,
  quantity: lineItem.quantity,
  history: selectHistoryFromView(lineItem),
  unitPrice: lineItem.unitPriceStorable,
  fixedPrice: lineItem.fixedPriceStorable,
  totalAmount: lineItem.totalAmountStorable,
  quantityDivider: lineItem.quantityDivider,
  locale: lineItem._embedded.workItem.locale,
  workType: lineItem._embedded.workItem.workType,
  contractUuid: lineItem.contractUuid,
  brandUuid: lineItem._embedded.workItem.brandUuid,
  supervisor: lineItem.contractorSignedByPartyName,
  entityUuid: lineItem._embedded.workItem.entityUuid,
  entityType: lineItem._embedded.workItem.entityType,
  description: lineItem._embedded.workItem.description,
  propertyUuid: lineItem._embedded.workItem.propertyUuid,
  teamwoxId: lineItem._embedded.workItem.content.teamwox_id,
  createdAt: unixTimeToMilliseconds(lineItem.history.createdAt),
  period: unixTimeToMilliseconds(lineItem._embedded.workItem.date),
  communicationLink: lineItem._embedded.communicationTask?.permalink,
  completedAt: lineItem._embedded.workItem?.completedAt
    ? unixTimeToMilliseconds(lineItem._embedded.workItem.completedAt)
    : undefined
});

const translateResponseToWorkItems = (
  response: UserLineItemViewListResponse,
  request: LineItemListRequest
): LineItemListResponse => {
  return {
    total: response.total_items,
    offset: (request.page - 1) * response.page_size,
    totalSum: translateTotalSum(response.total_sum_storable),
    items: response._embedded.certificate_line_item.map(
      mapUserLineItemViewToLineItem
    )
  };
};

export const getLineItems = defineHandler<
  LineItemListRequest,
  LineItemListResponse
>({
  path: '/getUserCertificateLineItems',
  method: HttpMethod.GET,
  params: ParamsContainer.Query,
  translator: translateResponseToWorkItems
});

export const workItemApi = {
  getLineItems
};
