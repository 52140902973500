import { useCallback, useMemo } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';

import { staticMenu } from '../constants';
import { MainMenuProps, MenuItem, MenuItemLight } from '../interface';

export const useMainMenu = (): MainMenuProps => {
  const history = useHistory();
  const location = useLocation();

  const menu = useMemo(
    () =>
      staticMenu.map(item => {
        const match = matchPath(location.pathname, {
          exact: item.exact,
          path: item.to
        });

        return { ...item, active: !!match };
      }),
    [location.pathname]
  );

  const onSelect = useCallback(
    (item: MenuItem | MenuItemLight) => {
      history.push(item.to);
    },
    [history]
  );

  return useMemo(() => ({ onSelect, menu }), [menu, onSelect]);
};
