import { createSelector } from 'reselect';

import { EntityStatus } from '../../../utils/asyncEntity';

import { DetailsStoreSegment } from './interface';

export const selectDetailsSegment = (state: DetailsStoreSegment) =>
  state.details;

export const selectDetailsData = (state: DetailsStoreSegment) => {
  const segment = selectDetailsSegment(state);
  return segment.data;
};

export const selectDetailsStatus = (state: DetailsStoreSegment) => {
  const segment = selectDetailsSegment(state);
  return segment.status;
};

export const selectDetailsPeriodEarnings = (state: DetailsStoreSegment) => {
  return selectDetailsData(state)?.periodEarnings || [];
};

export const selectIsDetailsLoading = createSelector(
  selectDetailsStatus,
  status => status === EntityStatus.initial || status === EntityStatus.fetching
);
