import { Condition, Conditions } from '../contracts';
import { SettingsWorkTypes, SettingsBrandProperty } from '../settings';

interface ConditionContainer {
  uuid: string;
  condition: Condition;
}

export const getUniqConditions = (conditions: Conditions) => {
  const conditionsMap = Object.entries(conditions).reduce<
    Record<string, ConditionContainer>
  >((result, [uuid, condition]) => {
    const key = [condition.workType, condition.property || ''].join('_');
    result[key] = { uuid, condition };
    return result;
  }, {});

  return Object.values(conditionsMap).reduce<Conditions>(
    (result, { uuid, condition }) => {
      result[uuid] = condition;

      return result;
    },
    {}
  );
};

interface ConditionDataSelector {
  workType: number;
  workSubtype: string;
}

export const getCWOptionName = (
  workTypes: SettingsWorkTypes,
  data: ConditionDataSelector
) => {
  const workType = workTypes[data.workType];

  const workTypeName = `${workType.name}`;
  const workSubTypeName = workType.work_sub_types[data.workSubtype].name;

  return [workTypeName, workSubTypeName].join(' - ');
};

export const createCWOptionNameGetter =
  (workTypes: SettingsWorkTypes) => (data: ConditionDataSelector) =>
    getCWOptionName(workTypes, data);
