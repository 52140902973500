import { AnyAction, AsyncActionCreators } from 'typescript-fsa';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum EntityStatus {
  error = 'error',
  initial = 'initial',
  fetched = 'fetched',
  fetching = 'fetching'
}

export type AsyncStatus<E extends {} = {}, Err extends any = any> = E & {
  status: EntityStatus;
  error?: Err;
};

export type AsyncEntity<
  D,
  E extends {} = {},
  Err extends any = any
> = AsyncStatus<
  {
    data?: D;
  } & E,
  Err
>;

export type AsyncList<D, E extends {} = {}> = AsyncStatus<
  {
    data: D[];
    total: number;
    offset: number;
  } & E
>;

export type AsyncEntityAction<P, D> = AsyncActionCreators<P, D, string>;

export type StatusAction<
  Req extends any = any,
  Res extends any = any,
  Err extends any = any
> = AsyncActionCreators<Req, Res, Err>;

export type AsyncReducer<S extends AsyncStatus> = (
  state: S,
  action: AnyAction
) => S;
