import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';
import { createDomainAction } from '@tsp/core/features/dependencies';

import { createFilterPresetsActions } from '../filters';

import {
  FormData,
  ToolsFormValues,
  GetCustomPaymentsRequest,
  GetCustomPaymentsResponse,
  ToolsFormAdditionalValues,
  CreateCustomPaymentResponse,
  UpdateCustomPaymentResponse
} from './interface';

type Callback = (errors?: Object) => void;

const createAction = actionCreatorFactory('CUSTOM_PAYMENTS');

export const loadCustomPaymentsAsync = createAsyncAction<
  GetCustomPaymentsRequest,
  GetCustomPaymentsResponse
>(createAction);

export const createCustomPayment = createAction.async<
  { data: FormData; callback: Callback },
  CreateCustomPaymentResponse,
  string
>('CREATE');

export const updateCustomPayment = createAction.async<
  { data: FormData; uuid: string; callback: Callback },
  UpdateCustomPaymentResponse,
  string
>('UPDATE');

export const deleteCustomPayment = createAction.async<
  { uuid: string; callback: VoidFunction },
  unknown,
  string
>('DELETE');

export const fetchCustomPayments = createAction('FETCH');
export const reloadCustomPayments = createAction<ToolsFormValues>('RELOAD');

export const presetsActions =
  createFilterPresetsActions<ToolsFormAdditionalValues>(createAction);

export const domainAction = createDomainAction(createAction);
