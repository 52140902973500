import { FormData, LinkCitiBankPayPalRequest } from './interface';

export const translateLinkFormValuesToRequest = ({
  email,
  phone,
  idType,
  ...values
}: FormData): LinkCitiBankPayPalRequest => {
  if (idType === 'email') {
    return { ...values, email };
  }

  const phoneString = `+${phone.code} ${phone.phone}`;

  return { ...values, phone: phoneString };
};
