import { createSelector } from 'reselect';

import { createFormatter } from '@tsp/utils/format/createFormatter';
import { calculateStatus } from '@tsp/utils/asyncEntity/utils/calculateStatus';

import {
  selectContract,
  selectContracts,
  selectContractsList,
  selectContractsStatus,
  createWorkTypesFilterSelector,
  selectFormattedContractBalance
} from '../contracts';
import { selectDefaultCurrency } from '../env';
import { createMoneyFormatter } from '../money';
import {
  selectWorkTypes,
  selectSettingsStatus,
  selectProjectsPortfolio
} from '../settings';
import { hasOnlyCreatedDate } from '../history/utils/hasOnlyCreatedDate';
import { historyToHistoryData } from '../history/utils/historyToHistoryData';

import { LineItemsStoreSegment, LineItemTableView } from './interface';
import { formatQuantity } from './utils/formatQuantity';

export const selectLineItemsSegment = (state: LineItemsStoreSegment) =>
  state.lineItems;

export const selectLineItemsData = (state: LineItemsStoreSegment) => {
  const segment = selectLineItemsSegment(state);
  return segment.data;
};

export const selectLineItemsStatus = (state: LineItemsStoreSegment) => {
  const segment = selectLineItemsSegment(state);
  return segment.status;
};

export const selectTotalSumData = (state: LineItemsStoreSegment) => {
  const segment = selectLineItemsSegment(state);
  return segment.totalSum;
};

export const selectLineItemsTableData = createSelector(
  selectLineItemsData,
  selectProjectsPortfolio,
  selectWorkTypes,
  selectContracts,
  selectProjectsPortfolio,
  (lineItems, portfolios, workTypes, contracts, projectPortfolio) => {
    return lineItems.map<LineItemTableView>(lineItem => {
      const locale = projectPortfolio[lineItem.locale]?.name || 'N/A';
      const brand =
        portfolios[lineItem.locale]?.brands[lineItem.brandUuid]?.name || 'N/A';
      const property =
        portfolios[lineItem.locale]?.brands[lineItem.brandUuid]?.properties[
          lineItem.propertyUuid
        ]?.label || 'N/A';

      const currency = contracts[lineItem.contractUuid].currency;

      const formatMoney = createFormatter(createMoneyFormatter(currency));
      const workType = workTypes[lineItem.workType];

      return {
        brand: brand,
        locale: locale,
        property: property,
        uuid: lineItem.uuid,
        embeds: lineItem.embeds,
        period: lineItem.period,
        unitName: lineItem.unitName,
        teamwoxId: lineItem.teamwoxId,
        workType: workType?.name || 'N/A',
        completedAt: lineItem.completedAt,
        description: lineItem.description,
        contractUuid: lineItem.contractUuid,
        amount: formatMoney(lineItem.totalAmount),
        unitPrice: formatMoney(lineItem.unitPrice),
        fixedPrice: formatMoney(lineItem.fixedPrice),
        communicationEntityType: lineItem.entityType,
        communicationEntityUuid: lineItem.entityUuid,
        communicationLink: lineItem.communicationLink,
        highlight: hasOnlyCreatedDate(lineItem.history),
        history: historyToHistoryData(lineItem.history, lineItem.supervisor),
        quantity: formatQuantity(lineItem.quantity, lineItem.quantityDivider)
      };
    });
  }
);

export const selectWorkTypesForFilter =
  createWorkTypesFilterSelector(selectContractsList);

export const selectWorkItemsFilterPresets = createSelector(
  selectLineItemsSegment,
  segment => segment.presets
);

export const selectWorkItemsFilterPresetInfo = createSelector(
  selectWorkItemsFilterPresets,
  presets =>
    Object.entries(presets)
      .map(([id, preset]) => ({
        id: Number(id),
        title: preset.title
      }))
      .sort((first, second) => second.id - first.id)
);

export const selectDefaultCurrencyTotalSum = createSelector(
  selectTotalSumData,
  selectDefaultCurrency,
  (totals, defaultCurrency) => {
    const value = totals[defaultCurrency] || 0;
    const formatMoney = createMoneyFormatter(defaultCurrency, true);

    return formatMoney(value);
  }
);

export const selectContractData = (uuid?: string) =>
  createSelector(
    selectContract(uuid),
    selectFormattedContractBalance(uuid),
    contract =>
      contract
        ? {
            id: contract.id,
            title: contract.title || ''
          }
        : undefined
  );

export const selectDependenciesStatus = createSelector(
  selectSettingsStatus,
  selectContractsStatus,
  calculateStatus
);

export const selectDomainStatus = createSelector(
  selectDependenciesStatus,
  selectLineItemsStatus,
  calculateStatus
);
