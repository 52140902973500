export enum InputType {
  File = 'File',
  Link = 'Link',
  FileDescriptor = 'FileDescriptor'
}

export interface InputFileDescriptor {
  type: InputType.FileDescriptor;
  fileName: string;
  entityUuid: string;
}

export interface InputFile {
  type: InputType.File;
  file: File;
}

export interface InputLink {
  type: InputType.Link;
  link: string;
}

export type InputValue = InputFile | InputLink | InputFileDescriptor;
