import { map, mergeMap } from 'rxjs/operators';

import { createLoadingEpic } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';

import { linkUnlimint } from '../unlimint';
import { AccountProvider } from '../settings';
import { linkCitiBankSEPA } from '../сitiBankSEPA';
import { pushNotification } from '../notifications';

import { selectHasAccount } from './selectors';
import { getAccountNameByType } from './helpers';
import { loadUserAccountsAsync } from './actions';
import { UserAccountsEpicDependencies } from './interface';

const loadUserAccountsEpic = createLoadingEpic({
  action: loadUserAccountsAsync,
  selectFetcher: (deps: UserAccountsEpicDependencies) =>
    deps.api.getUserAccounts
});

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadUserAccountsAsync.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

const successLinkAccountEpic: Epic = (actions$, state$) =>
  actions$.pipe(
    ofAction(linkCitiBankSEPA.done, linkUnlimint.done),
    mergeMap(action => {
      const provider = linkCitiBankSEPA.done.match(action)
        ? AccountProvider.CitiBankSEPA
        : AccountProvider.Unlimint;
      const providerName = getAccountNameByType(provider);

      const message = selectHasAccount(provider)(state$.value)
        ? `Your current ${providerName} account details will be changed after saving`
        : 'Account was successfully linked';

      const reloadAction = loadUserAccountsAsync.started({});
      const noticeAction = pushNotification({
        message,
        type: 'success'
      });

      return [reloadAction, noticeAction];
    })
  );

export const userAccountsEpic = combineEpics(
  loadUserAccountsEpic,
  errorNotificationEpic,
  successLinkAccountEpic
);
