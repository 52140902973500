import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

import { millisecondsToUnixTime } from '@tsp/utils/time/convert';

export const rangeToDateRequest = (range: [number, number]) => {
  const from = Math.max(0, startOfDay(range[0]).getTime());
  const to = endOfDay(range[1]).getTime() - 1000;

  return {
    dateTo: millisecondsToUnixTime(to),
    dateFrom: millisecondsToUnixTime(from)
  };
};
