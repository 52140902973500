import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';

import { unixTimeToMilliseconds } from '@tsp/utils/time/convert';

const QUANTITY_MS_FORMAT = 60000;
const QUANTITY_HOURS_FORMAT = 3600;

const formatUtc = (seconds: number, template: string) => {
  const ms = unixTimeToMilliseconds(seconds);
  const date = new Date(ms);
  const tzOffset = date.getTimezoneOffset();

  return format(addMinutes(date, tzOffset), template);
};

export const formatQuantity = (quantity: number, divider: number) => {
  if (divider === QUANTITY_HOURS_FORMAT) {
    return formatUtc(quantity, 'HH:mm');
  }

  if (divider === QUANTITY_MS_FORMAT) {
    return formatUtc(quantity, 'HH:mm:ss');
  }

  return String(quantity);
};
