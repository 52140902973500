import { createSelector } from 'reselect';

import { ExtendsLocation, RouterSegment } from './interface';

export const selectRouterSegment = (state: RouterSegment) => state.router;

export const selectQueryParams = createSelector(
  selectRouterSegment,
  segment => (segment.location as ExtendsLocation).query || {}
);
