import React, { ComponentType, PropsWithChildren } from 'react';
import { History } from 'history';
import { hot } from 'react-hot-loader/root';
import { renderRoutes } from 'react-router-config';

import { defineApi } from '@tsp/api';
import { initSentry } from '@tsp/sentry';
import { Recovery } from '@tsp/recovery';
import { createApplication } from '@tsp/core';
import { RuntimeEnvironments } from '@tsp/env';
import { combineEpics } from '@tsp/utils/epics';

import {
  notificationsEpic,
  notificationsReducer
} from './features/notifications';
import {
  customPaymentsEpic,
  customPaymentsReducer
} from './features/customPayments';
import {
  citiBankPayPalEpic,
  citiBankPayPalReducer
} from './features/сitiBankPayPal';
import {
  contractorAccountsEpic,
  contractorAccountsReducer
} from './features/contractorAccounts';
import { payoutEpic } from './features/payout';
import { configReducer } from './features/config';
import { createEnvReducer } from './features/env';
import { externalEpic } from './features/external';
import { userEpic, userReducer } from './features/user';
import { idBankEpic, idBankReducer } from './features/idBank';
import { payseraEpic, payseraReducer } from './features/paysera';
import { detailsReducer, detailsEpic } from './features/details';
import { bank131Epic, bank131Reducer } from './features/bank131';
import { unlimintEpic, unlimintReducer } from './features/unlimint';
import { overviewReducer, overviewEpic } from './features/overview';
import { settingsReducer, settingsEpic } from './features/settings';
import { invoicesReducer, invoicesEpic } from './features/invoices';
import { contractsReducer, contractsEpic } from './features/contracts';
import { workItemsEpic, lineItemsReducer } from './features/workItems';
import { konsoleProEpic, konsoleProReducer } from './features/konsolePro';
import { restaffEpic, restaffReducer } from './features/restaff';
import { customWorksEpic, customWorksReducer } from './features/customWorks';
import { citiBankSEPAReducer, citiBankSEPAEpic } from './features/сitiBankSEPA';
import { userAccountsReducer, userAccountsEpic } from './features/userAccounts';
import { departmentsReducer, departmentsEpic } from './features/departments';

import apiBlank from './api';
import { routes } from './routes';
import { EpicDependencies, State } from './interface';
import { revolutEpic, revolutReducer } from './features/revolut';

interface AppRouterProps {
  routerComponent: ComponentType<PropsWithChildren<{}>>;
}

export interface WrapperFactoryProps {
  history: History;
  recovery?: Recovery;
  env: RuntimeEnvironments;
}

const rootEpic = combineEpics(
  userEpic,
  idBankEpic,
  payoutEpic,
  revolutEpic,
  payseraEpic,
  detailsEpic,
  unlimintEpic,
  bank131Epic,
  overviewEpic,
  settingsEpic,
  invoicesEpic,
  externalEpic,
  contractsEpic,
  workItemsEpic,
  konsoleProEpic,
  restaffEpic,
  customWorksEpic,
  citiBankSEPAEpic,
  userAccountsEpic,
  notificationsEpic,
  citiBankPayPalEpic,
  customPaymentsEpic,
  contractorAccountsEpic,
  departmentsEpic
);

export const createRootComponent = (props: WrapperFactoryProps) => {
  const { history, env, recovery } = props;
  const recoveryState = recovery?.getRecovery();

  const reducers = {
    user: userReducer,
    config: configReducer,
    idBank: idBankReducer,
    paysera: payseraReducer,
    details: detailsReducer,
    bank131: bank131Reducer,
    revolut: revolutReducer,
    unlimint: unlimintReducer,
    overview: overviewReducer,
    settings: settingsReducer,
    invoices: invoicesReducer,
    env: createEnvReducer(env),
    contracts: contractsReducer,
    konsolePro: konsoleProReducer,
    restaff: restaffReducer,
    citiBankSEPA: citiBankSEPAReducer,
    userAccounts: userAccountsReducer,
    notifications: notificationsReducer,
    citiBankPayPal: citiBankPayPalReducer,
    lineItems: lineItemsReducer(recoveryState),
    contractorAccounts: contractorAccountsReducer,
    customWorks: customWorksReducer(recoveryState),
    customPayments: customPaymentsReducer(recoveryState),
    departments: departmentsReducer
  };

  const api = defineApi(apiBlank, { origin: env.API_SERVER });

  const dependencies = {
    api,
    initSentry,
    setRecovery: recovery?.setRecovery
  };

  return createApplication<State, EpicDependencies>({
    history,
    epicConfig: {
      rootEpic,
      dependencies
    },
    storeConfig: {
      reducers,
      middlewares: []
    }
  });
};

const AppRouter = (props: AppRouterProps) => {
  const { routerComponent: Router } = props;

  return <Router>{renderRoutes(routes)}</Router>;
};

export default hot(AppRouter);
