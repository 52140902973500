import React, { useRef, useState, useEffect } from 'react';
import { Theme } from '@mui/system';
import { lighten } from '@mui/material/styles';
import { Box } from '@tsp/tsp-frontend-components/mui';

const STEPS = 100;
const STEP_DELAY = 200;
const LOADER_HEIGHT = 4;

interface Props {
  show: boolean;
  onHide?: () => void;
}

const classes = {
  root: (theme: Theme) =>
    ({
      width: '100%',
      position: 'relative',
      height: LOADER_HEIGHT,
      backgroundColor: lighten(theme.palette.primary.light, 0.6)
    } as const),
  progress: (progress: number) => (theme: Theme) =>
    ({
      top: 0,
      left: 0,
      height: '100%',
      transition: '300ms',
      position: 'absolute',
      width: `${progress}%`,
      backgroundColor: theme.palette.primary.light
    } as const)
};

export const PageLoader = ({ show, onHide }: Props) => {
  const stepRef = useRef(5);
  const timerRef = useRef<NodeJS.Timeout | undefined>();
  const [progress, setProgress] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (stepRef.current < STEPS) {
        const value = STEPS - (5 / stepRef.current++) * STEPS;
        setProgress(value);
      }
    }, STEP_DELAY);

    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (!show) {
      clearInterval(timerRef.current);
      setProgress(100);

      setTimeout(() => setVisible(false), 300);
    }

    return () => clearInterval(timer);
  }, [show]);

  useEffect(() => {
    if (!visible) {
      onHide?.();
    }
  }, [onHide, visible]);

  return visible ? (
    <Box sx={classes.root}>
      <Box sx={classes.progress(progress)} />
    </Box>
  ) : null;
};
