import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { linkIdBank } from './actions';
import { IdBankState } from './interface';

const initialState: IdBankState = {
  status: EntityStatus.initial
};

export const reducer = (state = initialState) => state;

export const idBankReducer = withStatusReducer(linkIdBank, reducer);
