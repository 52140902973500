import { useEffect, useState } from 'react';
import * as Cookies from 'js-cookie';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import { parse, ParsedQuery } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import { EntityStatus } from '@tsp/utils/asyncEntity';

import { fetchUser, selectUserSegment } from '../../user';

import { ACCESS_PAGE_COOKIE_NAME } from '../interface';

const extractSafe = (value: string | string[]): string | undefined => {
  return value && !Array.isArray(value) ? value : undefined;
};

const mapQueryToApiParams = (query: ParsedQuery) => {
  const code = extractSafe(query.code);
  const session_state = extractSafe(query.session_state);

  return { code, session_state };
};

// Логика обработки ответа от SSO сервера
export const useLanding = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string | undefined>();

  const query = parse(useLocation().search);
  const correctQuery = query.code && query.session_state;

  const { status, data, error } = useSelector(selectUserSegment);

  useEffect(() => {
    const nextMessage = correctQuery
      ? status === EntityStatus.fetching
        ? undefined
        : status === EntityStatus.error && error
        ? error
        : undefined
      : 'Incorrect query values';

    setMessage(nextMessage);
  }, [correctQuery, error, status]);

  useEffect(() => {
    if (correctQuery && status === EntityStatus.initial) {
      dispatch(fetchUser.started(mapQueryToApiParams(query)));
    }
  }, [correctQuery, dispatch, query, status]);

  useEffect(() => {
    if (status === EntityStatus.fetched && data) {
      if (data.authenticated) {
        const accessPage = Cookies.get(ACCESS_PAGE_COOKIE_NAME);
        const redirect = accessPage || '/';

        dispatch(push(redirect));
      } else {
        setMessage(
          'Authentication error, please check session_state and code values'
        );
      }
    }
  }, [data, dispatch, status]);

  return [message, status];
};
