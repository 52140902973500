import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { createLoadingEpic } from '@tsp/utils/asyncEntity';

import { map } from 'rxjs/operators';

import { pushNotification } from '../notifications';

import { loadContractorAccountsAsync } from './actions';
import { ContractorAccountsEpicDependencies } from './interface';

const loadContractorAccountsEpic = createLoadingEpic({
  action: loadContractorAccountsAsync,
  selectFetcher: (deps: ContractorAccountsEpicDependencies) =>
    deps.api.getContractorAccounts
});

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadContractorAccountsAsync.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

export const contractorAccountsEpic = combineEpics(
  errorNotificationEpic,
  loadContractorAccountsEpic
);
