import { unixTimeToMilliseconds } from '@tsp/utils/time/convert';
import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { GetOverviewRequest, GetOverviewResponse } from './interface';

const translateResponseToOverviewData = (
  response: GetOverviewResponse
): GetOverviewResponse => {
  const { contractEarnings, periodEarnings, lastPayoutTs } = response;

  return {
    periodEarnings,
    contractEarnings,
    lastPayoutTs: unixTimeToMilliseconds(lastPayoutTs)
  };
};

const getIncomeOverview = defineHandler<
  GetOverviewRequest,
  GetOverviewResponse
>({
  path: '/getIncomeOverview',
  method: HttpMethod.GET,
  params: ParamsContainer.Query,
  translator: translateResponseToOverviewData
});

export const overviewApi = {
  getIncomeOverview
};
