import { AnyAction } from 'typescript-fsa';

import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { DepartmentsState } from './interface';
import { loadDepartmentsAction } from './actions';

const initialState: DepartmentsState = {
  status: EntityStatus.initial
};

const reducer = (state = initialState, action: AnyAction): DepartmentsState => {
  if (loadDepartmentsAction.done.match(action)) {
    return {
      ...state,
      data: action.payload.result.departments
    };
  }

  return state;
};

export const departmentsReducer = withStatusReducer(
  loadDepartmentsAction,
  reducer
);
