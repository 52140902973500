import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { combineReducers, ReducersMapObject } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { theme } from '@tsp/tsp-frontend-components';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { createEpicMiddleware } from '@tsp/utils/epics';

import { AppFactoryConfig } from './interface';
import { configureStore, CoreState } from './store';

export const createApplication = <S extends CoreState, D>(
  config: AppFactoryConfig<S, D>
) => {
  const { epicConfig, storeConfig, history } = config;
  const { reducers, middlewares } = storeConfig;

  const epicMiddleware = createEpicMiddleware(epicConfig);

  const reducersMap = {
    router: connectRouter(history),
    ...reducers
  } as unknown as ReducersMapObject<S>;

  const reducer = combineReducers<S>(reducersMap);

  const store = configureStore<S>(reducer, [
    routerMiddleware(history),
    epicMiddleware.middleware,
    ...middlewares
  ]);

  epicMiddleware.run();

  return ({ children }: PropsWithChildren<{}>) => (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <EmotionThemeProvider theme={theme}>
          <ThemeProviderLegacy theme={theme}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {children}
            </ThemeProvider>
          </ThemeProviderLegacy>
        </EmotionThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};
