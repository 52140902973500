import { composeWithDevTools } from 'redux-devtools-extension';
import {
  Reducer,
  compose,
  Middleware,
  createStore,
  applyMiddleware
} from 'redux';

export const configureStore = <S>(
  reducer: Reducer<S>,
  middlewares: Middleware[] = []
) => {
  const composedMiddlewares = applyMiddleware(...middlewares);

  const enhancer =
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(composedMiddlewares)
      : compose(composedMiddlewares);

  return createStore(reducer, enhancer);
};
