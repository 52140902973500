import { UserStoreSegment } from './interface';

export const selectUserSegment = (state: UserStoreSegment) => state.user;

export const selectUserData = (state: UserStoreSegment) => {
  const segment = selectUserSegment(state);
  return segment.data;
};

export const selectUserPositions = (state: UserStoreSegment) => {
  const data = selectUserData(state);
  return data.positions;
};
