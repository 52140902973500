import { Handler } from '@tsp/api';

import { Currency } from '../money';
import { SettingsSystemType } from '../settings';
import { ContractorAccount } from '../contractorAccounts';

export enum CardStatus {
  Manual = 'Manual',
  Verified = 'Verified',
  NotVerified = 'NotVerified',
  NotSupported = 'NotSupported'
}

export interface GetLinkAccountUrlRequest {
  contractorAccount: string;
}

export interface GetLinkAccountUrlResponse {
  link: string;
}

export interface SetDefaultAccountRequest {
  // { [contractorUid]: { [currencyId]: [accountUid] } }
  accounts: Record<string, Record<number, string>>;
}

export interface SetDefaultAccountResponse {
  currency: Currency;
  accountUid: string;
  contractorUid: string;
}

export interface PayoutEpicDependencies {
  api: {
    getLinkAccountUrl: Handler<
      GetLinkAccountUrlRequest,
      GetLinkAccountUrlResponse
    >;
    setDefaultAccount: Handler<
      SetDefaultAccountRequest,
      SetDefaultAccountResponse
    >;
  };
}

export interface AccountData {
  systemType: SettingsSystemType;
  contractorAccount: ContractorAccount;
}

export interface ExternalAccountView {
  uuid: string;
  showMenu: boolean;
  status: CardStatus;
}
