import { createSelector } from 'reselect';

import {
  SettingsWorkTypes,
  SettingsStoreSegment,
  SettingsProjectPortfolio
} from './interface';

export const selectSettingsSegment = (state: SettingsStoreSegment) =>
  state.settings;

export const selectSettingsStatus = (state: SettingsStoreSegment) => {
  return state.settings.status;
};

export const selectSystemTypes = (state: SettingsStoreSegment) => {
  const segment = selectSettingsSegment(state);
  return segment.data?.systemTypes;
};

export const selectContractTypes = (state: SettingsStoreSegment) => {
  const settings = selectSettingsSegment(state);
  return settings.data.contractTypes;
};

export const selectProjectsPortfolio = (state: SettingsStoreSegment) => {
  const settings = selectSettingsSegment(state);
  return settings.data.projectsPortfolio;
};

export const selectWorkTypes = createSelector(
  selectContractTypes,
  contractTypes =>
    Object.values(contractTypes).reduce<SettingsWorkTypes>(
      (result, { work_types }) =>
        Object.entries(work_types).reduce((result, [id, workType]) => {
          result[id] = workType;
          return result;
        }, result),
      {}
    )
);

export const selectProjectsPortfolioLocales = (localeNames: string[]) =>
  createSelector(selectProjectsPortfolio, portfolios =>
    localeNames.reduce<SettingsProjectPortfolio[]>((result, localeName) => {
      const locale = portfolios?.[localeName];

      if (locale) {
        result.push(locale);
      }

      return result;
    }, [])
  );

export const selectFormLocales = (properties: string[] | undefined) =>
  createSelector(selectProjectsPortfolio, locales => {
    let items = Object.entries(locales || {});
    if (typeof properties !== 'undefined') {
      if (!properties.length) {
        items = [];
      } else {
        items = items.filter(([_, projectPortfolio]) => {
          return Object.values(projectPortfolio.brands).some(brand => {
            return Object.keys(brand.properties).some(brandProperty => {
              return properties.includes(brandProperty);
            });
          });
        });
      }
    }

    return items.map(([uuid, locale]) => ({
      uuid,
      name: locale.name
    }));
  });

export const selectFormBrands = (
  properties: string[] | undefined,
  localeNames: string[],
  includeInactive = false
) =>
  createSelector(selectProjectsPortfolioLocales(localeNames), locales => {
    return locales.reduce<{ uuid: string; name: string }[]>(
      (result, locale) => {
        Object.entries(locale.brands).forEach(([uuid, brand]) => {
          const hasProperties = Object.entries(brand.properties).some(
            ([uuid, property]) => {
              const isAvailable = property.isActive || includeInactive;
              const isIncluded =
                typeof properties === 'undefined' || properties.includes(uuid);

              return isAvailable && isIncluded;
            }
          );

          if (hasProperties) {
            result.push({ uuid, name: brand.label });
          }
        });

        return result;
      },
      []
    );
  });

export const selectFormBrandsProperties = (
  properties: string[] | undefined,
  brandIds: string[],
  includeInactive = false
) =>
  createSelector(selectProjectsPortfolio, projectPortfolio =>
    Object.values(projectPortfolio).reduce<{ uuid: string; name: string }[]>(
      (result, locale) => {
        Object.entries(locale.brands).forEach(([brandId, brand]) => {
          if (brandIds.includes(brandId)) {
            Object.entries(brand.properties).forEach(([uuid, property]) => {
              const isAvailable = property.isActive || includeInactive;
              const isIncluded =
                typeof properties === 'undefined' || properties.includes(uuid);

              if (isAvailable && isIncluded) {
                result.push({
                  uuid,
                  name: `${property.label}${
                    property.isActive ? '' : ' (Inactive)'
                  }`
                });
              }
            });
          }
        });
        return result;
      },
      []
    )
  );
