import { Handler } from '@tsp/api';
import { RecoveryStateSetter } from '@tsp/recovery';
import { AsyncList, AsyncStatus } from '@tsp/utils/asyncEntity';
import { WithSorting } from '@tsp/utils/translators/createWithSorting';

import {
  ApiLineItem,
  CommunicationTask,
  DocumentAttachment
} from '../workItems';
import { FilterPresetsList } from '../filters';
import { SortValue } from '../components/Table';
import { InputValue } from '../components/FileInput/interface';
import { TotalSumStorable, TotalSumStorableApi } from '../money';
import { DocumentHistory, DocumentHistoryDates } from '../history';

export const CUSTOM_PAYMENT_CONTRACT_TYPE = '2000';

export interface ApiCustomPayment {
  uuid: string;
  paidAt: number;
  workType: string;
  createdAt: number;
  completedAt: number;
  description: string;
  departmentUuid: string;
  totalAmountStorable: number;
  _embedded: {
    contract: {
      uuid: string;
    };
    lineItem?: ApiLineItem;
    communicationTask?: CommunicationTask;
  };
}

export interface ApiCustomPaymentsResponse {
  page_size: number;
  total_items: number;
  totalSumStorable: TotalSumStorableApi;
  _embedded: {
    custom_payment: ApiCustomPayment[];
  };
}

export interface CustomPaymentDetailsView {
  amount: string;
  period: string;
  contract: string;
  workType: string;
  departmentName: string;
  description: string;
  attachment: InputValue[];
}

export interface CustomPayment {
  uuid: string;
  amount: number;
  workType: string;
  contract: string;
  departmentUuid: string;
  completedAt: number;
  description: string;
  supervisor?: string;
  communicationLink?: string;
  history: DocumentHistoryDates;
  attachment: DocumentAttachment[];
}

export type GetCustomPaymentsRequest = WithSorting<{
  page: number;
  limit?: number;
  search?: string;

  status?: string;

  dateTo?: number;
  dateFrom?: number;

  'filter[0][type]'?: 'eq';
  'filter[0][value]'?: string;
  'filter[0][field]'?: 'workType';
}>;

export interface GetCustomPaymentsResponse {
  total: number;
  offset: number;
  items: CustomPayment[];
  totalSum: TotalSumStorable;
}

export interface CreateCustomPaymentRequest {
  sign?: boolean;
  paidAt: number;
  workType: string;
  createdAt: number;
  attachment?: File;
  workSubType: string;
  completedAt: number;
  departmentUuid: string;
  description?: string;
  contractUuid: string;
  totalAmountStorable: number;
}

export interface UpdateCustomPaymentRequest {
  sign?: boolean;
  paidAt: number;
  workType: string;
  attachment?: File;
  workSubType: string;
  departmentUuid: string;
  description?: string;
  contractUuid: string;
  deleteAttachment: number;
  totalAmountStorable: number;
}

export type CreateCustomPaymentResponse = ApiCustomPayment;
export type UpdateCustomPaymentResponse = ApiCustomPayment;

export interface DeleteCustomPaymentRequest {}
export type DeleteCustomPaymentResponse = unknown;

export type CustomPaymentsFormState = AsyncStatus;

interface ToolsFormMainValues {
  pagination: {
    page: number;
    limit: number;
  };
  search: string;
  period: [number, number];
}

export interface ToolsFormAdditionalValues {
  status?: string;
  sort?: SortValue;
  workType?: string;
}

export type ToolsFormValues = ToolsFormMainValues & ToolsFormAdditionalValues;

export type ToolsFormAdditionalKeys = keyof ToolsFormAdditionalValues;

export type CustomPaymentsFilterPresets =
  FilterPresetsList<ToolsFormAdditionalValues>;

export type CustomPaymentsState = AsyncList<CustomPayment> & {
  totalSum: TotalSumStorable;
  form: CustomPaymentsFormState;
  presets: CustomPaymentsFilterPresets;
};

export interface CustomPaymentsStoreSegment {
  customPayments: CustomPaymentsState;
}

export interface FormData {
  sign?: boolean;
  next?: boolean;
  contract: string;
  workType: string;
  departmentUuid: string;
  completedAt: number;
  description: string;
  attachment?: InputValue[];
  totalAmountStorable: string;
}

export interface CustomPaymentTableView {
  uuid: string;
  amount: string;
  period: string;
  asanaId: string;
  workType: string;
  createdAt: number;
  highlight?: boolean;
  description?: string;
  history: DocumentHistory;
  attachment?: InputValue[];
  communicationLink?: string;
}

export interface CustomPaymentsRecoveryState {
  customPayments?: {
    presets: CustomPaymentsFilterPresets;
  };
}

export interface CustomPaymentsEpicDependencies {
  api: {
    getCustomPayments: Handler<
      GetCustomPaymentsRequest,
      GetCustomPaymentsResponse
    >;
    createCustomPayment: Handler<
      CreateCustomPaymentRequest,
      CreateCustomPaymentResponse
    >;
    updateCustomPayment: Handler<
      UpdateCustomPaymentRequest,
      UpdateCustomPaymentResponse
    >;
    deleteCustomPayment: Handler<
      DeleteCustomPaymentRequest,
      DeleteCustomPaymentResponse
    >;
  };
  setRecovery?: RecoveryStateSetter<CustomPaymentsRecoveryState>;
}
