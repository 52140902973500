import { createSelector } from 'reselect';

import { selectEnvValues } from '../env';
import { selectUserSegment } from '../user';

const SSO_TOOLTIP = 'Open SSO';

export const selectUserData = createSelector(
  selectUserSegment,
  selectEnvValues,
  (userSegment, { SSO_URL }) => {
    const { data } = userSegment;

    return data
      ? {
          nameLink: SSO_URL,
          nameHint: SSO_TOOLTIP,
          email: data.user.email,
          screenName: data.user.screenName
        }
      : undefined;
  }
);
