import { INITIAL_RANGE } from '../filters';
import { CellMeta } from '../components/Table';

import { ToolsFormValues } from './interface';

export const tableConfig: Record<string, CellMeta> = {
  date: { width: '15%', sort: false },
  document: { width: '20%' },
  period: { width: '10%', sort: false },
  amount: { width: '10%', align: 'right', sort: false },
  description: { width: '40%' },
  control: { width: '5%', align: 'right' }
};

export const toolsFormInitialValues: ToolsFormValues = {
  pagination: {
    page: 0,
    limit: 50
  },
  range: INITIAL_RANGE
};
