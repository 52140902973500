import { AjaxRequest } from 'rxjs/ajax';

import { ParamsContainer, RequestFunctionFactoryOptions } from '../interface';

export const propsToBody =
  (options: RequestFunctionFactoryOptions) =>
  (request: AjaxRequest, params: Object): AjaxRequest => {
    const contentTypeHeader = {
      'content-type': 'application/json'
    };

    const headers = request.headers
      ? { ...request.headers, ...contentTypeHeader }
      : contentTypeHeader;

    return (options.params || ParamsContainer.Body) === ParamsContainer.Body
      ? { ...request, headers, body: params }
      : request;
  };
