import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { LinkBank131Request, LinkBank131Response } from './interface';

const linkBank131 = defineHandler<LinkBank131Request, LinkBank131Response>({
  path: '/linkBank131Account',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const bank131Api = {
  linkBank131
};
