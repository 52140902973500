import { map, mergeMap } from 'rxjs/operators';

import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { parseServerError } from '@tsp/api/utils/parseServerError';
import { createPageInitEpic } from '@tsp/core/features/dependencies';

import {
  loadingAction as loadingContracts,
  domainAction as contractsDomainAction
} from '../contracts';
import {
  loadContractorAccountsAsync,
  ContractorAccountsStoreSegment,
  domainAction as contractorAccountsDomainAction
} from '../contractorAccounts';
import { EnvSegment } from '../env';
import { pushNotification } from '../notifications';
import { loadSettingsOnce } from '../settings/actions';
import {
  selectUserAccount,
  updateDefaultAccount,
  loadUserAccountsAsync,
  domainAction as userAccountsDomainAction,
  UserAccountsStoreSegment
} from '../userAccounts';

import { selectDependenciesStatus } from './selectors';
import { linkAccount, domainAction, setDefaultAccount } from './actions';
import { PayoutEpicDependencies, SetDefaultAccountRequest } from './interface';

type State = ContractorAccountsStoreSegment &
  UserAccountsStoreSegment &
  EnvSegment;

export const linkAccountEpic: Epic<State, PayoutEpicDependencies> = (
  actions$,
  state$,
  deps
) =>
  actions$.pipe(
    ofAction(linkAccount.started),
    mergeMap(action => {
      const { contractorAccount } = action.payload;

      return deps.api.getLinkAccountUrl({ contractorAccount }).pipe(
        map(response =>
          linkAccount.done({
            result: response,
            params: action.payload
          })
        ),
        catchAsyncError(error =>
          linkAccount.failed({
            error: parseServerError(error),
            params: action.payload
          })
        )
      );
    })
  );

export const openAccountLink: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkAccount.done),
    mergeMap(action => {
      window.location.href = action.payload.result.link;
      return [];
    })
  );

export const setDefaultAccountEpic: Epic<State, PayoutEpicDependencies> = (
  actions$,
  state$,
  deps
) =>
  actions$.pipe(
    ofAction(setDefaultAccount.started),
    mergeMap(({ payload }) => {
      const { contractor, account } = payload;
      const { currency } = selectUserAccount(account)(state$.value);

      const request: SetDefaultAccountRequest = {
        accounts: {
          [contractor]: { [currency.id]: account }
        }
      };

      const result = {
        accountUid: account,
        currency: currency.id,
        contractorUid: contractor
      };

      return deps.api.setDefaultAccount(request).pipe(
        map(() =>
          setDefaultAccount.done({
            result,
            params: payload
          })
        ),
        catchAsyncError(error =>
          setDefaultAccount.failed({
            params: payload,
            error: parseServerError(error)
          })
        )
      );
    })
  );

const updateDefaultAccountEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(setDefaultAccount.done),
    map(action => updateDefaultAccount(action.payload.result))
  );

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(setDefaultAccount.failed, linkAccount.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

const resetDependenciesEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.reset),
    mergeMap(() => {
      return [
        contractsDomainAction.reset(),
        userAccountsDomainAction.reset(),
        contractorAccountsDomainAction.reset()
      ];
    })
  );

const dependenciesEpic = createPageInitEpic({
  domainAction,
  selectStatus: selectDependenciesStatus,
  dependencies: [
    loadingContracts,
    loadSettingsOnce,
    loadUserAccountsAsync,
    loadContractorAccountsAsync
  ]
});

export const payoutEpic = combineEpics(
  linkAccountEpic,
  openAccountLink,
  dependenciesEpic,
  resetDependenciesEpic,
  setDefaultAccountEpic,
  errorNotificationEpic,
  updateDefaultAccountEpic
);
