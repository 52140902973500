import { AnyAction } from 'typescript-fsa';
import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { RestaffState } from './interface';
import { linkRestaff, resetRestaff } from './actions';

const initialState: RestaffState = {
  link: {
    status: EntityStatus.initial
  }
};

export const restaffReducer = (
  state = initialState,
  action: AnyAction
): RestaffState => {
  if (resetRestaff.match(action)) {
    return initialState;
  }

  if (linkRestaff.started.match(action)) {
    return {
      ...state,
      link: {
        status: EntityStatus.fetching
      }
    };
  }

  if (linkRestaff.done.match(action)) {
    return {
      ...state,
      link: {
        status: EntityStatus.fetched,
        data: action.payload.result.registrationUrl
      }
    };
  }

  if (linkRestaff.failed.match(action)) {
    return {
      ...state,
      link: {
        status: EntityStatus.fetched,
        error: action.payload.error
      }
    };
  }

  return state;
};
