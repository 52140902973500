import React from 'react';
import { Redirect } from 'react-router';
import { RouteConfig } from 'react-router-config';

import { MainPage } from './pages/Main';
import { AuthPage } from './pages/Auth';
import { AboutPage } from './pages/About';
import { LegalPage } from './pages/Legal';
import { ContactPage } from './pages/Contact';
import { InvoicesPage } from './pages/Invoices';
import { NotFoundPage } from './pages/NotFound';
// import { ContractsPage } from './pages/Contracts';
import { WorkItemsPage } from './pages/WorkItems';
import { CompliancePage } from './pages/Compliance';
import { SSOLandingPage } from './pages/SSOLanding';
import { CustomWorksPage } from './pages/CustomWorks';
import { PayoutMethodsPage } from './pages/PayoutMethods';
import { CustomPaymentsPage } from './pages/CustomPayments';
import { PayseraLinkingPage } from './pages/PayseraLinking';
import { ExternalDispatchPage } from './pages/ExternalDispatch';

export const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: MainPage
  },
  {
    path: '/auth',
    component: AuthPage
  },
  {
    path: '/sso/landing',
    component: SSOLandingPage
  },
  {
    path: '/work-items',
    component: WorkItemsPage
  },
  {
    exact: true,
    component: CustomWorksPage,
    path: [
      '/custom-works',
      '/custom-works/:action(new)',
      '/custom-works/:action(view)/:id'
    ]
  },
  {
    exact: true,
    component: CustomPaymentsPage,
    path: [
      '/custom-payments',
      '/custom-payments/:action(new)',
      '/custom-payments/:action(view)/:id'
    ]
  },
  {
    path: '/invoices',
    component: InvoicesPage
  },
  // {
  //   exact: true,
  //   path: ['/contracts', '/contracts/:contractId'],
  //   component: ContractsPage
  // },
  {
    exact: true,
    path: [
      '/accounts',
      '/accounts/:provider(idBank)',
      '/accounts/:provider(bank131)',
      '/accounts/:provider(revolut)',
      '/accounts/:provider(unlimint)',
      '/accounts/:provider(konsolePro)',
      '/accounts/:provider(restaff)',
      '/accounts/:provider(citi-bank-sepa)',
      '/accounts/:provider(citi-bank-paypal)'
    ],
    component: PayoutMethodsPage
  },
  {
    path: '/sso/logout',
    component: () => <Redirect to="/" />
  },
  {
    path: '/paysera/oauth',
    component: PayseraLinkingPage
  },
  {
    path: '/compliance/about',
    component: AboutPage
  },
  {
    path: '/compliance/legal',
    component: LegalPage
  },
  {
    path: '/compliance/contact',
    component: ContactPage
  },
  {
    path: '/compliance',
    component: CompliancePage
  },
  {
    path: '/dispatch/:entity',
    component: ExternalDispatchPage
  },
  {
    component: NotFoundPage
  }
];
