import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React, { ComponentType, PropsWithChildren } from 'react';
import { hydrate, render } from 'react-dom';
import { createBrowserHistory, History } from 'history';
import { ConnectedRouter } from 'connected-react-router';

import { loadableReady } from '@loadable/component';

import { fetchEnvClient } from '@tsp/env/client';
import AppRouter, { createRootComponent } from '@tsp/app';

import { recovery } from './utils/recovery';

const createRouter =
  (history: History): ComponentType<PropsWithChildren<{}>> =>
  props =>
    <ConnectedRouter history={history}>{props.children}</ConnectedRouter>;

loadableReady(async () => {
  const history = createBrowserHistory();
  const Router = createRouter(history);

  const env = await fetchEnvClient();

  const renderApp = window.__ssr ? hydrate : render;
  const Wrapper = createRootComponent({ history, recovery, env });

  renderApp(
    <Wrapper>
      <AppRouter routerComponent={Router} />
    </Wrapper>,
    document.getElementById('root')
  );
});
