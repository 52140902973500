import {
  HistoryKey,
  DocumentHistory,
  DocumentHistoryDates
} from '../interface';

export const historyToHistoryData = (
  history: DocumentHistoryDates,
  supervisor?: string
) => {
  return Object.entries(history).reduce<DocumentHistory>(
    (result, [key, value]) => {
      result[key as HistoryKey] = { value };

      if (key === 'signedAt' && supervisor) {
        result[key].initiator = supervisor;
      }

      return result;
    },
    {}
  );
};
