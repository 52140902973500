import { RuntimeEnvironments } from '@tsp/env';

import { FormData, LinkKonsoleProRequest } from './interface';

export const translateFormDataToRequest = (
  data: FormData,
  env: RuntimeEnvironments
): LinkKonsoleProRequest => {
  return {
    phone: `7${data.phone}`,
    currency: env.DEFAULT_CURRENCY,
    contractor: env.DEFAULT_CONTRACTOR
  };
};
