import { ConfigSegment } from './interface';

export const selectConfigSegment = (state: ConfigSegment) => {
  return state.config;
};

export const selectProjectName = (state: ConfigSegment) => {
  return selectConfigSegment(state).projectName;
};

export const selectProjectsList = (state: ConfigSegment) =>
  state.config.projects;
