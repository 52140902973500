import React, { FC } from 'react';
import { Theme } from '@mui/system';
import { Typography, Box } from '@tsp/tsp-frontend-components/mui';

interface Props {
  title: string;
}

const classes = {
  root: (theme: Theme) => ({
    width: '100%',
    maxWidth: 1920,
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    padding: `0 ${theme.spacing(7)}`
  }),
  title: {
    marginRight: 2
  }
};

export const Title: FC<Props> = ({ title, children }) => (
  <Box sx={classes.root}>
    <Typography sx={classes.title} variant="h3">
      {title}
    </Typography>
    {children}
  </Box>
);
