import { actionCreatorFactory } from 'typescript-fsa';

import { LinkPayseraRequest, LinkPayseraResponse } from './interface';

const createAction = actionCreatorFactory('PAYSERA');

export const linkPaysera = createAction.async<
  LinkPayseraRequest,
  LinkPayseraResponse,
  string
>('LINK');
