import { Observable } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';

/* eslint-disable @typescript-eslint/no-explicit-any */

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export enum ParamsContainer {
  Body = 'body',
  Form = 'form',
  Query = 'query'
}

export interface RequestFunctionFactoryOptions<Req = any, Res = any> {
  path: string;
  method: HttpMethod;
  responseType?: string;
  params?: ParamsContainer;
  headers?: Record<string, string>;
  translator?: (data: any, request: Req) => Res;
}

export type Middleware = (request: AjaxRequest, params: Object) => AjaxRequest;
export type MiddlewareFactory = (
  options: RequestFunctionFactoryOptions
) => Middleware;

export interface ApiConfig {
  origin: string;
}

export type Handler<Req = any, Res = any> = (
  req: Req,
  mutator?: Middleware
) => Observable<Res>;

export type HandlerFactory<Req = any, Res = any> = (
  apiConfig: ApiConfig
) => Handler<Req, Res>;

export type BlankMapObject<Req = any, Res = any> = {
  [key: string]: HandlerFactory<Req, Res>;
};

export type Basket<B extends BlankMapObject> = B extends BlankMapObject<
  infer Req,
  infer Res
>
  ? {
      [K in keyof B]: ReturnType<B[K]>;
    }
  : never;

export interface ApiBase {
  setBearerToken: (token: string) => void;
}

export type Api<B extends BlankMapObject> = ApiBase & Basket<B>;
