import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { LinkCitiBankSEPARequest, LinkCitiBankSEPAResponse } from './interface';

const linkCitiBankSEPA = defineHandler<
  LinkCitiBankSEPARequest,
  LinkCitiBankSEPAResponse
>({
  path: '/linkCitibankSEPAAccount',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const citiBankSEPAApi = {
  linkCitiBankSEPA
};
