import { createInRangeDefiner } from '@tsp/utils/time/inRange';
import { unixTimeToMilliseconds } from '@tsp/utils/time/convert';
import { protectUndefined } from '@tsp/utils/common/protectUndefined';
import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import {
  Conditions,
  ApiCondition,
  ApiContracts,
  GetContractRequest,
  GetContractResponse
} from './interface';

const convertTS = protectUndefined(unixTimeToMilliseconds);

const translateApiConditionsToConditions = (
  data: ApiCondition[]
): Conditions => {
  const inRange = createInRangeDefiner(new Date());

  return data.reduce<Conditions>((result, condition) => {
    const periodTo = condition.periodTo
      ? unixTimeToMilliseconds(condition.periodTo)
      : undefined;
    const periodFrom = convertTS(condition.periodFrom);

    const active = periodTo ? inRange(periodFrom, periodTo) : true;

    result[condition.uuid] = {
      active,
      periodTo,
      periodFrom,
      id: condition.uuid,
      workType: condition.workType,
      property: condition.propertyUuid,
      workSubtype: condition.workSubType,
      unitPrice: condition.params.unit_price,
      fixedPrice: condition.params.fixed_price,
      createdAt: convertTS(condition.createdAt),
      supervisors: condition._embedded.supervisors
    };
    return result;
  }, {});
};

const translateResponseToContracts = (
  data: ApiContracts
): GetContractResponse => {
  const inRange = createInRangeDefiner(new Date());

  return Object.values(data).reduce<GetContractResponse>((result, contract) => {
    const periodTo = convertTS(contract.periodTo);
    const periodFrom = convertTS(contract.periodFrom);

    const active = periodTo ? inRange(periodFrom, periodTo) : true;

    result[contract.uuid] = {
      active,
      periodTo,
      periodFrom,
      id: contract.uuid,
      type: contract.type,
      title: contract.description,
      currency: contract.currencyId,
      contractor: contract.contractor,
      supplierName: contract._embedded.supplier.name,
      balance: contract._embedded.supplierAccount.balance,
      conditions: translateApiConditionsToConditions(
        contract.contractConditions
      )
    };
    return result;
  }, {});
};

const getContracts = defineHandler<GetContractRequest, GetContractResponse>({
  method: HttpMethod.GET,
  path: '/getUserContracts',
  params: ParamsContainer.Query,
  translator: translateResponseToContracts
});

export const contractsApi = {
  getContracts
};
