import { DSN_MOCK, TUNNEL_ENDPOINT_NAME } from '../constants';

export interface InitSentryProps {
  user?: {
    id?: string;
    email?: string;
    username?: string;
  };
  token: string;
  apiServer: string;
}

const loadLibraries = () => {
  const sentryPromise = import('@sentry/react');
  const tracingPromise = import('@sentry/tracing');

  return Promise.all([sentryPromise, tracingPromise]).then(
    ([{ init, setUser }, { BrowserTracing }]) => ({
      init,
      setUser,
      BrowserTracing
    })
  );
};

export const initSentry = async ({
  user,
  token,
  apiServer
}: InitSentryProps) => {
  const { init, setUser, BrowserTracing } = await loadLibraries();

  if (user) {
    setUser(user);
  }

  return init({
    dsn: DSN_MOCK,
    integrations: [new BrowserTracing()],
    tunnel: [apiServer, TUNNEL_ENDPOINT_NAME].join('/'),
    transportOptions: {
      headers: {
        authorization: ['Bearer', token].join(' ')
      }
    },
    // TODO: this value in production, or using tracesSampler for finer control
    tracesSampleRate: 1.0
  });
};
