import { Currency } from '../money';

import { EnvSegment } from './interface';

export const selectEnvValues = (state: EnvSegment) => state.env;

export const selectDefaultCurrency = (state: EnvSegment) => {
  return state.env.DEFAULT_CURRENCY as unknown as Currency;
};

export const selectDefaultContractor = (state: EnvSegment) =>
  state.env.DEFAULT_CONTRACTOR;
