import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { LinkRevolutRequest, LinkRevolutResponse } from './interface';

const linkRevolut = defineHandler<LinkRevolutRequest, LinkRevolutResponse>({
  path: '/linkRevolutAccount',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const revolutApi = {
  linkRevolut
};
