import { AnyAction } from 'typescript-fsa';

import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { ContractsState } from './interface';
import { loadingAction, domainAction } from './actions';

const initialState: ContractsState = {
  status: EntityStatus.initial
};

const reducer = (state = initialState, action: AnyAction): ContractsState => {
  if (domainAction.reset.match(action)) {
    return { ...initialState };
  }

  if (loadingAction.done.match(action)) {
    return { ...state, data: action.payload.result };
  }

  return state;
};

export const contractsReducer = withStatusReducer(loadingAction, reducer);
