import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { AnyAction } from 'typescript-fsa';

import { SettingsState } from './interface';
import { loadSettingsAsync } from './actions';

const initialState: SettingsState = {
  status: EntityStatus.initial
};

const reducer = (state = initialState, action: AnyAction): SettingsState => {
  if (loadSettingsAsync.done.match(action)) {
    return { ...state, data: action.payload.result };
  }

  return state;
};

export const settingsReducer = withStatusReducer(loadSettingsAsync, reducer);
