import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';
import { createDomainAction } from '@tsp/core/features/dependencies';

import {
  GetContractorAccountsRequest,
  GetContractorAccountsResponse
} from './interface';

const createAction = actionCreatorFactory('CONTRACTOR_ACCOUNTS');

export const loadContractorAccountsAsync = createAsyncAction<
  GetContractorAccountsRequest,
  GetContractorAccountsResponse
>(createAction);

export const domainAction = createDomainAction(createAction);
