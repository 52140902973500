import React, { FC, useEffect } from 'react';
import { If, Then, Else, When } from 'react-if';

import { useAccess } from '../hooks/useAccess';
import { GetUserStatusResponse } from '../../user/interface';

interface Props {
  onAccess?: () => void;
  placeholder?: () => JSX.Element;
  checkAccess: (data: GetUserStatusResponse) => boolean;
}

export const Access: FC<Props> = props => {
  const { checkAccess, onAccess, placeholder, children } = props;

  const [loading, access] = useAccess({ checkAccess });

  useEffect(() => {
    if (access && onAccess) {
      onAccess();
    }
  }, [access, onAccess]);

  return (
    <If condition={loading}>
      <Then>{placeholder?.()}</Then>
      <Else>
        <When condition={access}>{children}</When>
      </Else>
    </If>
  );
};
