import { push } from 'connected-react-router';
import { mergeMap, map } from 'rxjs/operators';

import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { parseServerError } from '@tsp/api/utils/parseServerError';

import { linkPaysera } from './actions';
import { PayseraEpicDependencies } from './interface';

const linkPayseraEpic: Epic<unknown, PayseraEpicDependencies> = (
  actions$,
  _,
  deps
) =>
  actions$.pipe(
    ofAction(linkPaysera.started),
    mergeMap(action => {
      return deps.api.linkPaysera(action.payload).pipe(
        map(result => {
          return linkPaysera.done({ params: action.payload, result });
        }),
        catchAsyncError(error =>
          linkPaysera.failed({
            error: parseServerError(error),
            params: action.payload
          })
        )
      );
    })
  );

const linkSuccessEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkPaysera.done),
    map(() => push('/accounts'))
  );

export const payseraEpic = combineEpics(linkPayseraEpic, linkSuccessEpic);
