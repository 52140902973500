export const formatSimple = (value: number) => {
  const date = new Date(value);

  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const year = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
    date
  );

  return [day, month, year].join('.');
};

export const formatTime = (value: number) => {
  const date = new Date(value);

  return new Intl.DateTimeFormat('en', {
    hour12: false,
    hour: 'numeric',
    minute: '2-digit'
  }).format(date);
};

export const formatDateTime = (value: number) => {
  return [formatSimple(value), formatTime(value)].join(' ');
};
