import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';
import { createDomainAction } from '@tsp/core/features/dependencies';

import { GetDetailsRequest, GetDetailsResponse } from './interface';

const createAction = actionCreatorFactory('DETAILS');

export const loadingAction = createAsyncAction<
  GetDetailsRequest,
  GetDetailsResponse
>(createAction);

export const domainAction = createDomainAction(createAction);
