import { userApi } from './features/user';
import { idBankApi } from './features/idBank';
import { payoutApi } from './features/payout';
import { revolutApi } from './features/revolut';
import { detailsApi } from './features/details';
import { payseraApi } from './features/paysera';
import { bank131Api } from './features/bank131';
import { settingsApi } from './features/settings';
import { invoicesApi } from './features/invoices';
import { overviewApi } from './features/overview';
import { unlimintApi } from './features/unlimint';
import { externalApi } from './features/external';
import { workItemApi } from './features/workItems';
import { contractsApi } from './features/contracts';
import { konsoleProApi } from './features/konsolePro';
import { restaffApi } from './features/restaff';
import { customWorksApi } from './features/customWorks';
import { citiBankSEPAApi } from './features/сitiBankSEPA';
import { userAccountsApi } from './features/userAccounts';
import { citiBankPayPalApi } from './features/сitiBankPayPal';
import { customPaymentsApi } from './features/customPayments';
import { contractorAccountsApi } from './features/contractorAccounts';
import { departmentsApi } from './features/departments';

export default {
  ...userApi,
  ...idBankApi,
  ...payoutApi,
  ...bank131Api,
  ...revolutApi,
  ...detailsApi,
  ...payseraApi,
  ...overviewApi,
  ...invoicesApi,
  ...workItemApi,
  ...settingsApi,
  ...unlimintApi,
  ...externalApi,
  ...contractsApi,
  ...konsoleProApi,
  ...restaffApi,
  ...customWorksApi,
  ...citiBankSEPAApi,
  ...userAccountsApi,
  ...citiBankPayPalApi,
  ...customPaymentsApi,
  ...contractorAccountsApi,
  ...departmentsApi
};
