import { AnyAction } from 'typescript-fsa';

import { withRecoveryReducer } from '@tsp/recovery';
import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import {
  domainAction,
  presetsActions,
  updateCustomPayment,
  createCustomPayment,
  loadCustomPaymentsAsync
} from './actions';
import { CustomPaymentsState, CustomPaymentsRecoveryState } from './interface';

const initialState: CustomPaymentsState = {
  data: [],
  total: 0,
  offset: 0,
  presets: {},
  totalSum: {},
  status: EntityStatus.initial,
  form: {
    status: EntityStatus.initial
  }
};

const reducer = (
  state = initialState,
  action: AnyAction
): CustomPaymentsState => {
  if (domainAction.reset.match(action)) {
    return { ...initialState, presets: state.presets };
  }

  if (loadCustomPaymentsAsync.done.match(action)) {
    const { offset, total, totalSum, items: data } = action.payload.result;
    return { ...state, offset, total, totalSum, data };
  }

  if (
    createCustomPayment.started.match(action) ||
    updateCustomPayment.started.match(action)
  ) {
    return {
      ...state,
      form: { ...state.form, error: undefined, status: EntityStatus.fetching }
    };
  }

  if (
    createCustomPayment.failed.match(action) ||
    updateCustomPayment.failed.match(action)
  ) {
    return {
      ...state,
      form: {
        ...state.form,
        error: action.payload.error,
        status: EntityStatus.error
      }
    };
  }

  if (
    createCustomPayment.done.match(action) ||
    updateCustomPayment.done.match(action)
  ) {
    return {
      ...state,
      form: { ...state.form, error: undefined, status: EntityStatus.fetched }
    };
  }

  if (presetsActions.push.match(action)) {
    const { id, preset } = action.payload;

    return {
      ...state,
      presets: { ...state.presets, [id]: preset }
    };
  }

  if (presetsActions.delete.match(action)) {
    const { id } = action.payload;
    const { [id]: _, ...presets } = state.presets;

    return { ...state, presets };
  }

  return state;
};

const recoveryDataSelector = (
  recoveryState?: CustomPaymentsRecoveryState
): CustomPaymentsState => {
  const presets = recoveryState?.customPayments?.presets || {};

  return {
    ...initialState,
    presets
  };
};

export const customPaymentsReducer = withRecoveryReducer(
  withStatusReducer(loadCustomPaymentsAsync, reducer),
  recoveryDataSelector
);
