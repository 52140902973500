import { map, mergeMap } from 'rxjs/operators';

import { createLoadingEpic } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { createPageInitEpic } from '@tsp/core/features/dependencies';

import {
  loadingAction as loadingContracts,
  domainAction as contractsDomainAction
} from '../contracts';
import {
  loadUserAccountsAsync,
  domainAction as userAccountsDomainAction
} from '../userAccounts';
import {
  loadContractorAccountsAsync,
  domainAction as contractorAccountsDomainAction
} from '../contractorAccounts';
import { pushNotification } from '../notifications';
import { loadSettingsOnce } from '../settings/actions';

import { OverviewEpicDependencies } from './interface';
import { selectDependenciesStatus } from './selectors';
import { loadingAction, domainAction } from './actions';

const loadingEpic = createLoadingEpic({
  action: loadingAction,
  selectFetcher: (deps: OverviewEpicDependencies) => deps.api.getIncomeOverview
});

const resetDependenciesEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.reset),
    mergeMap(() => [
      contractsDomainAction.reset(),
      userAccountsDomainAction.reset(),
      contractorAccountsDomainAction.reset()
    ])
  );

const dependenciesEpic = createPageInitEpic({
  domainAction,
  selectStatus: selectDependenciesStatus,
  dependencies: [
    loadSettingsOnce,
    loadingContracts,
    loadUserAccountsAsync,
    loadContractorAccountsAsync
  ]
});

const loadMainDataEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.success),
    map(() => loadingAction.started({}))
  );

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadingAction.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

export const overviewEpic = combineEpics(
  loadingEpic,
  loadMainDataEpic,
  dependenciesEpic,
  resetDependenciesEpic,
  errorNotificationEpic
);
