import { filter, mergeMap } from 'rxjs/operators';
import { AsyncActionCreators } from 'typescript-fsa';

import { EntityStatus } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';

import { DomainAction } from './interface';
import { ofActionsList } from './ofActionsList';

type Dependencies = Array<AsyncActionCreators<unknown, unknown>>;

interface Props {
  domainAction: DomainAction;
  dependencies: Dependencies;
  selectStatus: (state: unknown) => EntityStatus;
}

enum DomainStatus {
  Initial = 'initial',
  Processing = 'processing',
  Success = 'success',
  Error = 'error'
}

const extractActions = (dependencies: Dependencies) => {
  const done = dependencies.map(action => action.done);
  const failed = dependencies.map(action => action.failed);
  const started = dependencies.map(action => action.started);

  return { started, done, failed };
};

export const createPageInitEpic = (props: Props) => {
  let status = DomainStatus.Initial;
  const { domainAction, dependencies, selectStatus } = props;

  const { started, done, failed } = extractActions(dependencies);

  const startEpic: Epic = actions$ =>
    actions$.pipe(
      ofAction(domainAction.init),
      filter(() => status === DomainStatus.Initial),
      mergeMap(() => {
        status = DomainStatus.Processing;
        return started.map(action => action({}));
      })
    );

  const resetEpic: Epic = actions$ =>
    actions$.pipe(
      ofAction(domainAction.reset),
      mergeMap(() => {
        status = DomainStatus.Initial;
        return [];
      })
    );

  const dependenciesEpic: Epic = (actions$, state$) =>
    actions$.pipe(
      ofActionsList(done),
      filter(() => status === DomainStatus.Processing),
      mergeMap(() => {
        const domainStatus = selectStatus(state$.value);
        return domainStatus === EntityStatus.fetched
          ? [domainAction.success()]
          : [];
      })
    );

  const doneEpic: Epic = actions$ =>
    actions$.pipe(
      ofAction(domainAction.success),
      mergeMap(() => {
        status = DomainStatus.Success;
        return [];
      })
    );

  const failedEpic: Epic = actions$ =>
    actions$.pipe(
      ofActionsList(failed),
      mergeMap(() => {
        status = DomainStatus.Error;
        return [domainAction.error()];
      })
    );

  return combineEpics(
    doneEpic,
    startEpic,
    resetEpic,
    failedEpic,
    dependenciesEpic
  );
};
