export enum Currency {
  EUR = 1,
  RUB = 2,
  USD = 3,
  GBP = 4,
  HUF = 5,
  AMD = 6
}

export type TotalSumStorable = Partial<Record<Currency, number>>;
export type TotalSumStorableApi = Partial<Record<Currency, string>>;
