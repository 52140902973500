import { unixTimeToMilliseconds } from '@tsp/utils/time/convert';

import { GetUserPositionsStatusResponseItem } from './interface';

export const translateUserPositions = (
  records: GetUserPositionsStatusResponseItem[]
): GetUserPositionsStatusResponseItem[] =>
  records.map(record => ({
    ...record,
    periodFrom: unixTimeToMilliseconds(record.periodFrom),
    periodTo: record.periodTo ? unixTimeToMilliseconds(record.periodTo) : null
  }));
