import { map, mergeMap } from 'rxjs/operators';

import { createLoadingEpic } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { createPageInitEpic } from '@tsp/core/features/dependencies';

import { pushNotification } from '../notifications';
import { loadSettingsOnce } from '../settings/actions';
import { domainAction as detailsDomainAction } from '../details';

import { selectDependenciesStatus } from './selectors';
import { ContractsEpicDependencies } from './interface';
import { domainAction, loadingAction } from './actions';

const loadingEpic = createLoadingEpic({
  action: loadingAction,
  selectFetcher: (deps: ContractsEpicDependencies) => deps.api.getContracts
});

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadingAction.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

const resetDependenciesEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.reset),
    mergeMap(() => [detailsDomainAction.reset()])
  );

const dependenciesEpic = createPageInitEpic({
  domainAction,
  selectStatus: selectDependenciesStatus,
  dependencies: [loadSettingsOnce]
});

const loadMainDataEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.success),
    map(() => loadingAction.started({}))
  );

export const contractsEpic = combineEpics(
  loadingEpic,
  dependenciesEpic,
  loadMainDataEpic,
  errorNotificationEpic,
  resetDependenciesEpic
);
