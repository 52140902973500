import bigDecimal from 'js-big-decimal';

import { millisecondsToUnixTime } from '@tsp/utils/time/convert';
import { excludeUndefined } from '@tsp/utils/common/excludeUndefined';
import { createWithDefaultSorting } from '@tsp/utils/translators/createWithSorting';

import { rangeToDateRequest } from '../filters/utils/periodToDateRange';
import { InputType, InputValue } from '../components/FileInput/interface';
import { workTypeToFilterQuery } from '../filters/utils/workTypeToFilterQuery';
import { reduceAdditionalFilters } from '../filters/utils/reduceAdditionalFilters';

import { CustomPayment, FormData, ToolsFormValues } from './interface';

const DEFAULT_WORK_SUB_TYPE = '1';

export const translateFiltersToRequest = createWithDefaultSorting(
  (values: ToolsFormValues) => {
    const { search, pagination, period, ...additional } = values;

    const { dateFrom, dateTo } = rangeToDateRequest(period);

    const mainValues = {
      search,
      dateTo,
      dateFrom,
      limit: pagination.limit,
      page: pagination.page + 1
    };

    const additionalValues = reduceAdditionalFilters(
      additional,
      (key, value) => {
        if (key === 'workType') {
          return workTypeToFilterQuery(value as unknown as string);
        }

        return { [key]: value };
      }
    );

    return { ...mainValues, ...additionalValues };
  }
);

export const translateAttachments = (
  attachments: InputValue[] = [],
  wasFiles: boolean
) => {
  const files = attachments.reduce<File[]>((result, input) => {
    return input.type === InputType.File ? [...result, input.file] : result;
  }, []);

  const attachment = files.length ? files[0] : undefined;
  const deleteAttachment = attachments.length === 0 && wasFiles ? 1 : undefined;

  return { attachment, deleteAttachment };
};

export const mapFormDataToRequest = (
  data: FormData,
  customPayment?: CustomPayment
) => {
  const wasFiles = (customPayment?.attachment?.length || 0) > 0;

  const attachment = translateAttachments(data.attachment, wasFiles);
  const currentTS = millisecondsToUnixTime(new Date().getTime());

  return excludeUndefined({
    ...attachment,
    sign: data.sign,
    paidAt: currentTS,
    createdAt: currentTS,
    workType: data.workType,
    contractUuid: data.contract,
    departmentUuid: data.departmentUuid,
    description: data.description,
    workSubType: DEFAULT_WORK_SUB_TYPE,
    completedAt: millisecondsToUnixTime(data.completedAt),
    totalAmountStorable: Math.trunc(
      Number(bigDecimal.multiply(data.totalAmountStorable, 100))
    )
  });
};
