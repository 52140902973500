import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';
import { createDomainAction } from '@tsp/core/features/dependencies';

import { GetContractRequest, GetContractResponse } from './interface';

const createAction = actionCreatorFactory('CONTRACT');

export const loadingAction = createAsyncAction<
  GetContractRequest,
  GetContractResponse
>(createAction);

export const domainAction = createDomainAction(createAction);
