import { Recovery } from '@tsp/recovery';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const RECOVERY_KEY = 'recovery';

export const getRecovery = () => {
  const data = window.localStorage.getItem(RECOVERY_KEY);

  return data && JSON.parse(data);
};

export const setRecovery = (state: any = {}) => {
  const current = getRecovery() || {};

  return window.localStorage.setItem(
    RECOVERY_KEY,
    JSON.stringify({ ...current, ...state })
  );
};

export const recovery: Recovery = {
  getRecovery,
  setRecovery
};
