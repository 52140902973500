import { AjaxError } from 'rxjs/ajax';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const parseServerError = <Err extends any>(error: AjaxError): any => {
  const validation_messages: Err = error?.response?.validation_messages;

  if (validation_messages) {
    return Object.entries(validation_messages).reduce(
      (result, [key, error]) => {
        const value =
          typeof error === 'string' ? error : Object.values(error).join(', ');

        return { ...result, [key]: value };
      },
      {}
    );
  }

  const detail: string = error?.response?.detail;
  return detail || 'Unknown server error';
};
