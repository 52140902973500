import { ApiLineItem, DocumentAttachment } from '../interface';

// TODO: Remove TW later
export const selectAttachment = (
  lineItem: ApiLineItem
): DocumentAttachment[] => {
  const link = lineItem._embedded.workItem.content.teamwox_attachment_url;
  const fileName = lineItem._embedded.workItem.content.attachment_display_name;

  const item: DocumentAttachment | undefined = fileName
    ? { type: 'cdn', fileName }
    : link
    ? { type: 'tw', link }
    : undefined;

  return item ? [item] : [];
};
