import { Observable, of, OperatorFunction } from 'rxjs';
import { Action, AnyAction } from 'typescript-fsa';
import { catchError } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-explicit-any */

export function catchAsyncError<A>(
  handler: (error: any) => AnyAction
): OperatorFunction<AnyAction, Action<A>>;

export function catchAsyncError(handler: (error: any) => AnyAction) {
  return (actions$: Observable<AnyAction>) =>
    actions$.pipe(catchError(error => of(handler(error))));
}
