import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '../../features/layout';

const Content = loadable(() => import('./LegalContent'));

export const LegalPage = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  );
};
