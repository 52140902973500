import React, { ReactNode, FC } from 'react';
import { Theme } from '@mui/system';
import { Box } from '@tsp/tsp-frontend-components/mui';

import { Intersection } from '../../components/Intersection';

type WithChildrenFunction<P extends Object> = P & {
  children: (sticky: boolean) => ReactNode;
};

type HeaderProps = WithChildrenFunction<{
  header: FC;
  intersection: boolean;
}>;

type StickyHeaderProps = Omit<HeaderProps, 'intersection'>;

const classes = {
  root: {
    backgroundColor: '#F0F0F0'
  },
  item: (theme: Theme) => ({
    width: 1920,
    maxWidth: '100%',
    margin: '0 auto',
    padding: `0 ${theme.spacing(7)}`
  }),
  tools: (intersection: boolean) => (theme: Theme) => ({
    width: '100%',
    transition: '0.2s',
    marginBottom: theme.spacing(3),
    backgroundColor: intersection
      ? theme.palette.background.paper
      : 'transparent'
  })
};

export const Header: FC<HeaderProps> = ({
  intersection,
  children: renderChildren,
  header: HeaderComponent
}) => (
  <Box sx={classes.root}>
    <Box sx={classes.tools(intersection)}>
      <Box sx={classes.item}>{renderChildren(intersection)}</Box>
    </Box>
    <Box sx={classes.item}>
      <HeaderComponent />
    </Box>
  </Box>
);

export const StickyHeader: FC<StickyHeaderProps> = props => (
  <Intersection>
    {intersection => <Header intersection={intersection} {...props} />}
  </Intersection>
);
