import { saveAs } from 'file-saver';
import { debounceTime, map, mergeMap } from 'rxjs/operators';

import { createLoadingEpic } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { parseServerError } from '@tsp/api/utils/parseServerError';
import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { createPageInitEpic } from '@tsp/core/features/dependencies';
import { base64StringToBuffer } from '@tsp/utils/convert/base64StringToBuffer';

import {
  loadUserAccountsAsync,
  domainAction as userAccountsDomainAction
} from '../userAccounts';
import {
  loadContractorAccountsAsync,
  domainAction as contractorAccountsDomainAction
} from '../contractorAccounts';
import { pushNotification } from '../notifications';
import { loadSettingsOnce } from '../settings/actions';

import {
  downloadPdf,
  domainAction,
  reloadInvoices,
  loadInvoicesAsync
} from './actions';
import { toolsFormInitialValues } from './constants';
import { InvoicesEpicDependencies } from './interface';
import { selectDependenciesStatus } from './selectors';
import { translateFilterToRequest } from './translators';

const updateFilterEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(reloadInvoices),
    debounceTime(500),
    map(action => {
      const request = translateFilterToRequest(action.payload);
      return loadInvoicesAsync.started(request);
    })
  );

const loadingStartEpic = createLoadingEpic({
  action: loadInvoicesAsync,
  selectFetcher: (deps: InvoicesEpicDependencies) => deps.api.getInvoices
});

const downloadPdfEpic: Epic<unknown, InvoicesEpicDependencies> = (
  $actions,
  _,
  deps
) =>
  $actions.pipe(
    ofAction(downloadPdf.started),
    mergeMap(action => {
      return deps.api.downloadPdf(action.payload).pipe(
        mergeMap(response => {
          const buffer = base64StringToBuffer(response);
          const blob = new Blob([buffer], {
            type: 'application/pdf'
          });

          saveAs(blob, `invoice-${action.payload.certificateUuid}.pdf`);

          return [];
        }),
        catchAsyncError(error =>
          downloadPdf.failed({
            error: parseServerError(error),
            params: action.payload
          })
        )
      );
    })
  );

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadInvoicesAsync.failed, downloadPdf.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

const resetDependenciesEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.reset),
    mergeMap(() => [
      userAccountsDomainAction.reset(),
      contractorAccountsDomainAction.reset()
    ])
  );

const dependenciesEpic = createPageInitEpic({
  domainAction,
  selectStatus: selectDependenciesStatus,
  dependencies: [
    loadSettingsOnce,
    loadUserAccountsAsync,
    loadContractorAccountsAsync
  ]
});

const loadMainDataEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.success),
    map(() => reloadInvoices(toolsFormInitialValues))
  );

export const invoicesEpic = combineEpics(
  downloadPdfEpic,
  updateFilterEpic,
  loadingStartEpic,
  dependenciesEpic,
  loadMainDataEpic,
  resetDependenciesEpic,
  errorNotificationEpic
);
