import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import {
  ContractDetails,
  GetDetailsRequest,
  GetDetailsResponse,
  ApiContractsDetails
} from './interface';

const mapResponseToDetails = (
  response: ApiContractsDetails
): ContractDetails => {
  const firstContractData = response.overviews[0];

  return {
    contractId: firstContractData.contractUuid,
    previousIncome: firstContractData.lastIncome,
    totalEarnings: firstContractData.totalEarnings,
    periodEarnings: firstContractData.periodEarnings,
    workItems: {
      paid: firstContractData.paidWorkItemsCount,
      signed: firstContractData.signedWorkItemsCount,
      waiting: firstContractData.pendingWorkItemsCount
    }
  };
};

const getDetails = defineHandler<GetDetailsRequest, GetDetailsResponse>({
  path: '/getUserContractsOverview',
  method: HttpMethod.POST,
  params: ParamsContainer.Body,
  translator: mapResponseToDetails
});

export const detailsApi = {
  getDetails
};
