import React from 'react';

import loadable from '@loadable/component';
import { useDependenciesLoading } from '@tsp/core/features/dependencies';

import { Layout } from '../../features/layout';
import { Access } from '../../features/auth/components';
import { GetUserStatusResponse } from '../../features/user';
import { domainAction, selectDomainStatus } from '../../features/workItems';

const Content = loadable(() => import('./WorkItemsContent'));

const checkAccess = (user: GetUserStatusResponse) => user.authenticated;

export const WorkItemsPage = () => {
  const { loading, onStart } = useDependenciesLoading({
    domainAction,
    selectStatus: selectDomainStatus
  });

  return (
    <Access checkAccess={checkAccess} onAccess={onStart}>
      <Layout loading={loading}>
        <Content />
      </Layout>
    </Access>
  );
};
