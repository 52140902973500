import { push } from 'connected-react-router';
import { map, mergeMap } from 'rxjs/operators';

import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { parseServerError } from '@tsp/api/utils/parseServerError';
import { createLoadingEpic } from '@tsp/utils/asyncEntity';

import { pushNotification } from '../notifications';
import { loadUserAccountsAsync } from '../userAccounts';

import { PayoutEpicDependencies } from '../payout';

import { linkBank131, loadBank131ApiTokenAsync } from './actions';
import { Bank131EpicDependencies } from './interface';

const loadBank131ApiTokenEpic = createLoadingEpic({
  action: loadBank131ApiTokenAsync,
  selectFetcher: (deps: PayoutEpicDependencies) => deps.api.getLinkAccountUrl
});

const linkBank131Epic: Epic<unknown, Bank131EpicDependencies> = (
  actions$,
  _,
  deps
) =>
  actions$.pipe(
    ofAction(linkBank131.started),
    mergeMap(action => {
      return deps.api.linkBank131(action.payload).pipe(
        map(result => {
          return linkBank131.done({ params: action.payload, result });
        }),
        catchAsyncError(error =>
          linkBank131.failed({
            error: parseServerError(error),
            params: action.payload
          })
        )
      );
    })
  );

const linkSuccessEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkBank131.done),
    map(() => push('/accounts'))
  );

const reloadAccountEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkBank131.done),
    map(() => loadUserAccountsAsync.started({}))
  );

const errorNotificationsEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkBank131.failed),
    mergeMap(action => {
      return typeof action.payload.error === 'string'
        ? [
            pushNotification({
              type: 'error',
              message: action.payload.error
            })
          ]
        : [];
    })
  );

const successNotificationsEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(linkBank131.done),
    map(() =>
      pushNotification({
        type: 'success',
        message: 'Account was successfully linked.'
      })
    )
  );

export const bank131Epic = combineEpics(
  loadBank131ApiTokenEpic,
  linkSuccessEpic,
  linkBank131Epic,
  reloadAccountEpic,
  errorNotificationsEpic,
  successNotificationsEpic
);
