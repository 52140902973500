import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import { IconButton } from '@tsp/tsp-frontend-components/mui';

import { AppNotification } from '../interface';
import { deleteNotifications } from '../actions';

interface Props {
  notification: AppNotification;
}

export const Notification = ({ notification }: Props) => {
  const dispatch = useDispatch();

  const showAction =
    notification.manualClose === undefined ? true : notification.manualClose;

  const hideNotification = useCallback(() => {
    dispatch(deleteNotifications([notification]));
  }, [dispatch, notification]);

  const action = showAction ? (
    <IconButton size="small" color="inherit" onClick={hideNotification}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  ) : null;

  return (
    <Alert action={action} severity={notification.type}>
      {notification.message}
    </Alert>
  );
};
