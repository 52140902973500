import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { LinkUnlimintRequest, LinkUnlimintResponse } from './interface';

const linkUnlimint = defineHandler<LinkUnlimintRequest, LinkUnlimintResponse>({
  path: '/linkUnlimintAccount',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const unlimintApi = {
  linkUnlimint
};
