import { actionCreatorFactory } from 'typescript-fsa';

import {
  LinkRevolutError,
  LinkRevolutRequest,
  LinkRevolutResponse
} from './interface';

type Callback = (errors?: Object) => void;

const createAction = actionCreatorFactory('REVOLUT');

export const linkRevolut = createAction.async<
  { data: LinkRevolutRequest; callback: Callback },
  LinkRevolutResponse,
  LinkRevolutError
>('LINK');
