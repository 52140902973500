import format from 'date-fns/format';
import { createSelector } from 'reselect';

import { calculateStatus } from '@tsp/utils/asyncEntity/utils/calculateStatus';

import {
  selectHasPrimaryMethod,
  selectUserAccountsStatus
} from '../userAccounts';
import {
  selectContractsList,
  selectContractsStatus,
  selectUserActiveContractorsList
} from '../contracts';
import { selectSettingsStatus } from '../settings';
import { selectContractorAccountsStatus } from '../contractorAccounts';

import { OverviewStoreSegment } from './interface';

export const selectOverviewSegment = (state: OverviewStoreSegment) =>
  state.overview;

export const selectOverviewStatus = (state: OverviewStoreSegment) => {
  return state.overview.status;
};

export const selectLastTransfer = (state: OverviewStoreSegment) => {
  return state.overview?.data.lastPayoutTs;
};

export const selectOverviewContractEarnings = (state: OverviewStoreSegment) => {
  const segment = selectOverviewSegment(state);
  return segment.data?.contractEarnings || [];
};

export const selectOverviewPeriodEarnings = (state: OverviewStoreSegment) => {
  const segment = selectOverviewSegment(state);
  return segment.data?.periodEarnings || [];
};

export const selectOverviewContract = createSelector(
  selectContractsList,
  contracts => {
    const contract = contracts.find(contract => contract.active);

    return contract ? contract.id : undefined;
  }
);

export const selectOverviewContractor = createSelector(
  selectUserActiveContractorsList,
  list => (list[0] ? list[0].uuid : undefined)
);

export const selectShowWelcomeContent = createSelector(
  selectHasPrimaryMethod,
  selectOverviewContract,
  selectOverviewPeriodEarnings,
  (hasPrimaryAccount, overviewContract, periodEarnings) =>
    [!hasPrimaryAccount, !overviewContract, !periodEarnings.length].some(
      value => value
    )
);

export const selectLastTransferFormatted = createSelector(
  selectLastTransfer,
  lastTransfer => {
    return lastTransfer ? format(lastTransfer, 'MMMM dd, yyyy') : '';
  }
);

export const selectDependenciesStatus = createSelector(
  selectSettingsStatus,
  selectContractsStatus,
  selectUserAccountsStatus,
  selectContractorAccountsStatus,
  calculateStatus
);

export const selectDomainStatus = createSelector(
  selectDependenciesStatus,
  selectOverviewStatus,
  calculateStatus
);
