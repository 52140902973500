import { INITIAL_RANGE } from '../filters';
import { CellMeta } from '../components/Table';
import { calculateFiltersCount } from '../filters/utils/calculateFiltersCount';

import { ToolsFormAdditionalKeys, ToolsFormValues } from './interface';

export const tableConfig: Record<string, CellMeta> = {
  status: { width: '7%', sort: false },
  date: { width: '10%', sort: false },
  property: { width: '23%', sort: false },
  period: { width: '12%', sort: false },
  amount: { width: '8%', align: 'right', sort: false },
  description: { width: '30%' },
  control: { width: '10%', align: 'right' }
};

export const toolsFormInitialValues: ToolsFormValues = {
  pagination: {
    page: 0,
    limit: 50
  },
  search: '',
  period: INITIAL_RANGE
};

export const ADDITIONAL_FIELDS: ToolsFormAdditionalKeys[] = [
  'status',
  'workType',
  'brandUuid',
  'localeCode',
  'propertyUuid'
];

export const calculateCount = calculateFiltersCount(ADDITIONAL_FIELDS);
