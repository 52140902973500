import { AnyAction } from 'typescript-fsa';

import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import {
  domainAction,
  updateDefaultAccount,
  loadUserAccountsAsync
} from './actions';
import { DefaultAccounts, UserAccountsState } from './interface';

const initialState: UserAccountsState = {
  status: EntityStatus.initial
};

const reducer = (
  state = initialState,
  action: AnyAction
): UserAccountsState => {
  if (domainAction.reset.match(action)) {
    return { ...initialState };
  }

  if (loadUserAccountsAsync.done.match(action)) {
    return {
      ...state,
      data: action.payload.result
    };
  }

  if (updateDefaultAccount.match(action)) {
    const { currency, accountUid, contractorUid } = action.payload;

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const defaultAccounts = {
      ...state.data.defaultAccounts,
      [contractorUid]: {
        ...state.data.defaultAccounts[contractorUid],
        [currency]: accountUid
      }
    } as DefaultAccounts;

    return {
      ...state,
      data: {
        ...state.data,
        defaultAccounts
      }
    };
  }

  return state;
};

export const userAccountsReducer = withStatusReducer(
  loadUserAccountsAsync,
  reducer
);
