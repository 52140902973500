import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { AnyAction } from 'typescript-fsa';

import { OverviewState } from './interface';
import { loadingAction, domainAction } from './actions';

const initialState: OverviewState = {
  status: EntityStatus.initial
};

const reducer = (state = initialState, action: AnyAction): OverviewState => {
  if (domainAction.reset.match(action)) {
    return { ...initialState };
  }

  if (loadingAction.done.match(action)) {
    return { ...state, data: action.payload.result };
  }

  return state;
};

export const overviewReducer = withStatusReducer(loadingAction, reducer);
