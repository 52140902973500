import { ActionCreatorFactory } from 'typescript-fsa';

import { DomainAction } from './interface';

export const createDomainAction = (
  createAction: ActionCreatorFactory
): DomainAction => ({
  init: createAction('DOMAIN_INIT'),
  reset: createAction('DOMAIN_RESET'),
  error: createAction('DOMAIN_INIT_ERROR'),
  success: createAction('DOMAIN_INIT_SUCCESS')
});
