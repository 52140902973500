import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EntityStatus } from '@tsp/utils/asyncEntity';

import {
  selectDetailsStatus,
  loadingAction as loadDetails,
  domainAction as detailsDomainAction
} from '../../details';

import { selectContractData } from '../selectors';

export const useContractLoader = () => {
  const dispatch = useDispatch();
  const [uuid, setUuid] = useState<string | undefined>();

  const detailsStatus = useSelector(selectDetailsStatus);
  const selectedContract = useSelector(selectContractData(uuid));

  const contract = useMemo(() => {
    return uuid && selectedContract && detailsStatus === EntityStatus.fetched
      ? selectedContract
      : undefined;
  }, [detailsStatus, selectedContract, uuid]);

  useEffect(() => {
    dispatch(detailsDomainAction.reset());

    if (uuid) {
      dispatch(loadDetails.started({ contractUuids: [uuid] }));
    }
  }, [dispatch, uuid]);

  const clearDetails = useCallback(() => setUuid(undefined), []);
  const fetchDetails = useCallback((uuid: string) => setUuid(uuid), []);

  return { fetchDetails, clearDetails, contract };
};
