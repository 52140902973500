import { actionCreatorFactory } from 'typescript-fsa';

import {
  GetAsanaResponse,
  GetAsanaLinkRequest,
  GetAttachmentLinkRequest,
  GetAttachmentLinkResponse
} from './interface';

const createAction = actionCreatorFactory('EXTERNAL');

export const openAsana = createAction.async<
  GetAsanaLinkRequest,
  GetAsanaResponse
>('OPEN_ASANA');

export const openAttachment = createAction.async<
  GetAttachmentLinkRequest,
  GetAttachmentLinkResponse
>('ATTACHMENT');
