import * as Cookies from 'js-cookie';
import { AjaxRequest } from 'rxjs/ajax';

export const BEARER_COOKIE_NAME = 'token';

export const createBearerHeader = (token: string) => ({
  authorization: 'Bearer ' + token
});

export const bearerAuthMiddleware =
  () =>
  (request: AjaxRequest): AjaxRequest => {
    const token = Cookies.get(BEARER_COOKIE_NAME);

    const headers = token
      ? { ...request.headers, ...createBearerHeader(token) }
      : request.headers;

    return { ...request, headers };
  };
