import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import {
  DownloadPdfRequest,
  GetInvoicesRequest,
  DownloadPdfResponse,
  GetInvoicesResponse
} from './interface';
import { translateResponseToInvoices } from './translators';

const getInvoices = defineHandler<GetInvoicesRequest, GetInvoicesResponse>({
  path: '/get-invoices',
  method: HttpMethod.GET,
  params: ParamsContainer.Query,
  translator: translateResponseToInvoices
});

const downloadPdf = defineHandler<DownloadPdfRequest, DownloadPdfResponse>({
  responseType: 'text',
  method: HttpMethod.GET,
  path: '/get-invoice-pdf',
  params: ParamsContainer.Query
});

export const invoicesApi = {
  getInvoices,
  downloadPdf
};
