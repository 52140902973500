import { Handler } from '@tsp/api';
import { AsyncList } from '@tsp/utils/asyncEntity';
import { RecoveryStateSetter } from '@tsp/recovery';
import { WithSorting } from '@tsp/utils/translators/createWithSorting';

import { FilterPresetsList } from '../filters';
import { SortValue } from '../components/Table';
import { TotalSumStorableApi, TotalSumStorable } from '../money';
import { DocumentHistory, DocumentHistoryDates } from '../history';

export interface Embed {
  type: string;
  title: string;
  url?: string;
  reach?: string;
}

export interface CommunicationTask {
  id: string;
  permalink: string;
}

export interface UserLineItemView {
  uuid: string;
  embeds: Embed[];
  unitName: string;
  quantity: number;
  unitPriceStorable: number;
  fixedPriceStorable: number;
  totalAmountStorable: number;
  quantityDivider: number;
  contractUuid: string;
  contractorSignedByPartyName?: string;
  history: {
    createdAt: number;
    sentAt: number;
    signedAt: number;
    paidAt?: number;
    refund?: number;
  };
  _embedded: {
    workItem: {
      date: number;
      description: string;
      workType: string;
      locale: string;
      brandUuid: string;
      completedAt: number;
      propertyUuid: string;
      content: {
        teamwox_id?: number;
        teamwox_attachment_url?: string;
        attachment_display_name?: string;
      };
      entityUuid?: string;
      entityType?: string;
    };
    communicationTask?: CommunicationTask;
  };
}

export interface ApiLineItem {
  uuid: string;
  contractorSignedAt: number;
  contractorSignedByParty?: {
    active: boolean;
    entityType: string;
    entityUuid: string;
    name: string;
    uuid: string;
    verified: boolean;
  };
  createdAt: number;
  embeds: Embed[];
  fixedPriceStorable: number;
  modelId: string;
  partOfCertificate: boolean;
  quantity: number;
  quantityDivider: number;
  supplierSignedAt: number;
  timeSpent: number;
  totalAmountStorable: number;
  unitName: string;
  unitPriceStorable: number;
  _embedded: {
    communicationTask?: CommunicationTask;
    workItem: {
      date: number;
      description: string;
      workType: string;
      locale: string;
      brandUuid: string;
      propertyUuid: string;
      content: {
        teamwox_id?: number;
        teamwox_attachment_url?: string;
        attachment_display_name?: string;
      };
      entityUuid?: string;
      entityType?: string;
    };
    contract: {
      uuid: string;
      type: string;
    };
    creditNoteItem?: {
      createdAt: number;
      creditNote?: {
        createdAt: number;
      };
    };
    certificate?: {
      createdAt: number;
    };
  };
}

export interface LineItem {
  uuid: string;
  period: number;
  locale: string;
  embeds: Embed[];
  workType: string;
  unitName: string;
  quantity: number;
  unitPrice: number;
  fixedPrice: number;
  createdAt: number;
  brandUuid: string;
  teamwoxId?: number;
  completedAt?: number;
  entityUuid?: string;
  entityType?: string;
  supervisor?: string;
  description: string;
  totalAmount: number;
  contractUuid: string;
  propertyUuid: string;
  quantityDivider: number;
  communicationLink?: string;
  history: DocumentHistoryDates;
}

export interface LineItemTableView {
  uuid: string;
  brand: string;
  amount: string;
  locale: string;
  period: number;
  embeds: Embed[];
  quantity: string;
  workType: string;
  property: string;
  unitName: string;
  unitPrice: string;
  fixedPrice: string;
  teamwoxId?: number;
  highlight?: boolean;
  description: string;
  completedAt?: number;
  contractUuid: string;
  history: DocumentHistory;
  communicationLink?: string;
  communicationEntityType?: string;
  communicationEntityUuid?: string;
}

export type LineItemListRequest = WithSorting<{
  page: number;
  limit: number;
  search?: string;

  status?: string;

  brandUuid?: string[];
  localeCode?: string[];
  propertyUuid?: string[];

  'filter[1][value]'?: string;
  'filter[1][type]'?: string;
  'filter[0][type]'?: string;
  'filter[0][alias]'?: string;
  'filter[0][field]'?: string;
  'filter[1][alias]'?: string;
  'filter[1][field]'?: string;
}>;

export interface ApiLineItemListResponse {
  page_size: number;
  total_items: number;
  total_sum_storable: TotalSumStorableApi;
  _embedded: {
    certificate_line_item: ApiLineItem[];
  };
}

export interface UserLineItemViewListResponse {
  page_size: number;
  total_items: number;
  total_sum_storable: TotalSumStorableApi;
  _embedded: {
    certificate_line_item: UserLineItemView[];
  };
}

export interface LineItemListResponse {
  total: number;
  offset: number;
  items: LineItem[];
  totalSum: TotalSumStorable;
}

interface ToolsFormMainValues {
  pagination: {
    page: number;
    limit: number;
  };
  search: string;
  period: [number, number];
}

export interface ToolsFormAdditionalValues {
  status?: string;
  sort?: SortValue;
  workType?: string;
  brandUuid?: string[];
  localeCode?: string[];
  propertyUuid?: string[];
}

export type ToolsFormValues = ToolsFormMainValues & ToolsFormAdditionalValues;

export type ToolsFormAdditionalKeys = keyof ToolsFormAdditionalValues;

export type WorkItemsFilterPresets =
  FilterPresetsList<ToolsFormAdditionalValues>;

export type LineItemsState = AsyncList<LineItem> & {
  totalSum: TotalSumStorable;
  presets: WorkItemsFilterPresets;
};

export interface LineItemsStoreSegment {
  lineItems: LineItemsState;
}

export interface LineItemsRecoveryState {
  lineItems?: {
    presets?: WorkItemsFilterPresets;
  };
}

export interface LineItemsEpicDependencies {
  api: {
    getLineItems: Handler<LineItemListRequest, LineItemListResponse>;
  };
  setRecovery?: RecoveryStateSetter<LineItemsRecoveryState>;
}

export enum EmbedType {
  Text = 'text',
  Link = 'link',
  FbLink = 'fblink'
}

export interface AttachmentCDN {
  type: 'cdn';
  fileName: string;
}

export interface AttachmentTW {
  type: 'tw';
  link: string;
}

export type DocumentAttachment = AttachmentCDN | AttachmentTW;
