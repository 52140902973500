import { actionCreatorFactory } from 'typescript-fsa';

import { createDomainAction } from '@tsp/core/features/dependencies';

import {
  GetLinkAccountUrlResponse,
  SetDefaultAccountResponse
} from './interface';

const createAction = actionCreatorFactory('PAYOUT');

export const linkAccount = createAction.async<
  { contractorAccount: string },
  GetLinkAccountUrlResponse,
  string
>('link');

export const setDefaultAccount = createAction.async<
  { account: string; contractor: string },
  SetDefaultAccountResponse,
  string
>('setDefault');

export const domainAction = createDomainAction(createAction);
