import { AjaxRequest } from 'rxjs/ajax';

import { MiddlewareFactory, RequestFunctionFactoryOptions } from '../interface';

export const combineMiddlewares =
  (...factories: MiddlewareFactory[]) =>
  (options: RequestFunctionFactoryOptions) => {
    const middlewares = factories.map(factory => factory(options));

    return (request: AjaxRequest, params: Object) =>
      middlewares.reduce((result, middleware) => {
        return { ...result, ...middleware(result, params) };
      }, request);
  };
