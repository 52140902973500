import { useCallback, useState } from 'react';
import * as Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { selectRouterSegment } from '@tsp/core/features/connect-router';

import { GetUserStatusResponse, useStatus } from '../../user';

import { ACCESS_PAGE_COOKIE_NAME } from '../interface';

interface Props {
  checkAccess: (data: GetUserStatusResponse) => boolean;
}

// Логика предоставления доступа
export const useAccess = (props: Props) => {
  const dispatch = useDispatch();
  const { checkAccess } = props;
  const [access, setAccess] = useState(false);
  const {
    location: { pathname }
  } = useSelector(selectRouterSegment);

  const onComplete = useCallback(
    (data: GetUserStatusResponse) => {
      const access = checkAccess(data);

      if (access) {
        setAccess(access);
      } else {
        // TODO: Move to localStorage later
        Cookies.set(ACCESS_PAGE_COOKIE_NAME, pathname);
        dispatch(push('/auth'));
      }
    },
    [checkAccess, dispatch, pathname]
  );

  const loading = useStatus({ onComplete });

  return [loading, access];
};
