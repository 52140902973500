import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { LinkRestaffRequest, LinkRestaffResponse } from './interface';

const linkRestaff = defineHandler<LinkRestaffRequest, LinkRestaffResponse>({
  path: '/linkRestaffAccount',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const restaffApi = {
  linkRestaff
};
