import { UserAccount } from '../../userAccounts';
import { AccountStatus, SettingsSystemType } from '../../settings';

import { CardStatus } from '../interface';

export const getCardStatus = (
  account: UserAccount,
  systemTypes: SettingsSystemType[] = []
): CardStatus => {
  const systemType = systemTypes.find(
    systemType => systemType.id === account.systemType
  );

  if (!systemType.is_integrated) {
    return CardStatus.NotSupported;
  }

  const map: Record<AccountStatus, CardStatus> = {
    [AccountStatus.Manual]: CardStatus.Manual,
    [AccountStatus.Verified]: CardStatus.Verified,
    [AccountStatus.NotVerified]: CardStatus.NotVerified
  };

  return map[account.status];
};
