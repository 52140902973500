import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import {
  LinkCitiBankPayPalRequest,
  LinkCitiBankPayPalResponse
} from './interface';

const linkCitiBankPayPal = defineHandler<
  LinkCitiBankPayPalRequest,
  LinkCitiBankPayPalResponse
>({
  path: '/linkCitibankPaypalAccount',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const citiBankPayPalApi = {
  linkCitiBankPayPal
};
