import { AjaxRequest } from 'rxjs/ajax';

import { ParamsContainer, RequestFunctionFactoryOptions } from '../interface';

/* eslint-disable @typescript-eslint/no-explicit-any */

const processParameter = ([key, value]: [string, any]) => {
  if (Array.isArray(value)) {
    return value
      .reduce<string[]>((result, value) => {
        return [...result, `${key}[]=${value}`];
      }, [])
      .join('&');
  }

  return [key, value].join('=');
};

export const getQueryString = (params: Object) => {
  return Object.entries(params)
    .filter(([_, value]) => value !== undefined)
    .map(processParameter)
    .join('&');
};

export const propsToQuery =
  (options: RequestFunctionFactoryOptions) =>
  (request: AjaxRequest, params: Object): AjaxRequest => {
    if (options.params !== ParamsContainer.Query) {
      return request;
    }

    const query = getQueryString(params);
    const url = query ? `${request.url}?${query}` : request.url;

    return { ...request, url };
  };
