import { AnyAction } from 'typescript-fsa';

import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { fetchUser } from './actions';
import { UserState } from './interface';

const initialState: UserState = {
  status: EntityStatus.initial
};

const reducer = (state = initialState, action: AnyAction): UserState => {
  if (fetchUser.done.match(action)) {
    return { ...state, data: action.payload.result };
  }

  return state;
};

export const userReducer = withStatusReducer(fetchUser, reducer);
