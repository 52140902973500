import { merge, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { createLoadingEpic, EntityStatus } from '@tsp/utils/asyncEntity';

import { pushNotification } from '../notifications';

import { selectSettingsStatus } from './selectors';
import { loadSettingsAsync, loadSettingsOnce } from './actions';
import { SettingsEpicDependencies, SettingsStoreSegment } from './interface';

const loadingEpic = createLoadingEpic({
  action: loadSettingsAsync,
  selectFetcher: (deps: SettingsEpicDependencies) => deps.api.getSettings
});

const loadOnceEpic: Epic<SettingsStoreSegment> = (actions$, state$) =>
  actions$.pipe(
    ofAction(loadSettingsOnce.started),
    mergeMap(() => {
      const status = selectSettingsStatus(state$.value);
      const doneAction = loadSettingsOnce.done({ result: {}, params: {} });

      const asyncStartActions$ = of(loadSettingsAsync.started({}));
      const doneActions$ = actions$.pipe(
        ofAction(loadSettingsAsync.done),
        map(() => doneAction)
      );

      return status === EntityStatus.initial
        ? merge(asyncStartActions$, doneActions$)
        : of(doneAction);
    })
  );

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadSettingsAsync.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

export const settingsEpic = combineEpics(
  loadingEpic,
  loadOnceEpic,
  errorNotificationEpic
);
