import { unixTimeToMilliseconds } from '@tsp/utils/time/convert';
import { protectUndefined } from '@tsp/utils/common/protectUndefined';

import { DocumentHistoryDates } from '../../history';

import { ApiLineItem, UserLineItemView } from '../interface';

const convertTS = protectUndefined(unixTimeToMilliseconds);

export const selectHistory = (lineItem: ApiLineItem): DocumentHistoryDates => {
  return {
    createdAt: convertTS(lineItem.createdAt),
    sentAt: convertTS(lineItem.supplierSignedAt),
    signedAt: convertTS(lineItem.contractorSignedAt),
    paidAt: convertTS(lineItem._embedded.certificate?.createdAt),
    refund: convertTS(lineItem._embedded.creditNoteItem?.creditNote?.createdAt)
  };
};

export const selectHistoryFromView = (
  lineItemView: UserLineItemView
): DocumentHistoryDates => {
  return {
    createdAt: convertTS(lineItemView.history.createdAt),
    sentAt: convertTS(lineItemView.history.sentAt),
    signedAt: convertTS(lineItemView.history.signedAt),
    paidAt: convertTS(lineItemView.history.paidAt),
    refund: convertTS(lineItemView.history.refund)
  };
};
