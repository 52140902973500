import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { linkRevolut } from './actions';
import { RevolutState } from './interface';

const initialState: RevolutState = {
  status: EntityStatus.initial
};

export const reducer = (state = initialState) => state;

export const revolutReducer = withStatusReducer(linkRevolut, reducer);
