import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { linkCitiBankSEPA } from './actions';
import { CitiBankSEPAState } from './interface';

const initialState: CitiBankSEPAState = {
  status: EntityStatus.initial
};

export const reducer = (state = initialState) => state;

export const citiBankSEPAReducer = withStatusReducer(linkCitiBankSEPA, reducer);
