import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';
import { createDomainAction } from '@tsp/core/features/dependencies';

import { GetOverviewRequest, GetOverviewResponse } from './interface';

const createAction = actionCreatorFactory('OVERVIEW');

export const loadingAction = createAsyncAction<
  GetOverviewRequest,
  GetOverviewResponse
>(createAction);

export const domainAction = createDomainAction(createAction);
