interface FormatterOptions {
  prefix?: string;
  postfix?: string;
  placeholder?: string;
}

export const createFormatter =
  <V>(format: (value: V) => string, options: FormatterOptions = {}) =>
  (value?: V) => {
    const { prefix, postfix, placeholder } = options;

    if (value !== undefined) {
      const formattedValue = format(value);
      return `${prefix || ''}${formattedValue}${postfix || ''}`;
    }

    return placeholder || '';
  };
