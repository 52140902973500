import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';
import { createDomainAction } from '@tsp/core/features/dependencies';

import { createFilterPresetsActions } from '../filters';

import {
  FormData,
  ToolsFormValues,
  GetCustomWorksRequest,
  GetCustomWorksResponse,
  DeleteCustomWorkResponse,
  CreateCustomWorkResponse,
  UpdateCustomWorkResponse,
  ToolsFormAdditionalValues,
  GetUserRatesRequest,
  GetUserRatesResponse
} from './interface';

const createAction = actionCreatorFactory('CUSTOM_WORKS');

type Callback = (errors?: Object) => void;

export const createCustomWork = createAction.async<
  { data: FormData; callback: Callback },
  CreateCustomWorkResponse,
  string
>('CREATE');

export const updateCustomWork = createAction.async<
  { data: FormData; workId: string; callback: Callback },
  UpdateCustomWorkResponse,
  string
>('UPDATE');

export const loadCustomWorksAsync = createAsyncAction<
  GetCustomWorksRequest,
  GetCustomWorksResponse
>(createAction);

export const deleteCustomWork = createAction.async<
  { workId: string; callback: VoidFunction },
  DeleteCustomWorkResponse,
  string
>('DELETE');

export const getUserRatesAction = createAction.async<
  {
    request: GetUserRatesRequest;
    callback: (response: GetUserRatesResponse) => void;
  },
  GetUserRatesResponse
>('FETCH_USER_RATES');

export const reloadCustomWorks = createAction<ToolsFormValues>('RELOAD');

export const presetsActions =
  createFilterPresetsActions<ToolsFormAdditionalValues>(createAction);

export const domainAction = createDomainAction(createAction);
