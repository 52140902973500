import { AnyAction } from 'typescript-fsa';

import {
  AsyncStatus,
  EntityStatus,
  AsyncReducer,
  StatusAction
} from './interface';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const withStatusReducer = <
  S extends AsyncStatus,
  Req = any,
  Res = any,
  Err = any
>(
  statusAction: StatusAction<Req, Res, Err>,
  reducer: AsyncReducer<S>
) => {
  return (state: S, action: AnyAction) => {
    if (statusAction.started.match(action)) {
      return reducer(
        { ...state, status: EntityStatus.fetching, error: undefined },
        action
      );
    }

    if (statusAction.done.match(action)) {
      return reducer(
        { ...state, status: EntityStatus.fetched, error: undefined },
        action
      );
    }

    if (statusAction.failed.match(action)) {
      return reducer(
        { ...state, error: action.payload.error, status: EntityStatus.error },
        action
      );
    }

    return reducer(state, action);
  };
};
