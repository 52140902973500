import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';
import { createDomainAction } from '@tsp/core/features/dependencies';

import { Currency } from '../money';

import { GetUserAccountsRequest, GetUserAccountsResponse } from './interface';

interface UpdateDefaultAccountData {
  currency: Currency;
  accountUid: string;
  contractorUid: string;
}

const createAction = actionCreatorFactory('USER_ACCOUNTS');

export const loadUserAccountsAsync = createAsyncAction<
  GetUserAccountsRequest,
  GetUserAccountsResponse
>(createAction);

export const updateDefaultAccount =
  createAction<UpdateDefaultAccountData>('updateDefault');

export const domainAction = createDomainAction(createAction);
