import { AnyAction } from 'typescript-fsa';

import { withRecoveryReducer } from '@tsp/recovery';
import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { LineItemsState, LineItemsRecoveryState } from './interface';
import { presetsActions, loadLineItemsAsync, domainAction } from './actions';

const initialState: LineItemsState = {
  data: [],
  total: 0,
  offset: 0,
  presets: {},
  totalSum: {},
  status: EntityStatus.initial
};

const reducer = (state: LineItemsState, action: AnyAction): LineItemsState => {
  if (domainAction.reset.match(action)) {
    return { ...initialState, presets: state.presets };
  }

  if (loadLineItemsAsync.started.match(action)) {
    return { ...state, status: EntityStatus.fetching, error: undefined };
  }

  if (loadLineItemsAsync.done.match(action)) {
    const { offset, total, items: data, totalSum } = action.payload.result;
    return {
      ...state,
      data,
      total,
      offset,
      totalSum,
      status: EntityStatus.fetched
    };
  }

  if (loadLineItemsAsync.failed.match(action)) {
    return {
      ...state,
      status: EntityStatus.error,
      error: action.payload.error
    };
  }

  if (presetsActions.push.match(action)) {
    const { id, preset } = action.payload;

    return {
      ...state,
      presets: { ...state.presets, [id]: preset }
    };
  }

  if (presetsActions.delete.match(action)) {
    const { id } = action.payload;
    const { [id]: _, ...presets } = state.presets;

    return { ...state, presets };
  }

  return state;
};

const recoveryDataSelector = (
  recoveryState?: LineItemsRecoveryState
): LineItemsState => {
  const presets = recoveryState?.lineItems?.presets || {};

  return {
    ...initialState,
    presets
  };
};

export const lineItemsReducer = withRecoveryReducer(
  withStatusReducer(loadLineItemsAsync, reducer),
  recoveryDataSelector
);
