import { actionCreatorFactory } from 'typescript-fsa';

import {
  LinkIdBankError,
  LinkIdBankRequest,
  LinkIdBankResponse
} from './interface';

type Callback = (errors?: Object) => void;

const createAction = actionCreatorFactory('IDBANK');

export const linkIdBank = createAction.async<
  { data: LinkIdBankRequest; callback: Callback },
  LinkIdBankResponse,
  LinkIdBankError
>('LINK');
