import { AnyAction } from 'redux';

import { AppNotificationState } from './interface';
import { pushNotification, deleteNotifications } from './actions';

const initialState: AppNotificationState = [];

export const notificationsReducer = (
  state = initialState,
  action: AnyAction
): AppNotificationState => {
  if (pushNotification.match(action)) {
    const notification = {
      ...action.payload,
      timestamp: new Date().getTime()
    };

    return [...state, notification];
  }

  if (deleteNotifications.match(action)) {
    return state.filter(notification => !action.payload.includes(notification));
  }

  return state;
};
