import { combineEpics as _combineEpics } from 'redux-observable';

import { CombineEpics } from './interface';

/* eslint-disable @typescript-eslint/no-explicit-any */

export function combineEpics<
  Epics extends ((action: any, state: any, deps: any) => any)[]
>(...epics: Epics): CombineEpics<Epics> {
  return _combineEpics(...epics) as CombineEpics<Epics>;
}
