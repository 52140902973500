import { unixTimeToMilliseconds } from '@tsp/utils/time/convert';
import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { GetUserAccountsRequest, GetUserAccountsResponse } from './interface';

const translateResponseToUserAccounts = (
  response: GetUserAccountsResponse
): GetUserAccountsResponse => ({
  ...response,
  nextPayoutTs: unixTimeToMilliseconds(response.nextPayoutTs)
});

const getUserAccounts = defineHandler<
  GetUserAccountsRequest,
  GetUserAccountsResponse
>({
  path: '/getUserAccounts',
  method: HttpMethod.GET,
  params: ParamsContainer.Query,
  translator: translateResponseToUserAccounts
});

export const userAccountsApi = {
  getUserAccounts
};
