import bigDecimal from 'js-big-decimal';

import { millisecondsToUnixTime } from '@tsp/utils/time/convert';
import { excludeUndefined } from '@tsp/utils/common/excludeUndefined';
import { createWithDefaultSorting } from '@tsp/utils/translators/createWithSorting';

import { Condition } from '../contracts';
import { rangeToDateRequest } from '../filters/utils/periodToDateRange';
import { InputType, InputValue } from '../components/FileInput/interface';
import { workTypeToFilterQuery } from '../filters/utils/workTypeToFilterQuery';
import { reduceAdditionalFilters } from '../filters/utils/reduceAdditionalFilters';

import {
  FormData,
  CustomWork,
  ToolsFormValues,
  CreateCustomWorkRequest,
  UpdateCustomWorkRequest
} from './interface';

export const translateFiltersToRequest = createWithDefaultSorting(
  (values: ToolsFormValues) => {
    const { search, pagination, period, ...additional } = values;

    const { dateFrom, dateTo } = rangeToDateRequest(period);

    const mainValues = {
      search,
      dateTo,
      dateFrom,
      limit: pagination.limit,
      page: pagination.page + 1
    };

    const additionalValues = reduceAdditionalFilters(
      additional,
      (key, value) => {
        if (key === 'workType') {
          return workTypeToFilterQuery(value as unknown as string);
        }

        return { [key]: value };
      }
    );

    return { ...mainValues, ...additionalValues };
  }
);

export const translateAttachments = (
  attachments: InputValue[] = [],
  wasFiles: boolean
) => {
  const files = attachments.reduce<File[]>((result, input) => {
    return input.type === InputType.File ? [...result, input.file] : result;
  }, []);

  const attachment = files.length ? files[0] : undefined;
  const deleteAttachment = attachments.length === 0 && wasFiles ? 1 : 0;

  return { attachment, deleteAttachment };
};

export const mapFormDataToCreateRequest = (
  formData: FormData
): CreateCustomWorkRequest => {
  const currentTs = millisecondsToUnixTime(new Date().getTime());
  const attachment = translateAttachments(formData.attachment, false);
  const {
    data: { workType, workSubType }
  } = formData.condition;

  return excludeUndefined({
    ...attachment,
    workSubType,
    paidAt: currentTs,
    createdAt: currentTs,
    workType: String(workType),
    workTypeBrand: formData.brand,
    contractUuid: formData.contract,
    workTypeLocale: formData.locale,
    description: formData.description,
    workTypeProperty: formData.property,
    completedAt: millisecondsToUnixTime(formData.completedAt),
    totalAmountStorable: Math.trunc(
      Number(bigDecimal.multiply(formData.totalAmountStorable, 100))
    ),
    property: formData.conditionProperty || formData.property
  });
};

export const mapFormDataToUpdateRequest = (
  formData: FormData,
  condition: Condition,
  customWork: CustomWork
): UpdateCustomWorkRequest => {
  const wasFiles = customWork.attachment.length > 0;
  const { workType, workSubtype: workSubType } = condition;
  const currentTs = millisecondsToUnixTime(new Date().getTime());
  const createdAt = millisecondsToUnixTime(customWork.history.createdAt);
  const attachment = translateAttachments(formData.attachment, wasFiles);

  return excludeUndefined({
    ...attachment,
    createdAt,
    version: 1,
    workSubType,
    paidAt: currentTs,
    workType: String(workType),
    contractUuid: formData.contract,
    description: formData.description,
    completedAt: millisecondsToUnixTime(formData.completedAt),
    totalAmountStorable: Math.trunc(
      Number(bigDecimal.multiply(formData.totalAmountStorable, 100))
    ),
    property: formData.conditionProperty || formData.property
  });
};
