import { EntityStatus } from '../interface';

export const calculateStatus = (...statuses: EntityStatus[]): EntityStatus => {
  const hasError = statuses.some(status => status === EntityStatus.error);

  if (hasError) {
    return EntityStatus.error;
  }

  const hasLoading = statuses.some(status => status === EntityStatus.fetching);

  if (hasLoading) {
    return EntityStatus.fetching;
  }

  const isLoaded = statuses.every(status => status === EntityStatus.fetched);

  if (isLoaded) {
    return EntityStatus.fetched;
  }

  const isInitial = statuses.every(status => status === EntityStatus.initial);

  if (isInitial) {
    return EntityStatus.initial;
  }

  return EntityStatus.fetching;
};
