import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { GetUserStatusRequest, GetUserStatusResponse } from './interface';

export const getUserStatus = defineHandler<
  GetUserStatusRequest,
  GetUserStatusResponse
>({
  path: '/getUserStatus',
  method: HttpMethod.POST,
  params: ParamsContainer.Query
});

export const refreshToken = defineHandler({
  // TODO: Replace to refreshToken endpoint later
  path: '/getUserStatus',
  method: HttpMethod.GET,
  params: ParamsContainer.Query
});

export const getUserPositions = defineHandler({
  path: '/getUserPositions',
  method: HttpMethod.GET
});

export const userApi = {
  refreshToken,
  getUserStatus,
  getUserPositions
};
