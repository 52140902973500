import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '../../features/layout';

const Content = loadable(() => import('./NotFoundContent'), {
  fallback: <div>Loading...</div>
});

export const NotFoundPage = () => (
  <Layout>
    <Content />
  </Layout>
);
