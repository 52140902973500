import { AnyAction } from 'typescript-fsa';

import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { InvoicesState } from './interface';
import { loadInvoicesAsync, domainAction } from './actions';

const initialState: InvoicesState = {
  data: [],
  total: 0,
  offset: 0,
  totalSum: {},
  status: EntityStatus.initial
};

const reducer = (state = initialState, action: AnyAction): InvoicesState => {
  if (domainAction.reset.match(action)) {
    return { ...initialState };
  }

  if (loadInvoicesAsync.done.match(action)) {
    const { offset, total, totalSum, items: data } = action.payload.result;
    return { ...state, offset, total, data, totalSum };
  }

  return state;
};

export const invoicesReducer = withStatusReducer(loadInvoicesAsync, reducer);
