import { AnyAction } from 'typescript-fsa';
import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { KonsoleProState } from './interface';
import { linkKonsolePro, resetKonsolePro } from './actions';

const initialState: KonsoleProState = {
  status: EntityStatus.initial
};

export const reducer = (state = initialState, action: AnyAction) => {
  if (linkKonsolePro.done.match(action)) {
    return { ...state, data: action.payload.result.registration_url };
  }

  if (resetKonsolePro.match(action)) {
    return { ...initialState };
  }

  return state;
};

export const konsoleProReducer = withStatusReducer(linkKonsolePro, reducer);
