import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import {
  SetDefaultAccountRequest,
  GetLinkAccountUrlRequest,
  SetDefaultAccountResponse,
  GetLinkAccountUrlResponse
} from './interface';

const getLinkAccountUrl = defineHandler<
  GetLinkAccountUrlRequest,
  GetLinkAccountUrlResponse
>({
  path: '/get-link-account-url',
  method: HttpMethod.GET,
  params: ParamsContainer.Query
});

const setDefaultAccount = defineHandler<
  SetDefaultAccountRequest,
  SetDefaultAccountResponse
>({
  path: '/save-default-accounts',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const payoutApi = {
  getLinkAccountUrl,
  setDefaultAccount
};
