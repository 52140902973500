import { AnyAction } from 'redux';

import { Reducer, DataSelector } from '../interface';

export const withRecoveryReducer =
  <S extends {}, R extends {}>(
    reducer: Reducer<S>,
    getInitialState: DataSelector<S>
  ) =>
  (recoveryState?: R) => {
    const initialState = getInitialState(recoveryState);

    return (state = initialState, action: AnyAction) => reducer(state, action);
  };
