import { interval } from 'rxjs';
import { mergeMap, first } from 'rxjs/operators';

import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';

import { selectNotifications } from './selectors';
import { pushNotification, deleteNotifications } from './actions';

const NOTIFICATION_LIVE_TIME = 15000;

const clearOutdatedEpic: Epic = (actions$, state$) =>
  actions$.pipe(
    ofAction(pushNotification),
    first(),
    mergeMap(() =>
      interval(1000).pipe(
        mergeMap(() => {
          const boundary = new Date().getTime() - NOTIFICATION_LIVE_TIME;
          const notifications = selectNotifications(state$.value);

          const deadpool = notifications.filter(
            notification => notification.timestamp <= boundary
          );

          return deadpool.length ? [deleteNotifications(deadpool)] : [];
        })
      )
    )
  );

export const notificationsEpic = combineEpics(clearOutdatedEpic);
