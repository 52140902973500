import { unixTimeToMilliseconds } from '@tsp/utils/time/convert';
import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { translateTotalSum } from '../money/translators';
import { selectHistory } from '../workItems/utils/selectHistory';
import { selectAttachment } from '../workItems/utils/selectAttachment';

import {
  CustomPayment,
  ApiCustomPayment,
  GetCustomPaymentsRequest,
  GetCustomPaymentsResponse,
  ApiCustomPaymentsResponse,
  CreateCustomPaymentRequest,
  UpdateCustomPaymentRequest,
  CreateCustomPaymentResponse,
  UpdateCustomPaymentResponse
} from './interface';

const mapApiCustomPaymentToCustomPayment = (
  payment: ApiCustomPayment
): CustomPayment => {
  const { lineItem, communicationTask } = payment._embedded;

  return {
    uuid: payment.uuid,
    workType: payment.workType,
    description: payment.description,
    departmentUuid: payment.departmentUuid,
    history: selectHistory(lineItem),
    amount: payment.totalAmountStorable,
    attachment: selectAttachment(lineItem),
    contract: payment._embedded.contract.uuid,
    communicationLink: communicationTask?.permalink,
    supervisor: lineItem?.contractorSignedByParty?.name,
    completedAt: unixTimeToMilliseconds(payment.completedAt)
  };
};

const translateResponseToCustomPayments = (
  response: ApiCustomPaymentsResponse,
  request: GetCustomPaymentsRequest
): GetCustomPaymentsResponse => {
  return {
    total: response.total_items,
    offset: (request.page - 1) * response.page_size,
    totalSum: translateTotalSum(response.totalSumStorable),
    items: response._embedded.custom_payment.map(
      mapApiCustomPaymentToCustomPayment
    )
  };
};

const getCustomPayments = defineHandler({
  method: HttpMethod.GET,
  path: '/custom-payment',
  params: ParamsContainer.Query,
  translator: translateResponseToCustomPayments
});

const createCustomPayment = defineHandler<
  CreateCustomPaymentRequest,
  CreateCustomPaymentResponse
>({
  method: HttpMethod.POST,
  path: '/custom-payment',
  params: ParamsContainer.Form
});

const updateCustomPayment = defineHandler<
  UpdateCustomPaymentRequest,
  UpdateCustomPaymentResponse
>({
  path: '/custom-payment',
  method: HttpMethod.PATCH,
  params: ParamsContainer.Form
});

const deleteCustomPayment = defineHandler({
  path: '/custom-payment',
  method: HttpMethod.DELETE
});

export const customPaymentsApi = {
  getCustomPayments,
  createCustomPayment,
  updateCustomPayment,
  deleteCustomPayment
};
