export interface SortingFields {
  'order-by[0][type]': string;
  'order-by[0][field]': string;
  'order-by[0][direction]': string;
}

export type WithSorting<Args extends {}> = Args & SortingFields;

const DEFAULT_SORTING_ARGS: SortingFields = {
  'order-by[0][type]': 'field',
  'order-by[0][direction]': 'desc',
  'order-by[0][field]': 'createdAt'
};

export const createWithDefaultSorting =
  <R extends {}, D extends {}>(translator: (data: D) => R) =>
  (data: D): WithSorting<R> => {
    const res = translator(data);
    return { ...res, ...DEFAULT_SORTING_ARGS };
  };
