export interface OtherProject {
  url: string;
  name: string;
}

export interface ProjectConfig {
  projectName: string;
  projects: OtherProject[];
}

export interface ConfigSegment {
  config: ProjectConfig;
}

export const config: ProjectConfig = {
  projectName: 'DocFlow',
  projects: [
    { name: 'Finder', url: '/' },
    { name: 'TheSoul', url: '/' },
    { name: 'Operator', url: '/' }
  ]
};
