import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { LinkPayseraRequest, LinkPayseraResponse } from './interface';

const linkPaysera = defineHandler<LinkPayseraRequest, LinkPayseraResponse>({
  path: '/create-paysera-account',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const payseraApi = {
  linkPaysera
};
