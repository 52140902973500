import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '../../features/layout';
import { Access } from '../../features/auth/components';
import { GetUserStatusResponse } from '../../features/user';
import { domainAction, selectDomainStatus } from '../../features/overview';
import { useDependenciesLoading } from '../../../core/features/dependencies';

const Content = loadable(() => import('./MainContent'));

const checkAccess = (user: GetUserStatusResponse) => user.authenticated;

export const MainPage = () => {
  const { loading, onStart } = useDependenciesLoading({
    domainAction,
    selectStatus: selectDomainStatus
  });

  return (
    <Access checkAccess={checkAccess} onAccess={onStart}>
      <Layout loading={loading}>
        <Content />
      </Layout>
    </Access>
  );
};
