import React from 'react';
import loadable from '@loadable/component';

import { useDependenciesLoading } from '@tsp/core/features/dependencies';

import { Layout } from '../../features/layout';
import { Access } from '../../features/auth/components';
import { GetUserStatusResponse } from '../../features/user';
import { domainAction } from '../../features/payout/actions';
import { selectDependenciesStatus } from '../../features/payout';

const Content = loadable(() => import('./PayoutMethodsContent'));

const checkAccess = (user: GetUserStatusResponse) => user.authenticated;

export const PayoutMethodsPage = () => {
  const { loading, onStart } = useDependenciesLoading({
    domainAction,
    selectStatus: selectDependenciesStatus
  });

  return (
    <Access checkAccess={checkAccess} onAccess={onStart}>
      <Layout loading={loading}>
        <Content />
      </Layout>
    </Access>
  );
};
