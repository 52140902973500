import React, { PropsWithChildren } from 'react';
import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import { Paper } from '@tsp/tsp-frontend-components/mui';

type Variant = 'content' | 'table';

type Props = PropsWithChildren<{
  variant: Variant;
  sx?: SystemStyleObject<Theme>;
}>;

const variantToPadding = (variant: Variant, values: (number | string)[]) => {
  switch (variant) {
    case 'table':
      return values[0];
    case 'content':
      return values[1];
    default:
      return 0;
  }
};

const variantToBorderRadius = (variant: Variant) => {
  if (variant === 'table') {
    return {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    };
  }

  return {};
};

export const Block = ({ variant, children, sx }: Props) => (
  <Paper
    elevation={0}
    sx={[
      (theme: Theme) => ({
        width: 1808,
        maxWidth: '100%',
        overflow: 'hidden',
        marginBottom: theme.spacing(3),
        padding: variantToPadding(variant, [0, theme.spacing(4)]),
        ...variantToBorderRadius(variant)
      }),
      sx
    ]}
  >
    {children}
  </Paper>
);
