import { actionCreatorFactory } from 'typescript-fsa';

import {
  LinkCitiBankError,
  LinkCitiBankSEPARequest,
  LinkCitiBankSEPAResponse
} from './interface';

type Callback = (errors?: Object) => void;

const createAction = actionCreatorFactory('CITI_BANK_SEPA');

export const linkCitiBankSEPA = createAction.async<
  { data: LinkCitiBankSEPARequest; callback: Callback },
  LinkCitiBankSEPAResponse,
  LinkCitiBankError
>('LINK');
