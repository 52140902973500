import { Handler } from '@tsp/api';
import { RecoveryStateSetter } from '@tsp/recovery';
import { AsyncList, AsyncStatus } from '@tsp/utils/asyncEntity';
import { WithSorting } from '@tsp/utils/translators/createWithSorting';

import {
  ApiLineItem,
  CommunicationTask,
  DocumentAttachment
} from '../workItems';
import { FilterPresetsList } from '../filters';
import { SortValue } from '../components/Table';
import { InputValue } from '../components/FileInput/interface';
import { DocumentHistoryDates, DocumentHistory } from '../history';
import { Currency, TotalSumStorable, TotalSumStorableApi } from '../money';

export const CUSTOM_WORKS_CONTRACT_TYPE = '200';

export interface ApiCustomWork {
  uuid: string;
  locale: string;
  paidAt: number;
  version: number;
  workType: string;
  brandUuid: string;
  createdAt: number;
  teamwoxId?: number;
  completedAt: number;
  description: string;
  workSubType: string;
  propertyUuid: string;
  totalAmountStorable: number;
  teamwoxAttachmentUrl: string | null;
  _embedded: {
    contract: {
      uuid: string;
    };
    lineItem?: ApiLineItem;
    communicationTask?: CommunicationTask;
  };
}

export interface ApiCustomWorksResponse {
  page: number;
  page_size: number;
  page_count: number;
  total_items: number;
  totalSumStorable: TotalSumStorableApi;
  _embedded: {
    custom_work: ApiCustomWork[];
  };
}

export interface CustomWork {
  uuid: string;
  brand: string;
  amount: number;
  locale: string;
  contract: string;
  property: string;
  workType: number;
  teamwoxId?: number;
  completedAt: number;
  supervisor?: string;
  workSubType: string;
  description?: string;
  communicationLink?: string;
  history: DocumentHistoryDates;
  attachment: DocumentAttachment[];
}

export type CustomWorkFormState = AsyncStatus;

interface ToolsFormMainValues {
  pagination: {
    page: number;
    limit: number;
  };
  search: string;
  period: [number, number];
}

export interface ToolsFormAdditionalValues {
  status?: string;
  sort?: SortValue;
  workType?: string;
  brandUuid?: string[];
  localeCode?: string[];
  propertyUuid?: string[];
}

export type ToolsFormValues = ToolsFormMainValues & ToolsFormAdditionalValues;

export type ToolsFormAdditionalKeys = keyof ToolsFormAdditionalValues;

type CustomWorksFilterPresets = FilterPresetsList<ToolsFormAdditionalValues>;

export type CustomWorksState = AsyncList<CustomWork> & {
  form: CustomWorkFormState;
  totalSum: TotalSumStorable;
  presets: CustomWorksFilterPresets;
};

export interface CustomWorksStoreSegment {
  customWorks: CustomWorksState;
}

export type GetCustomWorksRequest = WithSorting<{
  page: number;
  limit?: number;
  search?: string;

  status?: string;

  dateTo?: number;
  dateFrom?: number;

  brandUuid?: string[];
  localeCode?: string[];
  propertyUuid?: string[];

  'filter[0][type]'?: 'eq';
  'filter[0][value]'?: string;
  'filter[0][field]'?: 'workType';
}>;

export interface GetCustomWorksResponse {
  total: number;
  offset: number;
  items: CustomWork[];
  totalSum: TotalSumStorable;
}

export interface PostCustomWorkSignRequest {
  uuid: string;
}

export type PostCustomWorkSignResponse = unknown;

export interface DeleteCustomWorkRequest {}
export type DeleteCustomWorkResponse = unknown;

export interface CustomWorkTableView {
  uuid: string;
  amount: string;
  period: string;
  asanaId: string;
  property: string;
  createdAt: number;
  teamwoxId?: number;
  highlight?: boolean;
  description?: string;
  history: DocumentHistory;
  attachment: InputValue[];
  communicationLink?: string;
}

export interface CustomWorkDetailsView {
  brand: string;
  locale: string;
  period: string;
  amount: string;
  platform: string;
  contract: string;
  supervisor: string;
  description: string;
  attachment?: InputValue[];
}

export interface FormData {
  sign?: boolean;
  brand?: string;
  next?: boolean;
  locale?: string;
  contract: string;
  property?: string;
  completedAt: number;
  description: string;
  attachment?: InputValue[];
  conditionProperty?: string;
  totalAmountStorable: string;
  condition: { uuid: string; title: string; data: GetUserRatesResponseItem };
}

export interface CreateCustomWorkRequest {
  paidAt: number;
  property: string;
  workType: string;
  attachment?: File;
  createdAt: number;
  workSubType: string;
  description: string;
  completedAt: number;
  contractUuid: string;
  workTypeBrand?: string;
  workTypeLocale?: string;
  deleteAttachment: number;
  workTypeProperty?: string;
  totalAmountStorable: number;
}

export interface UpdateCustomWorkRequest {
  paidAt: number;
  version: number;
  workType: string;
  property: string;
  createdAt: number;
  attachment?: File;
  description: string;
  workSubType: string;
  completedAt: number;
  contractUuid: string;
  deleteAttachment: number;
  totalAmountStorable: number;
}

export type CreateCustomWorkResponse = ApiCustomWork;
export type UpdateCustomWorkResponse = ApiCustomWork;

export interface CustomWorkRecoveryState {
  customWorks?: {
    presets: CustomWorksFilterPresets;
  };
}

export interface GetUserRatesRequest {
  currencyId: Currency;
  contractType: number;
  periodTimestamp: number;
}

export interface GetUserRatesResponseItem {
  workType: number;
  workSubType: string;
  propertyUuid: string | null;
}

export type GetUserRatesResponse = GetUserRatesResponseItem[];

export interface CustomWorksEpicDependencies {
  api: {
    createCustomWork: Handler<
      CreateCustomWorkRequest,
      CreateCustomWorkResponse
    >;
    updateCustomWork: Handler<
      UpdateCustomWorkRequest,
      UpdateCustomWorkResponse
    >;
    signCustomWork: Handler<
      PostCustomWorkSignRequest,
      PostCustomWorkSignResponse
    >;
    deleteCustomWork: Handler<
      DeleteCustomWorkRequest,
      DeleteCustomWorkResponse
    >;
    getUserRates: Handler<GetUserRatesRequest, GetUserRatesResponse>;
    getCustomWorks: Handler<GetCustomWorksRequest, GetCustomWorksResponse>;
  };
  setRecovery?: RecoveryStateSetter<CustomWorkRecoveryState>;
}
