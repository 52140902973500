import React from 'react';
import loadable from '@loadable/component';

import { useDependenciesLoading } from '@tsp/core/features/dependencies';

import {
  domainAction,
  selectDomainStatus
} from '../../features/customPayments';
import { Layout } from '../../features/layout';
import { Access } from '../../features/auth/components';
import { GetUserStatusResponse } from '../../features/user';

const Content = loadable(() => import('./CustomPaymentsContent'));

const checkAccess = (user: GetUserStatusResponse) => user.authenticated;

export const CustomPaymentsPage = () => {
  const { loading, onStart } = useDependenciesLoading({
    domainAction,
    selectStatus: selectDomainStatus
  });

  return (
    <Access checkAccess={checkAccess} onAccess={onStart}>
      <Layout loading={loading}>
        <Content />
      </Layout>
    </Access>
  );
};
