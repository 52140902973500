import addMonths from 'date-fns/addMonths';
import startOfMonth from 'date-fns/startOfMonth';

const PERIODS_COUNT = 2;

const generatePeriods = (current: number) =>
  new Array(PERIODS_COUNT)
    .fill(undefined)
    .map((_, shift) => addMonths(current, -shift).getTime())
    .reverse();

export const FORM_PERIODS = generatePeriods(startOfMonth(new Date()).getTime());
