import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { LinkKonsoleProRequest, LinkKonsoleProResponse } from './interface';

const linkKonsolePro = defineHandler<
  LinkKonsoleProRequest,
  LinkKonsoleProResponse
>({
  path: '/linkKonsoleProAccount',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

export const konsoleProApi = {
  linkKonsolePro
};
