import { Handler } from '@tsp/api';
import { AsyncEntity } from '@tsp/utils/asyncEntity';

export enum AccountProvider {
  Internal = 1,
  Epayments = 2,
  WebMoney = 3,
  Card = 4,
  BankAccount = 5,
  Payoneer = 6,
  Paysera = 7,
  Ecommpay = 10,
  EcommpayCard = 11,
  Unlimint = 12,
  KonsolePro = 13,
  Bank131 = 14,
  Bank131Card = 15,
  IdBank = 16,
  Revolut = 17,
  Monetix = 18,
  MonetixCard = 19,
  CitiBankSEPA = 20,
  CitiBankPaypal = 21,
  Restaff = 25
}

export enum AccountStatus {
  NotVerified = 0,
  Verified = 1,
  Manual = 2
}

export interface SettingsBrandProperty {
  name: string;
  label: string;
  isActive: boolean;
}

export interface SettingsBrand {
  name: string;
  brand: string;
  label: string;
  properties: Record<string, SettingsBrandProperty>;
}

export interface SettingsProjectPortfolio {
  name: string;
  brands: Record<string, SettingsBrand>;
}

export interface SettingsWorkSubType {
  name: string;
}

export interface SettingsWorkType {
  name: string;
  type: number;
  work_sub_types: Record<string, SettingsWorkSubType>;
}

export type SettingsWorkTypes = Record<string, SettingsWorkType>;

export interface SettingsContractType {
  name: string;
  type: number;
  work_types: SettingsWorkTypes;
}

export interface AccrualModel {
  disabled: boolean;
  unit_name: string;
}

export interface SettingsSystemType {
  name: string;
  code: string;
  id: AccountProvider;
  is_integrated: boolean;
  type: 'internal' | 'external';
  allowed_transfer_types: Record<AccountProvider, AccountProvider>;
}

export interface Settings {
  systemTypes: SettingsSystemType[];
  accrualModels: Record<string, AccrualModel>;
  // customPaymentDepartments: Record<string, string>;
  contractTypes: Record<string, SettingsContractType>;
  projectsPortfolio: Record<string, SettingsProjectPortfolio>;
}

export type SettingsState = AsyncEntity<Settings>;

export interface SettingsStoreSegment {
  settings: SettingsState;
}

export interface GetSettingsRequest {}
export type GetSettingsResponse = Settings;

export interface SettingsEpicDependencies {
  api: {
    getSettings: Handler<GetSettingsRequest, GetSettingsResponse>;
  };
}
