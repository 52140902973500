import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';

import { GetSettingsRequest, GetSettingsResponse } from './interface';

const createAction = actionCreatorFactory('SETTINGS');

export const loadSettingsAsync = createAsyncAction<
  GetSettingsRequest,
  GetSettingsResponse
>(createAction);

export const loadSettingsOnce = createAction.async('LOAD_ONCE');
