import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { SxProps, Theme } from '@mui/system';
import { Box } from '@tsp/tsp-frontend-components/mui';

interface Props {
  sx?: SxProps<Theme>;
  children: (value: boolean) => ReactNode;
}

export const Intersection = ({ sx, children: renderChildren }: Props) => {
  const [value, setValue] = useState(false);
  const rootRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const rootElement = rootRef.current;

    const observer = new IntersectionObserver(
      ([e]) => setValue(e.intersectionRatio < 1),
      { threshold: [1] }
    );

    observer.observe(rootElement);

    return () => {
      observer.unobserve(rootElement);
    };
  }, []);

  return (
    <Box sx={sx} ref={rootRef}>
      {renderChildren(value)}
    </Box>
  );
};
