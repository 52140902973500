import { Observable, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { Epic, ofAction } from '@tsp/utils/epics';
import { parseServerError } from '@tsp/api/utils/parseServerError';

import { AsyncEntityAction } from './interface';

export type Fetcher<P, R> = (params: P) => Observable<R>;
export type FetcherFactory<D, P, R> = (deps: D) => Fetcher<P, R>;

type Options<S, D, P, R> = {
  action: AsyncEntityAction<P, R>;
  selectFetcher: FetcherFactory<D, P, R>;
};

export const createLoadingEpic = <S, D, P, R>(
  options: Options<S, D, P, R>
): Epic<S, D> => {
  return (actions$, state$, deps) => {
    return actions$.pipe(
      ofAction(options.action.started),
      mergeMap(action => {
        const fetch = options.selectFetcher(deps);

        return fetch(action.payload).pipe(
          map(response =>
            options.action.done({
              params: action.payload,
              result: response
            })
          ),
          catchError((error: AjaxError) => {
            console.error(error);
            return of(
              options.action.failed({
                error: parseServerError(error),
                params: action.payload
              })
            );
          })
        );
      })
    );
  };
};
