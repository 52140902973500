import { actionCreatorFactory } from 'typescript-fsa';

import {
  LinkUnlimintError,
  LinkUnlimintRequest,
  LinkUnlimintResponse
} from './interface';

type Callback = (errors?: Object) => void;

const createAction = actionCreatorFactory('UNLIMINT');

export const linkUnlimint = createAction.async<
  { data: LinkUnlimintRequest; callback: Callback },
  LinkUnlimintResponse,
  LinkUnlimintError
>('LINK');
