import { HttpMethod, defineHandler, ParamsContainer } from '@tsp/api';

import {
  Department,
  GetDepartmentsRequest,
  GetDepartmentsResponse
} from './interface';

export const getDepartments = defineHandler<
  GetDepartmentsRequest,
  GetDepartmentsResponse
>({
  path: '/getDepartments',
  method: HttpMethod.POST,
  params: ParamsContainer.Query,
  translator: data => {
    const departments: Array<Department> = data.departments.map(
      (department: Department): Department => {
        if (department.isArchived) {
          return {
            ...department,
            name: `${department.name} (Archived)`
          };
        }

        return department;
      }
    );

    return {
      departments: [
        ...departments.filter(department => !department.isArchived),
        ...departments.filter(department => department.isArchived)
      ]
    };
  }
});

export const departmentsApi = {
  getDepartments
};
