import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EntityStatus } from '@tsp/utils/asyncEntity';

import { fetchUser } from '../actions';
import { selectUserSegment } from '../selectors';
import { GetUserStatusResponse } from '../interface';

interface Props {
  onComplete?: (data: GetUserStatusResponse) => void;
}

// Логика загрузки статуса, если его еще нет
export const useStatus = (props: Props = {}) => {
  const dispatch = useDispatch();
  const { onComplete } = props;
  const { status, data: user } = useSelector(selectUserSegment);

  const isLoading =
    status === EntityStatus.initial || status === EntityStatus.fetching;

  useEffect(() => {
    if (status === EntityStatus.initial) {
      dispatch(fetchUser.started({}));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === EntityStatus.fetched && user && onComplete) {
      onComplete(user);
    }
  }, [onComplete, status, user]);

  return isLoading;
};
