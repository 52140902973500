import { createSelector } from 'reselect';

import { ContractorAccountsStoreSegment } from './interface';

export const selectContractorAccountsSegment = (
  state: ContractorAccountsStoreSegment
) => {
  return state.contractorAccounts;
};

export const selectContractorAccountsStatus = (
  state: ContractorAccountsStoreSegment
) => {
  return state.contractorAccounts.status;
};

export const selectContractorAccounts = (contractor: string) =>
  createSelector(selectContractorAccountsSegment, segment =>
    (segment.data || []).filter(account => account.ownerUuid === contractor)
  );
