import { merge, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { createLoadingEpic, EntityStatus } from '@tsp/utils/asyncEntity';

import { pushNotification } from '../notifications';

import { selectDepartmentsAsyncEntityStatus } from './selectors';
import { loadDepartmentsAction, loadDepartmentsOnceAction } from './actions';
import {
  DepartmentsEpicDependencies,
  DepartmentsStoreSegment
} from './interface';

const loadingEpic = createLoadingEpic({
  action: loadDepartmentsAction,
  selectFetcher: (deps: DepartmentsEpicDependencies) => deps.api.getDepartments
});

const loadOnceEpic: Epic<DepartmentsStoreSegment> = (actions$, state$) =>
  actions$.pipe(
    ofAction(loadDepartmentsOnceAction.started),
    mergeMap(() => {
      const status = selectDepartmentsAsyncEntityStatus(state$.value);
      const doneAction = loadDepartmentsOnceAction.done({
        result: {},
        params: {}
      });

      const asyncStartActions$ = of(loadDepartmentsAction.started({}));
      const doneActions$ = actions$.pipe(
        ofAction(loadDepartmentsAction.done),
        map(() => doneAction)
      );

      return status === EntityStatus.initial
        ? merge(asyncStartActions$, doneActions$)
        : of(doneAction);
    })
  );

const errorNotificationEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(loadDepartmentsAction.failed),
    map(action =>
      pushNotification({
        type: 'error',
        message: action.payload.error
      })
    )
  );

export const departmentsEpic = combineEpics(
  loadingEpic,
  loadOnceEpic,
  errorNotificationEpic
);
