import { map, mergeMap } from 'rxjs/operators';

import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { parseServerError } from '@tsp/api/utils/parseServerError';

import { EnvSegment, selectEnvValues } from '../env';
import { pushNotification } from '../notifications';

import { linkKonsolePro } from './actions';
import { KonsoleProEpicDependencies } from './interface';
import { translateFormDataToRequest } from './translators';

type State = EnvSegment;
type KonsoleProEpic = Epic<State, KonsoleProEpicDependencies>;

const linkKonsoleProEpic: KonsoleProEpic = (actions$, state$, deps) =>
  actions$.pipe(
    ofAction(linkKonsolePro.started),
    mergeMap(action => {
      const env = selectEnvValues(state$.value);
      const request = translateFormDataToRequest(action.payload.data, env);

      return deps.api.linkKonsolePro(request).pipe(
        map(result => {
          return linkKonsolePro.done({ params: action.payload, result });
        }),
        catchAsyncError(error =>
          linkKonsolePro.failed({
            error: parseServerError(error),
            params: action.payload
          })
        )
      );
    })
  );

const errorNotificationsEpic: KonsoleProEpic = actions$ =>
  actions$.pipe(
    ofAction(linkKonsolePro.failed),
    mergeMap(action => {
      return typeof action.payload.error === 'string'
        ? [
            pushNotification({
              type: 'error',
              message: action.payload.error
            })
          ]
        : [];
    })
  );

const successCallbackEpic: KonsoleProEpic = actions$ =>
  actions$.pipe(
    ofAction(linkKonsolePro.done),
    mergeMap(action => {
      action.payload.params.callback();
      return [];
    })
  );

const errorCallbackEpic: KonsoleProEpic = actions$ =>
  actions$.pipe(
    ofAction(linkKonsolePro.failed),
    mergeMap(action => {
      const errors: Object =
        typeof action.payload.error === 'string' ? {} : action.payload.error;

      action.payload.params.callback(errors);

      return [];
    })
  );

export const konsoleProEpic = combineEpics(
  errorCallbackEpic,
  linkKonsoleProEpic,
  successCallbackEpic,
  errorNotificationsEpic
);
