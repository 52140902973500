import { mergeMap, map } from 'rxjs/operators';
import { Epic, ofAction, combineEpics } from '@tsp/utils/epics';

import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { parseServerError } from '@tsp/api/utils/parseServerError';

import { openAsana, openAttachment } from './actions';
import { ExternalEpicDependencies } from './interface';

const asanaEpic: Epic<unknown, ExternalEpicDependencies> = (
  actions$,
  _,
  deps
) =>
  actions$.pipe(
    ofAction(openAsana.started),
    mergeMap(action =>
      deps.api.getCommunicationLink(action.payload).pipe(
        map(response =>
          openAsana.done({
            result: response,
            params: action.payload
          })
        ),
        catchAsyncError(error =>
          openAsana.failed({
            params: action.payload,
            error: parseServerError(error)
          })
        )
      )
    )
  );

const openAsanaLinkEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(openAsana.done),
    mergeMap(action => {
      window.location.href = action.payload.result.permalink;
      return [];
    })
  );

const attachmentEpic: Epic<unknown, ExternalEpicDependencies> = (
  actions$,
  _,
  deps
) =>
  actions$.pipe(
    ofAction(openAttachment.started),
    mergeMap(action =>
      deps.api.getAttachmentLink(action.payload).pipe(
        map(response =>
          openAttachment.done({
            result: response,
            params: action.payload
          })
        ),
        catchAsyncError(error =>
          openAttachment.failed({
            params: action.payload,
            error: parseServerError(error)
          })
        )
      )
    )
  );

const openAttachmentEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(openAttachment.done),
    mergeMap(action => {
      window.location.href = action.payload.result.temporaryLink;
      return [];
    })
  );

export const externalEpic = combineEpics(
  asanaEpic,
  attachmentEpic,
  openAsanaLinkEpic,
  openAttachmentEpic
);
