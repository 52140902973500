import { map } from 'rxjs/operators';
import { ajax, AjaxRequest } from 'rxjs/ajax';

import * as Cookies from 'js-cookie';

import { propsToBody } from './utils/propsToBody';
import { propsToForm } from './utils/propsToForm';
import { propsToQuery } from './utils/propsToQuery';
import { bearerAuthMiddleware } from './utils/bearerAuth';
import { combineMiddlewares } from './utils/combineMiddlewares';

import {
  Api,
  Basket,
  ApiConfig,
  Middleware,
  BlankMapObject,
  HandlerFactory,
  RequestFunctionFactoryOptions
} from './interface';
import { BEARER_COOKIE_NAME } from './utils/bearerAuth';

/* eslint-disable @typescript-eslint/no-explicit-any */

const defaultMutator: Middleware = req => req;

const defineBasket = <B extends BlankMapObject>(
  blank: B,
  config: ApiConfig
): Basket<B> => {
  return Object.entries(blank).reduce((result, [key, createHandler]) => {
    return { ...result, [key]: createHandler(config) };
  }, {} as unknown as Basket<B>);
};

export const defineApi = <B extends BlankMapObject>(
  blankBasket: B,
  config: ApiConfig
): Api<B> => {
  const setBearerToken = (token: string) => {
    return Cookies.set(BEARER_COOKIE_NAME, token);
  };

  const basket = defineBasket(blankBasket, config);

  return { ...basket, setBearerToken };
};

export const defineHandler =
  <Req = any, Res = any>(
    options: RequestFunctionFactoryOptions<Req, Res>
  ): HandlerFactory<Req, Res> =>
  (apiConfig: ApiConfig) =>
  (req: Req, mutator = defaultMutator) => {
    const { origin } = apiConfig;

    const originRequest: AjaxRequest = {
      method: options.method,
      url: origin + options.path,
      headers: options.headers || {},
      responseType: options.responseType || 'json'
    };

    const middleware = combineMiddlewares(
      propsToBody,
      propsToForm,
      propsToQuery,
      () => mutator,
      bearerAuthMiddleware
    );

    const request = middleware(options)(originRequest, req);

    return ajax(request).pipe(
      map(data =>
        options.translator
          ? options.translator(data.response, req)
          : (data.response as Res)
      )
    );
  };

export * from './interface';
