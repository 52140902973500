import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@tsp/tsp-frontend-components/mui';

import { selectSortedNotifications } from '../selectors';

import { Notification } from './Notification';

const classes = {
  root: {
    left: '50%',
    zIndex: 10000,
    position: 'fixed',
    transform: 'translateX(-50%)'
  } as const,
  notification: {
    marginTop: 2
  }
};

export const Notifications = () => {
  const notifications = useSelector(selectSortedNotifications);

  return (
    <Box sx={classes.root}>
      {notifications.map(notification => (
        <Box sx={classes.notification} key={notification.timestamp}>
          <Notification notification={notification} />
        </Box>
      ))}
    </Box>
  );
};
