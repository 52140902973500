import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { GetSettingsRequest, GetSettingsResponse } from './interface';

export const getSettings = defineHandler<
  GetSettingsRequest,
  GetSettingsResponse
>({
  path: '/get-settings',
  method: HttpMethod.GET,
  params: ParamsContainer.Query
});

export const settingsApi = {
  getSettings
};
