export const isEmptyValue = (value: unknown) => {
  if (value === undefined || value === null) {
    return true;
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }

  return false;
};
