import { unixTimeToMilliseconds } from '@tsp/utils/time/convert';
import { defineHandler, HttpMethod, ParamsContainer } from '@tsp/api';

import { translateTotalSum } from '../money/translators';
import { selectHistory } from '../workItems/utils/selectHistory';
import { selectAttachment } from '../workItems/utils/selectAttachment';

import {
  CustomWork,
  ApiCustomWork,
  GetCustomWorksRequest,
  GetCustomWorksResponse,
  ApiCustomWorksResponse,
  CreateCustomWorkRequest,
  CreateCustomWorkResponse,
  PostCustomWorkSignRequest,
  PostCustomWorkSignResponse
} from './interface';

const mapApiCustomWorkToCustomWork = (work: ApiCustomWork): CustomWork => {
  const { lineItem, communicationTask } = work._embedded;

  return {
    uuid: work.uuid,
    locale: work.locale,
    brand: work.brandUuid,
    teamwoxId: work.teamwoxId,
    property: work.propertyUuid,
    workSubType: work.workSubType,
    description: work.description,
    amount: work.totalAmountStorable,
    history: selectHistory(lineItem),
    attachment: selectAttachment(lineItem),
    contract: work._embedded.contract.uuid,
    workType: Number.parseInt(work.workType),
    communicationLink: communicationTask?.permalink,
    supervisor: lineItem?.contractorSignedByParty?.name,
    completedAt: unixTimeToMilliseconds(work.completedAt)
  };
};

const translateResponseToCustomWorks = (
  response: ApiCustomWorksResponse,
  request: GetCustomWorksRequest
): GetCustomWorksResponse => {
  return {
    total: response.total_items,
    offset: (request.page - 1) * response.page_size,
    totalSum: translateTotalSum(response.totalSumStorable),
    items: response._embedded.custom_work.map(mapApiCustomWorkToCustomWork)
  };
};

const getCustomWorks = defineHandler({
  path: '/custom-work',
  method: HttpMethod.GET,
  params: ParamsContainer.Query,
  translator: translateResponseToCustomWorks
});

const createCustomWork = defineHandler<
  CreateCustomWorkRequest,
  CreateCustomWorkResponse
>({
  path: '/custom-work',
  method: HttpMethod.POST,
  params: ParamsContainer.Form
});

const updateCustomWork = defineHandler<
  CreateCustomWorkRequest,
  CreateCustomWorkResponse
>({
  path: '/custom-work',
  method: HttpMethod.PATCH,
  params: ParamsContainer.Form
});

const signCustomWork = defineHandler<
  PostCustomWorkSignRequest,
  PostCustomWorkSignResponse
>({
  path: '/custom-work-supplier-sign',
  method: HttpMethod.POST,
  params: ParamsContainer.Body
});

const deleteCustomWork = defineHandler({
  path: '/custom-work',
  method: HttpMethod.DELETE
});

const getUserRates = defineHandler({
  path: '/filterUserRates',
  method: HttpMethod.POST
});

export const customWorksApi = {
  getUserRates,
  getCustomWorks,
  signCustomWork,
  deleteCustomWork,
  createCustomWork,
  updateCustomWork
};
