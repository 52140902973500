import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncAction } from '@tsp/utils/asyncEntity';
import { createDomainAction } from '@tsp/core/features/dependencies';

import {
  ToolsFormValues,
  DownloadPdfRequest,
  GetInvoicesRequest,
  DownloadPdfResponse,
  GetInvoicesResponse
} from './interface';

const createAction = actionCreatorFactory('INVOICES');

export const loadInvoicesAsync = createAsyncAction<
  GetInvoicesRequest,
  GetInvoicesResponse
>(createAction);

export const reloadInvoices = createAction<ToolsFormValues>('RELOAD');

export const downloadPdf = createAction.async<
  DownloadPdfRequest,
  DownloadPdfResponse,
  string
>('DOWNLOAD_PDF');

export const domainAction = createDomainAction(createAction);
