const DEFAULT_MAX_LENGTH = 16;

export const spliceCardNumber = (
  value: string,
  maxLength = DEFAULT_MAX_LENGTH,
  div = '...'
) => {
  if (value.length <= maxLength) {
    return value;
  }

  const pivot = value.length / 2;

  const excludeCount = value.length - maxLength + div.length;

  const firstBlockSize = Math.round(excludeCount / 2);
  const secondBlockSize = excludeCount - firstBlockSize;

  const firstIndex = pivot - firstBlockSize;
  const secondIndex = pivot + secondBlockSize;

  return [
    value.substr(0, firstIndex),
    value.substr(secondIndex, value.length - 1)
  ].join(div);
};
