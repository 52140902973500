import * as Cookies from 'js-cookie';

import { ENV_ENTRY_POINT, EnvSchema, RuntimeEnvironments } from './interface';

export const overloadEnv = (
  data: RuntimeEnvironments,
  overview: Partial<RuntimeEnvironments>
): RuntimeEnvironments => ({
  ...data,
  ...overview
});

export const loadFromCookies = () =>
  Object.keys(EnvSchema).reduce<Partial<RuntimeEnvironments>>(
    (result, schemaKey) => {
      const key = schemaKey.toLowerCase();
      const value = Cookies.get(key);

      return value ? { ...result, [schemaKey]: value } : result;
    },
    {}
  );

export const fetchEnvClient = () =>
  fetch(ENV_ENTRY_POINT)
    .then(response => response.json())
    .then(data => overloadEnv(data, loadFromCookies()));
