import { EntityStatus, withStatusReducer } from '@tsp/utils/asyncEntity';

import { linkPaysera } from './actions';
import { PayseraState } from './interface';

const initialState: PayseraState = {
  status: EntityStatus.initial
};

export const reducer = (state = initialState) => state;

export const payseraReducer = withStatusReducer(linkPaysera, reducer);
