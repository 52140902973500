import { actionCreatorFactory } from 'typescript-fsa';

import {
  FormData,
  LinkCitiBankError,
  LinkCitiBankPayPalResponse
} from './interface';

type Callback = (errors?: Object) => void;

const createAction = actionCreatorFactory('CITI_BANK_PAYPAL');

export const linkCitiBankPayPal = createAction.async<
  { data: FormData; callback: Callback },
  LinkCitiBankPayPalResponse,
  LinkCitiBankError
>('LINK');
