import { push } from 'connected-react-router';
import { debounceTime, map, mergeMap } from 'rxjs/operators';

import { createLoadingEpic } from '@tsp/utils/asyncEntity';
import { combineEpics, Epic, ofAction } from '@tsp/utils/epics';
import { parseServerError } from '@tsp/api/utils/parseServerError';
import { catchAsyncError } from '@tsp/utils/epics/catchAsyncError';
import { createPageInitEpic } from '@tsp/core/features/dependencies';

import {
  ContractsStoreSegment,
  selectContractCondition,
  loadingAction as loadingContracts,
  domainAction as contractsDomainAction
} from '../contracts';
import { pushNotification } from '../notifications';
import { loadSettingsOnce } from '../settings/actions';

import {
  domainAction,
  presetsActions,
  deleteCustomWork,
  updateCustomWork,
  createCustomWork,
  reloadCustomWorks,
  loadCustomWorksAsync,
  getUserRatesAction
} from './actions';
import {
  selectCustomWork,
  selectDependenciesStatus,
  selectCustomWorksFilterPresets
} from './selectors';
import {
  CustomWorksStoreSegment,
  CustomWorksEpicDependencies
} from './interface';
import {
  translateFiltersToRequest,
  mapFormDataToCreateRequest,
  mapFormDataToUpdateRequest
} from './translators';
import { toolsFormInitialValues } from './constants';

type State = ContractsStoreSegment & CustomWorksStoreSegment;

const updateFilterEpic: Epic = $actions =>
  $actions.pipe(
    ofAction(reloadCustomWorks),
    debounceTime(500),
    map(action => {
      const request = translateFiltersToRequest(action.payload);
      return loadCustomWorksAsync.started(request);
    })
  );

const loadingStartEpic = createLoadingEpic({
  action: loadCustomWorksAsync,
  selectFetcher: (deps: CustomWorksEpicDependencies) => deps.api.getCustomWorks
});

const createCustomWorkEpic: Epic<State, CustomWorksEpicDependencies> = (
  actions$,
  state$,
  deps
) =>
  actions$.pipe(
    ofAction(createCustomWork.started),
    mergeMap(action => {
      const { data } = action.payload;
      const request = mapFormDataToCreateRequest(data);

      return deps.api.createCustomWork(request).pipe(
        mergeMap(response => {
          const doneActions = [
            createCustomWork.done({
              result: response,
              params: action.payload
            })
          ];

          if (data.sign) {
            return deps.api
              .signCustomWork({ uuid: response.uuid })
              .pipe(mergeMap(() => doneActions));
          }

          return doneActions;
        }),
        catchAsyncError(error =>
          createCustomWork.failed({
            error: parseServerError(error),
            params: action.payload
          })
        )
      );
    })
  );

const updateCustomWorkEpic: Epic<State, CustomWorksEpicDependencies> = (
  action$,
  state$,
  deps
) =>
  action$.pipe(
    ofAction(updateCustomWork.started),
    mergeMap(action => {
      const state = state$.value;
      const { data } = action.payload;
      const { contract, condition: conditionOption } = data;

      const customWork = selectCustomWork(action.payload.workId)(state);
      const condition = selectContractCondition(
        contract,
        conditionOption.uuid
      )(state);

      const request = mapFormDataToUpdateRequest(data, condition, customWork);

      return deps.api
        .updateCustomWork(request, ajaxReq => ({
          ...ajaxReq,
          url: `${ajaxReq.url}/${customWork.uuid}`
        }))
        .pipe(
          mergeMap(response => {
            const doneActions = [
              updateCustomWork.done({
                result: response,
                params: action.payload
              })
            ];

            if (data.sign) {
              return deps.api
                .signCustomWork({ uuid: response.uuid })
                .pipe(mergeMap(() => doneActions));
            }

            return doneActions;
          }),
          catchAsyncError(error =>
            updateCustomWork.failed({
              error: parseServerError(error),
              params: action.payload
            })
          )
        );
    })
  );

const deleteCustomWorkEpic: Epic<State, CustomWorksEpicDependencies> = (
  actions$,
  _,
  deps
) =>
  actions$.pipe(
    ofAction(deleteCustomWork.started),
    mergeMap(action => {
      return deps.api
        .deleteCustomWork({}, ajaxReq => ({
          ...ajaxReq,
          url: `${ajaxReq.url}/${action.payload.workId}`
        }))
        .pipe(
          mergeMap(() => {
            return [
              deleteCustomWork.done({
                params: action.payload,
                result: {}
              })
            ];
          }),
          catchAsyncError(error =>
            deleteCustomWork.failed({
              error: parseServerError(error),
              params: action.payload
            })
          )
        );
    })
  );

const hideFormEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(
      createCustomWork.done,
      updateCustomWork.done,
      deleteCustomWork.done
    ),
    mergeMap(action => {
      if (deleteCustomWork.done.match(action)) {
        return [push('/custom-works')];
      }

      if (
        createCustomWork.done.match(action) ||
        updateCustomWork.done.match(action)
      ) {
        return action.payload.params.data.next ? [] : [push('/custom-works')];
      }

      return [];
    })
  );

const updateFilterPresetsEpic: Epic<State, CustomWorksEpicDependencies> = (
  actions$,
  state$,
  deps
) =>
  actions$.pipe(
    ofAction(presetsActions.delete, presetsActions.push),
    map(action => {
      const presets = selectCustomWorksFilterPresets(state$.value);
      deps.setRecovery({ customWorks: { presets } });

      const messageAction = presetsActions.push.match(action)
        ? 'saved'
        : 'deleted';

      const title = action.payload.preset.title;

      return pushNotification({
        type: 'success',
        message: `Filter preset "${title}" ${messageAction}`
      });
    })
  );

const errorNotificationsEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(
      createCustomWork.failed,
      updateCustomWork.failed,
      deleteCustomWork.failed,
      loadCustomWorksAsync.failed
    ),
    mergeMap(action => {
      return typeof action.payload.error === 'string'
        ? [
            pushNotification({
              type: 'error',
              message: action.payload.error
            })
          ]
        : [];
    })
  );

const successNotificationsEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(
      createCustomWork.done,
      updateCustomWork.done,
      deleteCustomWork.done
    ),
    map(action => {
      if (createCustomWork.done.match(action)) {
        return pushNotification({
          type: 'success',
          message: 'Custom work added successfully'
        });
      }

      if (updateCustomWork.done.match(action)) {
        return pushNotification({
          type: 'success',
          message: 'Custom work updated successfully'
        });
      }

      return pushNotification({
        type: 'success',
        message: 'Custom work deleted successfully'
      });
    })
  );

const successResultEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(
      createCustomWork.done,
      updateCustomWork.done,
      deleteCustomWork.done
    ),
    mergeMap(action => {
      action.payload.params.callback();
      return [];
    })
  );

const errorResultEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(createCustomWork.failed, updateCustomWork.failed),
    mergeMap(action => {
      const errors: Object =
        typeof action.payload.error === 'string' ? {} : action.payload.error;

      action.payload.params.callback(errors);

      return [];
    })
  );

const resetDependenciesEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.reset),
    mergeMap(() => [contractsDomainAction.reset()])
  );

const dependenciesEpic = createPageInitEpic({
  domainAction,
  selectStatus: selectDependenciesStatus,
  dependencies: [loadSettingsOnce, loadingContracts]
});

const loadMainDataEpic: Epic = actions$ =>
  actions$.pipe(
    ofAction(domainAction.success),
    map(() => reloadCustomWorks(toolsFormInitialValues))
  );

const loadUserRatesStart: Epic<State, CustomWorksEpicDependencies> = (
  actions$,
  _,
  deps
) =>
  actions$.pipe(
    ofAction(getUserRatesAction.started),
    mergeMap(({ payload }) =>
      deps.api.getUserRates(payload.request).pipe(
        map(result =>
          getUserRatesAction.done({
            result,
            params: payload
          })
        ),
        catchAsyncError(error =>
          getUserRatesAction.failed({
            params: payload,
            error: parseServerError(error)
          })
        )
      )
    )
  );

const loadUserRatesDone: Epic = actions$ =>
  actions$.pipe(
    ofAction(getUserRatesAction.done),
    mergeMap(({ payload }) => {
      const response = payload.result;
      const { callback } = payload.params;

      callback(response);
      return [];
    })
  );

export const customWorksEpic = combineEpics(
  hideFormEpic,
  errorResultEpic,
  updateFilterEpic,
  loadingStartEpic,
  dependenciesEpic,
  loadMainDataEpic,
  successResultEpic,
  loadUserRatesDone,
  loadUserRatesStart,
  deleteCustomWorkEpic,
  updateCustomWorkEpic,
  createCustomWorkEpic,
  resetDependenciesEpic,
  errorNotificationsEpic,
  updateFilterPresetsEpic,
  successNotificationsEpic
);
