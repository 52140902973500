import { AjaxRequest } from 'rxjs/ajax';

import { ParamsContainer, RequestFunctionFactoryOptions } from '../interface';

/* eslint-disable @typescript-eslint/no-explicit-any */

const isObject = (value: any) => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

const paramsToFormData = (
  params: Object,
  formData: FormData,
  prefix?: string
): FormData => {
  return Object.entries(params).reduce((result, [key, data]) => {
    const convert = isObject(data) && !(data instanceof File);

    if (convert) {
      return paramsToFormData(data, formData, key);
    } else {
      const formDataKey = prefix ? `${prefix}[${key}]` : key;
      result.set(formDataKey, data);
    }

    return result;
  }, formData);
};

export const propsToForm =
  (options: RequestFunctionFactoryOptions) =>
  (request: AjaxRequest, params: Object): AjaxRequest => {
    if (options.params !== ParamsContainer.Form) {
      return request;
    }

    const body = paramsToFormData(params, new FormData());

    // const body = Object.entries(params).reduce((result, [key, data]) => {
    //   const value = isObject(data) ? JSON.stringify(data) : data;
    //
    //   result.set(key, value);
    //   return result;
    // }, new FormData());

    return { ...request, body };
  };
